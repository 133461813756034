import InnerNavLink from "components/InnerNavLink";
import PageTitle from "components/layout/PageTitle";
import { Outlet } from "react-router-dom";

export default function SettingsPage() {
  return (
    <div>
      <PageTitle title="Configurações" />
      <div className="flex items-center">
        <InnerNavLink to="/settings" text="Dados cadastrais" />
        <InnerNavLink to="/settings/integrations" text="Integrações" />
        <InnerNavLink to="/settings/driverpayment" text="Pagamento de entregadores" />
      </div>
      <Outlet />
    </div>
  );
}
