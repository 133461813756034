import React from "react";
import { NavLink } from "react-router-dom";
import "./Menu.scss";

import { ReactComponent as OrderIcon } from "assets/icons/layout/navbar-orders-icon.svg";
import { ReactComponent as DriversIcon } from "assets/icons/layout/navbar-drivers-icon.svg";
import { ReactComponent as MetricsIcon } from "assets/icons/layout/navbar-metrics-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/layout/navbar-settings-icon.svg";

interface MenuProps {
  isVisible: boolean;
  toggleMenu: () => void;
}

const Menu: React.FC<MenuProps> = ({ isVisible, toggleMenu }) => {
  return (
    <nav className={` ${isVisible ? "menu" : "menu__hidden"}`} onClick={toggleMenu}>
      <NavLink
        to="/"
        className={({ isActive }) => (isActive ? "menu__item menu__item--active" : "menu__item")}
      >
        <OrderIcon />
        Pedidos
      </NavLink>
      <NavLink
        to="/drivers"
        className={({ isActive }) => (isActive ? "menu__item menu__item--active" : "menu__item")}
      >
        <DriversIcon />
        Entregadores
      </NavLink>
      <NavLink
        to="/metrics"
        className={({ isActive }) => (isActive ? "menu__item menu__item--active" : "menu__item")}
      >
        <MetricsIcon />
        Métricas
      </NavLink>
      <NavLink
        to="/settings"
        className={({ isActive }) => (isActive ? "menu__item menu__item--active" : "menu__item")}
      >
        <SettingsIcon />
        Configurações
      </NavLink>
    </nav>
  );
};

export default Menu;
