import { ReactComponent as WppIcon } from "assets/icons/wpp-icon.svg";

interface WhatsAppButtonProps {
  driverNumber: string;
}

const URL = "https://wa.me";

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ driverNumber }) => {
  const openChat = () => {
    window.open(`${URL}/${driverNumber}`);
  };

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        openChat();
      }}
    >
      <div className="flex items-center gap-2">
        <WppIcon />
      </div>
    </button>
  );
};

export default WhatsAppButton;
