import React from "react";
import { Skeleton } from "antd";

interface OrderFeedSkeletonProps {
  isLoading: boolean;
  orderQtt: number;
}

const OrderFeedSkeleton = ({ isLoading, orderQtt }: OrderFeedSkeletonProps) => {
  const generateSkeletons = (orderQtt: number) => {
    const skeletons = [];
    for (let i = 0; i < orderQtt; i++) {
      skeletons.push(
        <div className="orderSmallCard" key={i}>
          <div className="flex justify-between mb-3">
            <Skeleton.Button active={isLoading} size="small" />
            <Skeleton.Button active={isLoading} size="small" />
          </div>
          <Skeleton.Input style={{ width: 200 }} active={isLoading} size="small" />
        </div>
      );
    }
    return skeletons;
  };

  return <div>{generateSkeletons(orderQtt)}</div>;
};

export default OrderFeedSkeleton;
