import React from "react";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import OrderStatusTag from "components/OrderStatusTag";
import Order from "interfaces/domain/order.interface";
import { getCompleteHour } from "utils/date.utils";
import { ReactComponent as AddressIcon } from "assets/icons/address-icon.svg";

interface OrderInfoPanelProps {
  order?: Order;
  setLastHoverOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  toggleOrderDetails: (order: Order) => void;
  openAssignModalAndSetOrder: (orderId: string, driverId?: string) => void;
}

const OrderInfoPanel: React.FC<OrderInfoPanelProps> = (props) => {
  const { order, setLastHoverOrder, toggleOrderDetails, openAssignModalAndSetOrder } = props;

  return (
    <div
      className="w-72 bg-white absolute top-2 left-2 rounded p-5"
      style={{ boxShadow: "0px 3px 4px rgba(0, 0, 0, .3)" }}
    >
      {order ? (
        <>
          <div className="mb-3 flex justify-between cursor-pointer">
            <div className="flex gap-4">
              <p className="orderId" onClick={() => toggleOrderDetails(order)}>
                #{order.displayId}
              </p>
              <p className="orderTime">{getCompleteHour(order.orderTimestamp)}</p>
            </div>
            <div
              className="flex justify-end cursor-pointer mb-2"
              onClick={() => setLastHoverOrder(undefined)}
            >
              <CloseOutlined />
            </div>
          </div>
          <div className="mb-3 flex flex-col">
            {order.driver ? (
              <div className="flex justify-between items-center">
                <div className="flex">
                  <span className="mr-2">
                    <Avatar
                      size="large"
                      src={order.driver.profileImage}
                      icon={!order.driver.profileImage && <UserOutlined />}
                    />
                  </span>
                  <div className="flex flex-col items-start">
                    <span className="text-sm">
                      {order.driver.firstName} {order.driver.lastName}
                    </span>
                    <OrderStatusTag status={order.lastOrderStatus} />
                  </div>
                </div>
                <div>
                  <span
                    className="cardLink text-red cursor-pointer"
                    onClick={() =>
                      order.driver && openAssignModalAndSetOrder(order._id, order.driver._id)
                    }
                  >
                    Alterar
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <span className="mr-2">
                  <Avatar icon={<UserOutlined />} />
                </span>
                <div className="flex flex-col items-start">
                  <span
                    className="cardLink text-red cursor-pointer text-sm"
                    onClick={() => openAssignModalAndSetOrder(order._id)}
                  >
                    Atribuir entregador
                  </span>
                  <OrderStatusTag status={order.lastOrderStatus} />
                </div>
              </div>
            )}
          </div>
          <div className="flex">
            <AddressIcon className="align-middle" />
            <p className="ml-3" style={{ fontSize: "0.75rem" }}>
              {order.deliveryAddress.formattedAddress}
            </p>
          </div>
        </>
      ) : (
        <p className="text-center">Aponte para um pedido</p>
      )}
    </div>
  );
};

export default OrderInfoPanel;
