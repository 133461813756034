import { useContext, useEffect, useState } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input/Input";
import TextArea from "antd/lib/input/TextArea";
import Select from "antd/lib/select";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";
import { useDebouncedCallback } from "use-debounce";
import useGetAddressFromCep from "hooks/useGetAddressFromCep";
import Button from "components/layout/Button";
import useGetAutoCompleteAddress from "hooks/useGetAutoCompleteAddress";
import { StoreContext } from "contexts/store.context";
import AddressListDropdown from "./AddressListDropdown";
import { getDriverPaymentConfig } from "utils/store.utils";
import { buildCreateOrderPayload, getFormattedAddress } from "utils/order.utils";
import { CreateOrderFormValues } from "./CreateOrder.interface";
import currency from "currency.js";
import { Coordinates } from "interfaces/domain/global.interface";
import useHereGeocode from "hooks/useHere";
import { useQuery } from "react-query";
import MapboxService from "services/Mapbox.service";
import useDriverPayment from "hooks/useDriverPayment";
import { DriverFeeValues, DriverPaymentConfig } from "interfaces/domain/store.interface";
import Order from "interfaces/domain/order.interface";
import useOrderCreation from "./useOrderCreation";
import toast from "react-hot-toast";
import Title from "antd/lib/typography/Title";
import Modal from "components/layout/Modal";
import Decimal from "decimal.js";

interface CreateOrderProps {
  order?: Order;
  isVisible: boolean;
  toggleVisibility: () => void;
  setOrderToEdit: React.Dispatch<React.SetStateAction<Order | undefined>>;
}

const CreateOrder: React.FC<CreateOrderProps> = (props) => {
  const { order, isVisible, toggleVisibility, setOrderToEdit } = props;
  const { store } = useContext(StoreContext);
  const [form] = Form.useForm();
  const [telMask, setTelMask] = useState("");
  const [paymentConfig, setPaymentConfig] = useState<DriverPaymentConfig | undefined>();
  const [addressDropdownVisible, setAddressDropdownVisible] = useState(false);
  const [cep, setCep] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [addressQuery, setAddressQuery] = useState("");
  const [orderCoordinates, setOrderCoordinates] = useState<Coordinates>();
  const [orderDistance, setOrderDistance] = useState(0);
  const [driverFeeData, setDriverFeeData] = useState<DriverFeeValues>();

  const toggleAddressDropdown = () => setAddressDropdownVisible(!addressDropdownVisible);

  useEffect(() => {
    setPaymentConfig(getDriverPaymentConfig());
  }, []);

  useEffect(() => {
    if (!order) return;

    setFormattedAddress(order.deliveryAddress.formattedAddress);
    form.setFieldsValue({
      displayId: order.displayId,
      name: order.customer.name,
      phone: order.customer.phone || "",
      postalCode: order.deliveryAddress.postalCode,
      streetName: order.deliveryAddress.streetName,
      streetNumber: order.deliveryAddress.streetNumber,
      neighborhood: order.deliveryAddress.neighborhood,
      formattedAddress: order.deliveryAddress.formattedAddress,
      city: order.deliveryAddress.city,
      state: order.deliveryAddress.state,
      complement: order.deliveryAddress.complement || "",
      reference: order.deliveryAddress.reference || "",
      note: order.note || "",
      paymentMethod:
        order.payments.methods[0].paymentType === "ONLINE"
          ? "ONLINE"
          : order.payments.methods[0].method,
      totalPrice: order.paymentSummary.totalPrice.toString().replace(".", ","),
      deliveryFee: order.paymentSummary.deliveryFee.toString().replace(".", ","),
      changeFor: order.paymentSummary.changeFor.toString().replace(".", ","),
      distance: order.distance,
    });
  }, [form, order]);

  const formInitialValues = {
    displayId: Math.floor(1000 + Math.random() * 9000).toString(),
    name: "",
    phone: "",
    postalCode: "",
    streetName: "",
    streetNumber: "",
    neighborhood: "",
    formattedAddress: "",
    city: "",
    state: "",
    complement: "",
    reference: "",
    note: "",
    paymentMethod: "",
    totalPrice: "",
    deliveryFee: "",
    changeFor: "",
    distance: "",
  };

  const { mutate } = useOrderCreation(order ? "EDIT" : "CREATE", () => {
    toggleVisibility();
  });

  const {
    data: cepData,
    isLoading: cepIsLoading,
    isSuccess: cepIsSuccess,
    isError: cepHasErrors,
  } = useGetAddressFromCep(cep);

  useEffect(() => {
    if (!cepData) return;
    form.setFieldsValue({
      streetName: cepData.logradouro,
      streetNumber: "",
      neighborhood: cepData.bairro,
      city: cepData.localidade,
      state: cepData.uf,
    });
  }, [cepData, form]);

  const {
    data: addressData,
    isLoading: addressIsLoading,
    isError: addressHasErros,
    isSuccess: addressIsSuccess,
  } = useGetAutoCompleteAddress(addressQuery, [store?.lat || 0, store?.lng || 0]);

  const { data: geocodeData, isLoading: geocodeIsLoading } = useHereGeocode(formattedAddress);
  const { getRadiusFee, getKilometerFee } = useDriverPayment(paymentConfig);

  useQuery(
    [
      "getRouteDistance",
      [store?.lng || 0, store?.lat || 0],
      [orderCoordinates?.lng || 0, orderCoordinates?.lat || 0],
    ],
    () =>
      MapboxService.getRoute([
        [store?.lng || 0, store?.lat || 0],
        [orderCoordinates?.lng || 0, orderCoordinates?.lat || 0],
      ]),
    {
      enabled: !!orderCoordinates,
      onSuccess: (data) => {
        if (!data) return;
        const fixedRoute = new Decimal(data.routes[0].distance)
          .div(1000)
          .toDecimalPlaces(2)
          .toNumber();
        setOrderDistance(fixedRoute || 0);
        form.setFieldsValue({ distance: fixedRoute });
      },
    }
  );

  useEffect(() => {
    if (!geocodeData) return;
    setOrderCoordinates({
      lng: geocodeData.items[0].position.lng,
      lat: geocodeData.items[0].position.lat,
    });
  }, [geocodeData]);

  const debouncedCep = useDebouncedCallback((cep: string) => {
    setCep(cep);
  }, 1000);

  const debouncedAddressAutoComplete = useDebouncedCallback((value) => {
    if (!store) return;
    setAddressQuery(value);
    toggleAddressDropdown();
  }, 500);

  const debouncedFormattedAddress = useDebouncedCallback(() => {
    const formFields = form.getFieldsValue() as CreateOrderFormValues;
    const formattedAddress = getFormattedAddress(
      formFields.streetName,
      formFields.streetNumber,
      formFields.neighborhood,
      formFields.city,
      formFields.state,
      formFields.postalCode
    );
    setFormattedAddress(formattedAddress);
    form.setFieldsValue({ formattedAddress });
  }, 1000);

  useEffect(() => {
    if (!store || !orderCoordinates || !orderDistance || !paymentConfig) return;

    let driverFeeValues: DriverFeeValues = {};
    const formDeliveryFee = form.getFieldValue("deliveryFee");
    const deliveryFee = formDeliveryFee ? currency(formDeliveryFee, { decimal: "," }).value : 0;

    if (paymentConfig?.deliveryFee?.options) {
      if (
        paymentConfig.deliveryFee.options.uniqueValue ||
        (paymentConfig.deliveryFee.options.altenatingValue && !deliveryFee)
      ) {
        driverFeeValues.driverDeliveryFee = paymentConfig.deliveryFee.fee;
      } else if (paymentConfig.deliveryFee.options.altenatingValue && deliveryFee) {
        driverFeeValues.driverDeliveryFee = deliveryFee;
      }
    } else if (paymentConfig?.radiusFee?.length) {
      const radiusFee = getRadiusFee(
        [store.lat, store.lng],
        [orderCoordinates.lat, orderCoordinates.lng]
      );
      driverFeeValues.deliveryFee = radiusFee ? radiusFee.driverFee : 0;
      driverFeeValues.driverRadiusFee = radiusFee ? radiusFee.driverFee : 0;
      driverFeeValues.toRange = radiusFee ? radiusFee.toRange : 0;
    } else if (paymentConfig?.kilometerFee?.length) {
      const kilometerFee = getKilometerFee(orderDistance);
      driverFeeValues.deliveryFee = kilometerFee ? kilometerFee.driverFee : 0;
      driverFeeValues.driverKilometerFee = kilometerFee ? kilometerFee.driverFee : 0;
      driverFeeValues.toRange = kilometerFee ? kilometerFee.toRange : 0;
    }

    setDriverFeeData(driverFeeValues);
    form.setFieldsValue({ deliveryFee: driverFeeValues.deliveryFee });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCoordinates, orderDistance]);

  const onFormSubmit = (values: CreateOrderFormValues) => {
    if (!store || !orderCoordinates) return;

    if (driverFeeData) {
      if (driverFeeData.driverRadiusFee) {
        values.driverRadiusFee = driverFeeData.driverRadiusFee;
      } else if (driverFeeData.driverKilometerFee) {
        values.driverKilometerFee = driverFeeData.driverKilometerFee;
      } else if (driverFeeData.driverDeliveryFee) {
        values.driverDeliveryFee = driverFeeData.driverDeliveryFee;
      }
    }
    values.coordinates = orderCoordinates;

    let orderToSubmit;
    if (order) {
      orderToSubmit = buildCreateOrderPayload(store._id, values);
      toast.promise(mutate(orderToSubmit, order._id), {
        loading: "Carregando...",
        success: "Pedido manual editado com sucesso!",
        error: "Erro ao editar pedido manual.",
      });
    } else {
      values.integrationSource = "Fleetmap";
      orderToSubmit = buildCreateOrderPayload(store._id, values);
      toast.promise(mutate(orderToSubmit), {
        loading: "Carregando...",
        success: "Pedido manual criado com sucesso!",
        error: "Erro ao criar pedido manual.",
      });
    }
  };

  let formIsLoading = cepIsLoading || addressIsLoading || geocodeIsLoading;

  return (
    <Modal
      title={<Title level={5}>{order ? "Editar Pedido" : "Novo Pedido"}</Title>}
      width={1500}
      visible={isVisible}
      onCancel={toggleVisibility}
      afterClose={() => {
        form.resetFields();
        setOrderToEdit(undefined);
        setCep("");
        setFormattedAddress("");
      }}
    >
      <Form
        form={form}
        preserve={false}
        className="flex flex-col items-center text-left"
        layout="vertical"
        onFinish={(values) => onFormSubmit(values)}
        initialValues={formInitialValues}
      >
        <div>
          <div className="flex justify-start">
            <Form.Item
              name="displayId"
              label="ID do pedido"
              required
              rules={[{ required: true, type: "string" }]}
            >
              <Input className="input" />
            </Form.Item>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              <strong>1</strong> - Informações do <strong>cliente</strong>
            </p>
            <div className="flex gap-6 items-center">
              <Form.Item
                name="name"
                label="Nome"
                required
                rules={[{ required: true, type: "string" }]}
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item name="phone" label="DDD + Telefone" rules={[{ type: "string" }]}>
                <InputMask
                  className="input"
                  alwaysShowMask={false}
                  mask={telMask}
                  onBlur={(e) => {
                    let valLen = e.target.value.replace(/[^a-zA-Z0-9][\s]*/gi, "").length;
                    if (valLen === 11 && telMask.length !== 11) {
                      return setTelMask("(99) 99999-9999");
                    } else if (valLen === 10 && telMask.length !== 10) {
                      return setTelMask("(99) 9999-9999");
                    } else {
                      return setTelMask("");
                    }
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <p>
              <strong>2</strong> - Informações de <strong>destino</strong>
            </p>
            <div className="flex gap-4">
              <Form.Item
                name="postalCode"
                label="CEP"
                validateStatus={(() => {
                  if (cepIsLoading) {
                    return "validating";
                  } else if (cepHasErrors) {
                    return "error";
                  } else if (cepIsSuccess || addressIsSuccess) {
                    return "success";
                  } else {
                    return "";
                  }
                })()}
                rules={[{ required: true }, { type: "string" }]}
              >
                <InputMask
                  disabled={formIsLoading}
                  className="input"
                  mask="99999-999"
                  onChange={(e) => {
                    const cep = e.target.value.replace(/[^\w][\s]*/gi, "");
                    if (!cep || cep.includes("_") || cep.length !== 8) return;
                    debouncedCep(cep);
                  }}
                />
              </Form.Item>
              <div className="relative flex flex-col">
                <Form.Item
                  className="w-80"
                  name="streetName"
                  label="Logradouro"
                  validateStatus={(() => {
                    if (formIsLoading) {
                      return "validating";
                    } else if (addressHasErros) {
                      return "error";
                    } else if (addressIsSuccess || cepIsSuccess) {
                      return "success";
                    }
                  })()}
                  rules={[{ required: true }, { type: "string" }]}
                >
                  <Input
                    className="input"
                    disabled={formIsLoading}
                    onChange={(e) => {
                      if (!e.target.value) return;
                      debouncedAddressAutoComplete(e.target.value);
                    }}
                    onBlur={() => setAddressDropdownVisible(false)}
                  />
                </Form.Item>
                <AddressListDropdown
                  addressList={addressData}
                  formInstance={form}
                  isVisible={addressDropdownVisible}
                  toggle={toggleAddressDropdown}
                />
              </div>
              <Form.Item
                name="streetNumber"
                label="Número"
                required
                rules={[{ required: true, type: "string" }]}
              >
                <Input
                  disabled={formIsLoading}
                  className="input"
                  onChange={(e) => {
                    if (!e.target.value) return;
                    debouncedFormattedAddress();
                  }}
                />
              </Form.Item>
              <Form.Item
                name="neighborhood"
                label="Bairro"
                required
                rules={[{ required: true, type: "string" }]}
              >
                <Input className="input" disabled={cepIsLoading} />
              </Form.Item>
              <div className="hidden">
                <Form.Item name="formattedAddress" label="FormattedAddress">
                  <Input className="input" readOnly />
                </Form.Item>
                <Form.Item name="city" label="Cidade">
                  <Input className="input" readOnly />
                </Form.Item>
                <Form.Item name="state" label="Estado">
                  <Input className="input" readOnly />
                </Form.Item>
              </div>
              <Form.Item name="complement" label="Complemento">
                <Input className="input" />
              </Form.Item>
              <Form.Item name="reference" label="Referencia">
                <Input className="input" />
              </Form.Item>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <p>
              <strong>3</strong> - Informações do <strong>pedido</strong>
            </p>
            <div className="flex gap-4">
              <Form.Item name="note" label="Detalhes">
                <TextArea
                  style={{ resize: "none" }}
                  allowClear
                  bordered
                  rows={6}
                  cols={71}
                  className="flex-grow"
                />
              </Form.Item>
              <div className="flex flex-col">
                <div className="flex gap-4">
                  <Form.Item
                    name="paymentMethod"
                    className="w-48"
                    label="Forma de pagamento"
                    required
                    rules={[{ required: true, type: "string" }]}
                  >
                    <Select>
                      <Select.Option value="CASH">Dinheiro</Select.Option>
                      <Select.Option value="DEBIT">Débito</Select.Option>
                      <Select.Option value="CREDIT">Crédito</Select.Option>
                      <Select.Option value="MEAL_VOUCHER">Vale refeição</Select.Option>
                      <Select.Option value="ONLINE">Pagamento Online</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="totalPrice"
                    className="w-48"
                    label="Valor total"
                    required
                    rules={[{ required: true, type: "string" }]}
                  >
                    <NumberFormat
                      className="input"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$"
                    />
                  </Form.Item>
                  <Form.Item name="changeFor" className="w-48" label="Troco">
                    <NumberFormat
                      className="input"
                      onValueChange={(v) => form.setFieldsValue({ changeFor: v.floatValue })}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$"
                    />
                  </Form.Item>
                </div>
                <div className="flex items-center gap-4">
                  <Form.Item name="deliveryFee" className="w-48" label="Taxa de entrega">
                    <NumberFormat
                      className="input"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$"
                    />
                  </Form.Item>
                  <Form.Item name="distance" className="w-48" label="Distância">
                    <NumberFormat
                      readOnly
                      className="input"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      suffix=" km"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div className="flex justify-between">
        <Button onClick={toggleVisibility} text="Cancelar" bgcolor="white" textcolor="red" />
        <Button
          onClick={() => form.submit()}
          text="Finalizar"
          bgcolor="red"
          textcolor="white"
          htmlType="submit"
        />
      </div>
    </Modal>
  );
};

export default CreateOrder;
