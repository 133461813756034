import axios from "axios";
import appEnv from "./appEnv";
import { getStoreFromLocalStorage } from "./store.utils";

const userStoreId = getStoreFromLocalStorage();

export const ApiClient = axios.create({
  baseURL: appEnv.FLEETMAP_API,
});

ApiClient.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers.storeid = userStoreId;
  }
  return config;
});

export const ViaCEPApiClient = axios.create({ baseURL: appEnv.CEP_API });
export const HereGeocodeApiClient = axios.create({ baseURL: appEnv.HERE_GEOCODE_API });
export const HereAutoCompleteApiClient = axios.create({ baseURL: appEnv.HERE_AUTOCOMPLETE_API });
export const MapboxApiClient = axios.create({ baseURL: appEnv.MAPBOX_API });
