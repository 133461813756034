import { useContext, useState } from "react";
import "./Topbar.scss";
import Menu from "./Menu";
import logo from "assets/icons/layout/navbar-logo.svg";
import { ReactComponent as MenuIcon } from "assets/icons/layout/menu_icon.svg";
import { ReactComponent as ExitIcon } from "assets/icons/layout/exit_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { StoreContext } from "contexts/store.context";
import firebaseApp from "credentials/firebase.credentials";
import User from "interfaces/domain/user.interface";
import styled from "styled-components";

interface MenuOverlayProps {
  visible: boolean;
}

const MenuOverlay = styled.div<MenuOverlayProps>`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
`;

const Topbar = () => {
  const { user, store, setUser } = useContext(StoreContext);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => setMenuIsVisible(!menuIsVisible);

  const logout = async () => {
    localStorage.removeItem("user");
    setUser({} as User);
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        navigate("/auth/login");
      });
  };

  return (
    <>
      <div className="topbar">
        <div className="flex gap-6 items-center">
          <div className="cursor-pointer select-none" onClick={toggleMenu}>
            <MenuIcon />
            <MenuOverlay visible={menuIsVisible} />
          </div>
          <Link to="/">
            <img style={{ width: "80%" }} src={logo} alt="Fleetmap logo" />
          </Link>
        </div>
        <div className="flex items-center">
          <div className="flex flex-col text-right mr-4">
            <span>
              {user.firstName} {user.lastName}
            </span>
            <span>{store?.displayName}</span>
          </div>
          <button className="ml-2" onClick={logout}>
            <ExitIcon className="mr-2" />
            Sair
          </button>
        </div>
      </div>
      <Menu isVisible={menuIsVisible} toggleMenu={toggleMenu} />
    </>
  );
};

export default Topbar;
