const appEnv = {
  FLEETMAP_API: process.env.REACT_APP_FLEETMAP_API || "",
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY || "",
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  FIREBASE_DATABASE_URL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID || "",
  IFOOD_API: process.env.REACT_APP_IFOOD_API || "",
  CEP_API: process.env.REACT_APP_CEP_API || "",
  MAPBOX_ACCESS_KEY: process.env.REACT_APP_MAPBOX_ACCESS_KEY || "",
  MAPBOX_API: process.env.REACT_APP_MAPBOX_API || "",
  HERE_GEOCODE_API: process.env.REACT_APP_GEOCODE_HERE_API || "",
  HERE_AUTOCOMPLETE_API: process.env.REACT_APP_AUTOCOMPLETE_HERE_API || "",
  HERE_API_KEY: process.env.REACT_APP_HERE_API_KEY || "",
};

export default appEnv;
