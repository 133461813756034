import React from "react";
import AssociateDriverImg from "assets/images/associate-driver.png";
import Button from "components/layout/Button";

interface SuccessAssignProps {
  toggleModal: () => void;
}

const SuccessAssign: React.FC<SuccessAssignProps> = (props) => {
  const { toggleModal } = props;

  return (
    <div className="flex flex-col items-center">
      <figure className="mb-10">
        <img src={AssociateDriverImg} alt="Sucesso na atribuição do pedido" />
      </figure>
      <p className="w-96 mb-10">
        O pedido foi <strong>atribuído com sucesso</strong> ao entregador!
      </p>
      <Button
        loading={false}
        text="Fechar"
        bgcolor={"red"}
        textcolor={"white"}
        onClick={toggleModal}
      />
    </div>
  );
};

export default SuccessAssign;
