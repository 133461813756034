import distance from "@turf/distance";
import { Coord } from "@turf/helpers";
import Store, {
  DriverDistanceConfig,
  DriverPaymentConfig,
} from "interfaces/domain/store.interface";

export const getStoreFromLocalStorage = () => {
  const storedStore = localStorage.getItem("store");
  if (storedStore === "" || storedStore === "undefined" || storedStore === null) {
    return "";
  } else {
    const pasedStoredStore = JSON.parse(storedStore) as Store;
    return pasedStoredStore._id;
  }
};

export const getDriverPaymentConfig = (): DriverPaymentConfig | undefined => {
  const storedStore = localStorage.getItem("store");
  if (!storedStore || storedStore === null || storedStore === "undefined") return;
  const parsedStoredStore = JSON.parse(storedStore) as Store;
  if (!parsedStoredStore.driverPaymentConfig) return;
  return {
    deliveryFee: parsedStoredStore.driverPaymentConfig.deliveryFee,
    radiusFee: parsedStoredStore.driverPaymentConfig.radiusFee,
    kilometerFee: parsedStoredStore.driverPaymentConfig.kilometerFee,
  };
};

export const resolveRadiusDistanceFee = (
  from: Coord,
  to: Coord,
  distanceMetadata: DriverDistanceConfig[]
): DriverDistanceConfig => {
  const dist = distance(from, to, { units: "kilometers" });
  const rangeFound = distanceMetadata.find((elem) => dist <= elem.toRange);
  if (!rangeFound) return distanceMetadata[distanceMetadata.length - 1];
  return rangeFound;
};

export const resolveKilometerDistanceFee = (
  toRange: number,
  distanceMetadata: DriverDistanceConfig[]
): DriverDistanceConfig => {
  const rangeFound = distanceMetadata.find((elem) => toRange <= elem.toRange);
  if (!rangeFound) return distanceMetadata[distanceMetadata.length - 1];
  return rangeFound;
};
