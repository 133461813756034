import { useEffect, useState } from "react";
import Store, {
  DriverDistanceConfig,
  DriverPaymentConfig,
} from "interfaces/domain/store.interface";
import { Form, Space, Tooltip, Table, InputNumber } from "antd";
import toast from "react-hot-toast";
import Column from "antd/lib/table/Column";
import FormItem from "antd/lib/form/FormItem";
import Button from "components/layout/Button";
import styled from "styled-components";
import RealCurrency from "components/RealCurrency/RealCurrency";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/remove-icon.svg";
import { UseMutateAsyncFunction } from "react-query";

interface DriverKilometerFeeCardProps {
  driverKilometerFee: DriverDistanceConfig[] | undefined;
  mutateConfig: UseMutateAsyncFunction<Store, unknown, DriverPaymentConfig, unknown>;
  setPaymentConfig: React.Dispatch<React.SetStateAction<DriverPaymentConfig | undefined>>;
}

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea !important;
  border-radius: 0.625rem !important;
  padding: 0.6875rem !important;
  box-sizing: border-box;
  resize: none;

  & > .ant-input-number-handler-wrap {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }

  &.ant-input-number-focused,
  &.ant-input-number:hover {
    border: 0.5px solid #ef233c !important;
    box-shadow: 0 0 0 2px rgba(239, 35, 60, 0.2) !important;
    box-sizing: border-box;
    outline: 0;
  }

  & .ant-input-number-handler:hover .ant-input-number-handler-down-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
    color: #ef233c;
  }
`;

const DriverKilometerFeeCard: React.FC<DriverKilometerFeeCardProps> = (props) => {
  const { driverKilometerFee, mutateConfig, setPaymentConfig } = props;
  const [distanceFees, setDistanceFees] = useState<DriverDistanceConfig[] | undefined>(
    driverKilometerFee
  );
  const [form] = Form.useForm();

  useEffect(() => {
    setDistanceFees(driverKilometerFee || []);
  }, [driverKilometerFee]);

  useEffect(() => {
    form.resetFields();
  }, [driverKilometerFee, form]);

  const EditRow = (record: DriverDistanceConfig) => {
    form.setFieldsValue({ ...record });
  };

  const DeleteRow = (range: number) => {
    setDistanceFees((oldFees) => oldFees && oldFees.filter((fee) => fee.toRange !== range));
  };

  const sortDistances = (feeConfig: DriverDistanceConfig[]) => {
    return feeConfig.sort((a, b) => a.toRange - b.toRange);
  };

  const onFinishForm = (values: any) => {
    if (!distanceFees) return;
    const newFeeEntry = values as DriverDistanceConfig;
    const indexOfExistingFee = distanceFees.findIndex((e) => e.toRange === newFeeEntry.toRange);
    if (indexOfExistingFee >= 0) {
      setDistanceFees((oldFees) => {
        if (!oldFees) return;
        const updatedFees = [...oldFees];
        updatedFees[indexOfExistingFee] = newFeeEntry;
        return updatedFees;
      });
    } else {
      const sortedFeeEntries = sortDistances([...distanceFees, newFeeEntry]);
      setDistanceFees(sortedFeeEntries);
    }

    form.resetFields();
  };

  const onSaveFees = () => {
    if (JSON.stringify(driverKilometerFee) === JSON.stringify(distanceFees)) {
      toast.success("Dados inalterados.");
    } else {
      const params = { kilometerFee: distanceFees };
      setPaymentConfig(params);
      toast.promise(mutateConfig(params), {
        loading: "Carregando...",
        success: "Pagamento por distância atualizado com sucesso!",
        error: "Erro ao atualizar pagamento por distância.",
      });
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={(values) => onFinishForm(values)}
        initialValues={distanceFees}
        layout="vertical"
      >
        <div className="flex gap-6 items-center">
          <FormItem name="toRange" label="Distância (Km)" required>
            <StyledInputNumber min={0} />
          </FormItem>
          <FormItem name="deliveryFee" label="Taxa do cliente (R$)" required>
            <StyledInputNumber min={0} precision={2} decimalSeparator="," />
          </FormItem>
          <FormItem name="driverFee" label="Taxa do entregador (R$)" required>
            <StyledInputNumber min={0} precision={2} decimalSeparator="," />
          </FormItem>
          <Button htmlType="submit" text="Adicionar" bgcolor="red" textcolor="white" />
        </div>
      </Form>

      <Table
        pagination={{
          pageSize: 10,
        }}
        rowKey={(e) => e.toRange}
        dataSource={distanceFees}
        rowClassName="editable-row"
      >
        <Column
          title="Distância em km"
          dataIndex="toRange"
          key="toRange"
          render={(text, _: DriverDistanceConfig) => <span>Até {text} km</span>}
        />
        <Column
          title="Taxa do cliente"
          dataIndex="deliveryFee"
          key="deliveryFee"
          render={(text, _: DriverDistanceConfig) => <RealCurrency valueToConvert={text} sign />}
        />

        <Column
          title="Taxa do entregador"
          dataIndex="driverFee"
          key="driverFee"
          render={(text, _: DriverDistanceConfig) => <RealCurrency valueToConvert={text} sign />}
        />
        <Column
          title="Ações"
          key="actions"
          render={(_text, record: DriverDistanceConfig) => (
            <Space size="middle">
              <Tooltip title="Editar" className="cursor-pointer">
                <EditIcon onClick={() => EditRow(record)} />
              </Tooltip>
              <Tooltip title="Remover" className="cursor-pointer">
                <RemoveIcon onClick={() => DeleteRow(record.toRange)} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div className="flex mt-4">
        <Button
          disabled={JSON.stringify(driverKilometerFee) === JSON.stringify(distanceFees)}
          onClick={onSaveFees}
          text="Salvar configuração"
          bgcolor="red"
          textcolor="white"
        />
      </div>
    </>
  );
};

export default DriverKilometerFeeCard;
