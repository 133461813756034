import React, { useEffect, useState } from "react";
import { FirebaseDriverData, FirebaseDriverSnapshot } from "./OrdersMap/OrdersMap.interface";
import SuccessAssign from "components/AssignModals/SuccessAssign";
import { useMutation, useQueryClient } from "react-query";
import ModalTitle from "components/layout/ModalTitle";
import Order from "interfaces/domain/order.interface";
import DriverService from "services/driver.service";
import OrderService from "services/order.service";
import Button from "components/layout/Button";
import Modal from "components/layout/Modal";
import { Select } from "antd";
import { rtdb } from "utils/firebasev9";
import { onValue, ref } from "firebase/database";

interface AssignDriverModalProps {
  storeId: string;
  isVisible: boolean;
  singleSelectedOrder: string;
  preSelectedDriver?: string;
  orderToDetail: Order | undefined;
  setOrderToDetail: React.Dispatch<React.SetStateAction<Order | undefined>>;
  toggleModal: () => void;
  setPreSelectedDriver: React.Dispatch<React.SetStateAction<string>>;
}

const AssignDriverModal = (props: AssignDriverModalProps) => {
  const {
    storeId,
    isVisible,
    singleSelectedOrder,
    preSelectedDriver,
    orderToDetail,
    setOrderToDetail,
    toggleModal,
    setPreSelectedDriver,
  } = props;

  const { Option } = Select;
  const [selectedDriver, setSelectedDriver] = useState("");
  const [drivers, setDrivers] = useState<FirebaseDriverData[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isVisible || !storeId) return;
    const driversRef = ref(rtdb, `stores/${storeId}`);
    return onValue(driversRef, (snapshot) => {
      setIsLoading(true);
      setSelectedDriver("");
      const snapshotObj = snapshot.val() as FirebaseDriverSnapshot;
      if (!snapshotObj) return;
      const onlineDrivers = Object.values(snapshotObj) as FirebaseDriverData[];
      setDrivers(onlineDrivers.filter((driver) => driver.status === "AVAILABLE"));
      setIsLoading(false);
    });
  }, [isVisible, storeId]);

  const queryClient = useQueryClient();
  const assignDriverMutation = useMutation(
    "assignDriver",
    () => OrderService.assignDriver(singleSelectedOrder, selectedDriver),
    {
      onSuccess: (data) => {
        if (storeId === "") return;
        queryClient.setQueryData<any>("activeOrders", (oldData: any) => {
          return oldData.map((order: Order) => {
            if (order._id === data._id) {
              if (orderToDetail?._id === data._id) {
                setOrderToDetail(data);
              }
              return data;
            }
            return order;
          });
        });

        DriverService.getDriverRef(storeId, selectedDriver).update({
          ordersAction: "AVAILABLE",
          attributionType: "DISTANCE",
        });
      },
    }
  );

  const unassignDriverMutation = useMutation(
    "unassignDriver",
    () => OrderService.unassignDriver(singleSelectedOrder),
    {
      onSuccess: (updatedOrder) => {
        queryClient.setQueryData<any>("activeOrders", (oldData: any) => {
          return oldData.map((order: Order) => {
            if (order._id === updatedOrder._id) {
              if (orderToDetail?._id === updatedOrder._id) {
                setOrderToDetail(updatedOrder);
              }
              return updatedOrder;
            }
            return order;
          });
        });
        if (!preSelectedDriver) return;
        const driverRef = DriverService.getDriverRef(storeId, preSelectedDriver);
        driverRef.get().then((data) => {
          if (!data.exists()) return;
          const firebaseDriver = data.val() as FirebaseDriverData;
          driverRef.update({
            ordersAction: "REFETCH",
            inTransitOrders: firebaseDriver.inTransitOrders.filter(
              (order) => order !== updatedOrder.displayId
            ),
          });
        });
      },
    }
  );

  const handleSelectChange = (value: string) => setSelectedDriver(value);

  const handleAssignSubmit = () => {
    if (selectedDriver && selectedDriver !== "") {
      assignDriverMutation.mutate();
      setSelectedDriver("");
      setPreSelectedDriver("");
    }
  };

  const handleUnassignSubmit = () => {
    unassignDriverMutation.mutate();
    setSelectedDriver("");
    setPreSelectedDriver("");
  };

  const resetMutationAfterClose = () => {
    assignDriverMutation.reset();
    setSelectedDriver("");
    setPreSelectedDriver("");
  };

  return (
    <Modal
      title={ModalTitle({
        text: "Atribuir pedido",
      })}
      width={820}
      visible={isVisible}
      onCancel={toggleModal}
      afterClose={resetMutationAfterClose}
    >
      <div className="flex flex-col items-center">
        {assignDriverMutation.isIdle || assignDriverMutation.isLoading ? (
          <>
            <p className="mb-10 mx-auto">
              Selecione um dos entregadores disponíveis para atribuir este pedido:
            </p>
            <Select
              showSearch
              bordered
              style={{ width: 465, textAlign: "left" }}
              optionFilterProp="children"
              optionLabelProp="children"
              filterOption={(input, option) => {
                if (!option || !option.children) return false;
                return option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              loading={isLoading}
              onChange={handleSelectChange}
              value={selectedDriver}
            >
              {drivers?.map((driver: FirebaseDriverData, index: number) => (
                <Option key={driver.id} value={driver.id}>
                  {index + 1}&#176;- {`${driver.fullName}`}
                </Option>
              ))}
            </Select>
            {preSelectedDriver ? (
              <div className="flex items-center">
                <Button
                  className="mt-10 mr-10"
                  loading={false}
                  text="Reivindicar pedido"
                  bgcolor={"white"}
                  textcolor={"red"}
                  onClick={handleUnassignSubmit}
                />
                <Button
                  loading={assignDriverMutation.isLoading}
                  className="mt-10"
                  disabled={isLoading || !drivers || !drivers.length}
                  text="Atribuir pedido ao entregador"
                  bgcolor={"red"}
                  textcolor={"white"}
                  onClick={handleAssignSubmit}
                />
              </div>
            ) : (
              <Button
                loading={assignDriverMutation.isLoading}
                className="mt-10"
                disabled={isLoading || !drivers || !drivers.length}
                text="Atribuir pedido ao entregador"
                bgcolor={"red"}
                textcolor={"white"}
                onClick={handleAssignSubmit}
              />
            )}
          </>
        ) : null}
        {assignDriverMutation.isSuccess && <SuccessAssign toggleModal={toggleModal} />}
        {assignDriverMutation.isError && (
          <>
            <p className="w-80 mb-10">
              <span className="text-red">
                <strong>Erro ao atribuir pedido.</strong>
              </span>
              <br />
              Tente novamente.
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AssignDriverModal;
