import { useContext, useEffect, useState } from "react";
import { StoreContext } from "contexts/store.context";
import DriverDeliveryFeeCard from "./DriverDeliveryFeeCard";
import DriverRadiusFeeCard from "./DriverRadiusFeeCard";
import DriverQuilometerFeeCard from "./DriverKilometerFeeCard";
import styled from "styled-components";
import { Radio, RadioChangeEvent } from "antd";
import DataCard from "components/DataCard";
import { DriverPaymentConfig } from "interfaces/domain/store.interface";
import { useMutation } from "react-query";
import StoreService from "services/store.service";

const StyledRadio = styled(Radio)`
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 0.625rem;
  padding: 0.625rem;
  cursor: pointer;
`;

const DriverPaymentTab = () => {
  const { store, setStore } = useContext(StoreContext);
  const [radioValue, setRadioValue] = useState(-1);
  const [paymentConfig, setPaymentConfig] = useState<DriverPaymentConfig>();

  useEffect(() => {
    if (!store) return;
    setPaymentConfig(store.driverPaymentConfig);
  }, [store]);

  useEffect(() => {
    if (!paymentConfig) return setRadioValue(-1);
    if (paymentConfig.deliveryFee) {
      setRadioValue(1);
    } else if (paymentConfig.radiusFee) {
      setRadioValue(2);
    } else if (paymentConfig.kilometerFee) {
      setRadioValue(3);
    }
  }, [paymentConfig, store]);

  const mutation = useMutation(
    (param: DriverPaymentConfig) => StoreService.updateDriverPayment(param),
    {
      onSuccess: (updatedStore) => {
        setStore(updatedStore);
      },
    }
  );

  if (!store) return <h1>Entre novamente na plataforma</h1>;
  return (
    <div className="mt-4 px-4">
      <DataCard
        title="Configurar pagamento de entregadores"
        description="Selecione e configure o modelo a ser considerado"
      >
        <Radio.Group
          onChange={(e: RadioChangeEvent) => setRadioValue(e.target.value)}
          value={radioValue}
        >
          <StyledRadio value={1}>Pagamento por entrega</StyledRadio>
          <StyledRadio value={2}>Pagamento por raio</StyledRadio>
          <StyledRadio value={3}>Pagamento por distância</StyledRadio>
        </Radio.Group>
        <div className="mt-6">
          {radioValue === 1 && (
            <DriverDeliveryFeeCard
              mutateConfig={mutation.mutateAsync}
              deliveryFee={{
                fee: paymentConfig?.deliveryFee?.fee,
                options: paymentConfig?.deliveryFee?.options,
              }}
              setPaymentConfig={setPaymentConfig}
            />
          )}
          {radioValue === 2 && (
            <DriverRadiusFeeCard
              mutateConfig={mutation.mutateAsync}
              driverRadiusFee={paymentConfig?.radiusFee}
              setPaymentConfig={setPaymentConfig}
            />
          )}
          {radioValue === 3 && (
            <DriverQuilometerFeeCard
              mutateConfig={mutation.mutateAsync}
              driverKilometerFee={paymentConfig?.kilometerFee}
              setPaymentConfig={setPaymentConfig}
            />
          )}
        </div>
      </DataCard>
    </div>
  );
};

export default DriverPaymentTab;
