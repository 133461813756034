import { OrderStatusName } from "interfaces/domain/order.interface";
import { ReactComponent as UnassignedMarker } from "assets/icons/orders/unassigned-marker.svg";
import { ReactComponent as AssignedMarker } from "assets/icons/orders/assigned-marker.svg";
import { ReactComponent as InTransitMarker } from "assets/icons/orders/intransit-marker.svg";

interface OrderMarkerIconProps {
  orderStatus: OrderStatusName;
}

const getOrderIcon = (orderStatus: OrderStatusName) => {
  if (orderStatus === "ASSIGNED") {
    return <AssignedMarker />;
  } else if (orderStatus === "IN_TRANSIT") {
    return <InTransitMarker />;
  } else {
    return <UnassignedMarker />;
  }
};

const OrderMarkerIcon: React.FC<OrderMarkerIconProps> = ({ orderStatus }) => {
  return getOrderIcon(orderStatus);
};

export default OrderMarkerIcon;
