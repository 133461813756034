import React, { useState } from "react";
import { ReactComponent as DetailsIcon } from "assets/icons/details-icon.svg";
import IntegrationSourceLogo from "components/IntegrationSourceLogo";
import OrderStatusTag from "components/OrderStatusTag";
import Order, { OrderStatusName } from "interfaces/domain/order.interface";
import { unixToViewDateTime } from "utils/date.utils";
import InnerNavLink from "components/InnerNavLink";
import { UserOutlined } from "@ant-design/icons";
import OrderService from "services/order.service";
import { Avatar, Space, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import { useQuery } from "react-query";
import Table from "antd/lib/table";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import ptBR from "antd/lib/date-picker/locale/pt_BR";
import OrderSearchInput from "./components/OrderSearchInput";

interface OrderHistoryTabProps {
  toggleOrderDetails: (order: Order) => void;
}

const { RangePicker } = generatePicker(dayjsGenerateConfig);
const dateFormatList = ["DD/MM/YYYY - HH:mm", "DD/MM/YY - HH:mm"];

const OrderHistoryTab: React.FC<OrderHistoryTabProps> = (props) => {
  const [dates, setDates] = useState<any>([]);
  const [value, setValue] = useState<any>();
  const { toggleOrderDetails } = props;
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const [filtredData, setFiltredData] = useState<Order[] | undefined>([]);
  const onSearch = (searchValue: string) => {
    if (!data) return;
    const filtred = data.filter((order) => {
      return order.displayId.includes(searchValue);
    });
    setFiltredData(filtred);
  };

  const { data, isLoading } = useQuery(
    "ordersHistory",
    () => OrderService.getOrdersBetweenDates(startDate, endDate),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: startDate !== 0 && endDate !== 0,
      onSuccess: () => {
        setStartDate(0);
        setEndDate(0);
      },
    }
  );

  const submitRangePicker = (dates: any) => {
    const startDate = dates[0] && dates[0].unix();
    const endDate = dates[1] && dates[1].unix();
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  return (
    <>
      <div className="flex items-center mb-6">
        <InnerNavLink to="/" text="Mapa" />
        <InnerNavLink to={`/orders/list`} text="Lista" />
        <InnerNavLink to={`/orders/historic`} text="Histórico" />
      </div>
      <div className="flex gap-6 mb-6">
        <div>
          <RangePicker
            value={value}
            disabledDate={(current) => {
              if (!dates || dates.length === 0) {
                return false;
              }
              const tooLate = dates[0] && current.diff(dates[0], "days") > 60;
              const tooEarly = dates[1] && dates[1].diff(current, "days") < 60;
              return tooEarly || tooLate;
            }}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValue(val)}
            disabled={isLoading}
            showTime={{ format: "HH:mm" }}
            format={dateFormatList}
            onOk={submitRangePicker}
            locale={ptBR}
          />
        </div>
        <OrderSearchInput onSearch={onSearch} placeholder="Pesquisar por pedido" />
      </div>
      <Table
        dataSource={filtredData && filtredData.length > 0 ? filtredData : data}
        loading={isLoading}
        rowKey="_id"
        size="middle"
      >
        <Column
          title="Pedido"
          dataIndex="displayId"
          key="displayId"
          render={(value) => <b>#{value}</b>}
        />
        <Column
          title="Via"
          dataIndex="integrationSource"
          key="integrationSource"
          render={(value) => <IntegrationSourceLogo integrationSource={value} />}
        />
        <Column
          title="Hora do pedido"
          dataIndex="orderTimestamp"
          key="orderTimestamp"
          render={(value) => unixToViewDateTime(value)}
        />
        <Column
          title="Status"
          dataIndex="lastOrderStatus"
          key="lastOrderStatus"
          render={(value: OrderStatusName) => <OrderStatusTag status={value} bg />}
        />
        <Column
          title="Entregador"
          dataIndex="driver"
          key="driver"
          render={(value) =>
            value && (
              <div className="flex items-center">
                <span className="mr-2">
                  <Avatar src={value.profileImage} icon={!value.profileImage && <UserOutlined />} />
                </span>
                <span>
                  {value.firstName} {value.lastName}
                </span>
              </div>
            )
          }
        />
        <Column
          title="Ações"
          key="actions"
          render={(_text, record: Order) => (
            <Space size="middle">
              <Tooltip title="Detalhes" className="cursor-pointer">
                <DetailsIcon onClick={() => toggleOrderDetails(record)} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default OrderHistoryTab;
