import styled from "styled-components";
import { ReactComponent as DragAndDropIcon } from "assets/icons/drag-and-drop-icon.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { useState } from "react";
import { Address } from "interfaces/domain/global.interface";

const StyledDragOrderPortrait = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  border: 0.0625rem solid #eeeeee;
  border-radius: 0.625rem;
  padding: 0.625rem;
  font-size: 0.875rem;
`;

interface DragOrderPortraitProps {
  orderId: string;
  address: Address;
}

const StyledInfo = styled.div`
  min-width: 15.625rem;
  position: absolute;
  top: -165%;
  left: -20%;
  padding: 1rem;
  background: #fff;
  border: 0.0625rem solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(184, 184, 184, 0.25);
  text-align: left;
  z-index: 10;
`;

const DragOrderPortrait: React.FC<DragOrderPortraitProps> = ({ orderId, address }) => {
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  return (
    <StyledDragOrderPortrait>
      <DragAndDropIcon />
      <span className="orderId">#{orderId}</span>
      <div
        className="flex items-center"
        onMouseEnter={() => setInfoIsVisible(true)}
        onMouseLeave={() => setInfoIsVisible(false)}
      >
        <StyledInfo className={infoIsVisible ? "block" : "hidden"}>
          <p>
            Endereço: {address.streetName}, {address.streetNumber}
          </p>
          <p>Bairro: {address.neighborhood}</p>
        </StyledInfo>
        <InfoIcon />
      </div>
    </StyledDragOrderPortrait>
  );
};

export default DragOrderPortrait;
