import { useState } from "react";
import PageTitle from "components/layout/PageTitle";
import Button from "components/layout/Button";
import InviteDriverModal from "./InviteDriverModal";
import "./Drivers.scss";
import InnerNavLink from "components/InnerNavLink";
import { Outlet } from "react-router-dom";

const DriversPage = () => {
  const [visibleModal, setVisibleModal] = useState("");
  const toggleVisibleModal = (modalName?: string) => {
    setVisibleModal("");
    if (!visibleModal && modalName && modalName !== visibleModal) {
      setVisibleModal(modalName);
    }
  };

  return (
    <>
      <InviteDriverModal
        isVisible={visibleModal === "InviteDriverModal"}
        toggleModal={() => toggleVisibleModal("InviteDriverModal")}
      />

      <div className="flex">
        <PageTitle title="Entregadores" />
      </div>

      <div className="mt-5"></div>

      <div className="driver__opts">
        <div>
          <InnerNavLink to="drivers" text="Associados" />
          <InnerNavLink to="drivers/invited" text="Convidados" />
        </div>
        <div>
          <Button
            className="tour-invite-driver"
            text="Convidar novo entregador"
            bgcolor="red"
            textcolor="white"
            onClick={() => toggleVisibleModal("InviteDriverModal")}
          />
        </div>
      </div>

      <Outlet />
    </>
  );
};

export default DriversPage;
