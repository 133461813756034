import { DriverStatusName } from "interfaces/domain/driver.interface";
import { ReactComponent as InTransitDriverMarker } from "assets/icons/orders/intransit-driver-marker.svg";
import { ReactComponent as ReturningDriverMarker } from "assets/icons/orders/returning-driver-marker.svg";

interface DriverMarkerIconProps {
  driverStatus: DriverStatusName;
}

const getDriverIcon = (driverStatus: DriverStatusName) => {
  if (driverStatus === "IN_TRANSIT") {
    return <InTransitDriverMarker />;
  } else {
    return <ReturningDriverMarker />;
  }
};

const DriverMarkerIcon: React.FC<DriverMarkerIconProps> = ({ driverStatus }) => {
  return getDriverIcon(driverStatus);
};

export default DriverMarkerIcon;
