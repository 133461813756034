import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";

const StyledMapAction = styled.div`
  width: 1.8125rem;
  height: 1.8125rem;
  background: #fff;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface MapActionProps {
  tooltip: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const MapAction: React.FC<MapActionProps> = (props) => {
  return (
    <Tooltip title={props.tooltip} className="cursor-pointer" placement="left">
      <StyledMapAction onClick={props.onClick}>{props.icon}</StyledMapAction>
    </Tooltip>
  );
};

export default MapAction;
