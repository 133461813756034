import { useEffect } from "react";
import Store, {
  DriverDistanceConfig,
  DriverPaymentConfig,
} from "interfaces/domain/store.interface";
import { Form, Input, Button as AntdButton, Space, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import currency from "currency.js";
import { UseMutateAsyncFunction } from "react-query";
import toast from "react-hot-toast";

interface DriverRadiusFeeCardProps {
  driverRadiusFee: DriverDistanceConfig[] | undefined;
  mutateConfig: UseMutateAsyncFunction<Store, unknown, DriverPaymentConfig, unknown>;
  setPaymentConfig: React.Dispatch<React.SetStateAction<DriverPaymentConfig | undefined>>;
}

const DriverRadiusFeeCard: React.FC<DriverRadiusFeeCardProps> = (props) => {
  const { driverRadiusFee, mutateConfig, setPaymentConfig } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [driverRadiusFee, form]);

  const onFinishForm = (values: any) => {
    const { deliveryFee } = values as { deliveryFee: DriverDistanceConfig[] };
    const params = { radiusFee: deliveryFee };
    setPaymentConfig(params);
    if (JSON.stringify(deliveryFee) === JSON.stringify(driverRadiusFee)) {
      toast.success("Dados inalterados.");
    } else {
      toast.promise(mutateConfig(params), {
        loading: "Carregando...",
        success: "Pagamento por radio atualizado com sucesso!",
        error: "Erro ao atualizar pagamento por raio.",
      });
    }
  };

  return (
    <Form form={form} onFinish={(values) => onFinishForm(values)}>
      <Form.List name="deliveryFee" initialValue={driverRadiusFee}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...field}
                  label="Faixa de km"
                  name={[field.name, "toRange"]}
                  hasFeedback
                  normalize={(value, _) => currency(value, { decimal: "," }).value}
                  rules={[{ required: true, type: "number" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Taxa cobrada"
                  name={[field.name, "deliveryFee"]}
                  hasFeedback
                  normalize={(value, _) => currency(value, { decimal: "," }).value}
                  rules={[{ type: "number" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Taxa para o entregador"
                  name={[field.name, "driverFee"]}
                  hasFeedback
                  normalize={(value, _) => currency(value, { decimal: "," }).value}
                  rules={[{ required: true, type: "number" }]}
                >
                  <Input />
                </Form.Item>
                <Tooltip title="Remover faixa">
                  <MinusCircleOutlined
                    className="cursor-pointer"
                    onClick={() => remove(field.name)}
                  />
                </Tooltip>
              </Space>
            ))}
            <div className="flex gap-4 mt-4">
              <AntdButton type="dashed" icon={<PlusOutlined />} onClick={() => add()}>
                Adicionar faixa
              </AntdButton>
              <AntdButton htmlType="submit">Salvar configuração</AntdButton>
            </div>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default DriverRadiusFeeCard;
