import React from "react";
import { FirebaseDriverData } from "./OrdersMap/OrdersMap.interface";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { getCompleteHour } from "utils/date.utils";
import DriverStatusTag from "components/DriverStatusTag";

interface DriverMarkerPopupProps {
  onlineDriver: FirebaseDriverData;
}

const DriverMarkerPopup: React.FC<DriverMarkerPopupProps> = (props) => {
  const { onlineDriver } = props;

  return (
    <div style={{ fontFamily: "Poppins", color: "#2D3134" }} className="p-3 cursor-pointer">
      <div className="mb-3 flex flex-col">
        <div className="flex items-center">
          <span className="mr-2">
            <Avatar
              src={onlineDriver.profileImage}
              icon={!onlineDriver.profileImage && <UserOutlined />}
            />
          </span>
          <div className="flex flex-col">
            <span className="text-xs">{onlineDriver.fullName}</span>
            <DriverStatusTag status={onlineDriver.status} />
          </div>
        </div>
      </div>
      <div>
        {onlineDriver.status === "IN_TRANSIT" && (
          <div>
            <p>Pedido(s) sendo entregue(s):</p>
            <div className="flex justify-around">
              {onlineDriver &&
                onlineDriver.inTransitOrders.map((displayId) => (
                  <span key={displayId} className="orderId">
                    #{displayId}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className="mt-2">
        <div className="mb-1">
          <p>Distancia total a percorrer:</p>
          <b>
            {onlineDriver.deliveryDistance && onlineDriver.deliveryDistance.replace(".", ",")} km
          </b>
        </div>
        <div>
          <p>Previsão de retorno:</p>
          <b>{getCompleteHour(onlineDriver.estimatedOrderEndTime)}</b>
        </div>
      </div>
    </div>
  );
};

export default DriverMarkerPopup;
