import React from "react";

interface ModalProps {
  text: string;
  className?: string;
}

const ModalTitle = ({ text, className }: ModalProps): React.ReactNode => {
  return (
    <h2
      className={`text-2xl${className || ""}`}
      style={{
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        margin: 0,
      }}
    >
      {text}
    </h2>
  );
};

export default ModalTitle;
