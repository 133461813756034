import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import appEnv from "utils/appEnv";

const firebaseConfig = {
  apiKey: appEnv.FIREBASE_API_KEY,
  authDomain: appEnv.FIREBASE_AUTH_DOMAIN,
  databaseURL: appEnv.FIREBASE_DATABASE_URL,
  projectId: appEnv.FIREBASE_PROJECT_ID,
  storageBucket: appEnv.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: appEnv.FIREBASE_MESSAGING_SENDER_ID,
  appId: appEnv.FIREBASE_APP_ID,
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const rtdb = getDatabase(firebase);
const firestore = getFirestore(firebase);

export { firebase, auth, rtdb, firestore };
