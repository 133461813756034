import React, { useContext, useEffect, useState } from "react";
import Modal from "components/layout/Modal";
import DriverService from "services/driver.service";
import { FirebaseDriverData, FirebaseDriverSnapshot } from "./OrdersMap.interface";
import DriverStatusTag from "components/DriverStatusTag";
import { getCompleteHour } from "utils/date.utils";
import { Dropdown, Menu } from "antd";
import { StoreContext } from "contexts/store.context";
import { ReactComponent as ArrowDownIcon } from "assets/icons/orders/arrowdown-icon.svg";

interface DriverInfoModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  storeId: string | undefined;
}

const DriverInfoModal: React.FC<DriverInfoModalProps> = (props) => {
  const { isVisible, toggleModal, storeId } = props;
  const { mapInstance } = useContext(StoreContext);
  const [driverInfoData, setDriverInfoData] = useState<FirebaseDriverData[]>();

  useEffect(() => {
    if (!storeId) return;
    const driversRef = DriverService.getDriversRef(storeId);
    driversRef.on("child_removed", (snapshot) => {
      if (!snapshot.exists()) return;
      const driverObj = snapshot.val() as FirebaseDriverData;
      setDriverInfoData(
        (oldDrivers) => oldDrivers && oldDrivers.filter((driver) => driver.id !== driverObj.id)
      );
    });
    const driverListener = driversRef.on("value", (snapshot) => {
      const snapshotObj = snapshot.val() as FirebaseDriverSnapshot;
      if (!snapshotObj) return;
      const onlineDrivers = Object.values(snapshotObj) as FirebaseDriverData[];
      setDriverInfoData(onlineDrivers);
    });

    return () => driversRef.off("value", driverListener);
  }, [storeId]);

  return (
    <Modal width={1124} title="Entregadores online" visible={isVisible} onCancel={toggleModal}>
      {driverInfoData ? (
        <ul className="driverInfo-list">
          {driverInfoData.map((info) => (
            <li key={info.id} className="driverInfo-item-list">
              <div className="flex justify-around items-center">
                <span>{info.fullName}</span>
                {info.status === "IN_TRANSIT" && (
                  <span style={{ fontSize: "14px" }}>
                    (retorno as {getCompleteHour(info.estimatedOrderEndTime)})
                  </span>
                )}
                <DriverStatusTag status={info.status} bg />
                {info.status === "IN_TRANSIT" && (
                  <Dropdown
                    className="cursor-pointer"
                    overlay={
                      <Menu>
                        <Menu.Item
                          key={info.id}
                          onClick={(e) => {
                            e.domEvent.stopPropagation();
                            mapInstance?.flyTo({
                              center: [info.coordinates.lng, info.coordinates.lat],
                              zoom: 13,
                            });
                          }}
                        >
                          Centralizar
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <div>
                      <span>
                        <ArrowDownIcon className="align-middle" />
                      </span>
                    </div>
                  </Dropdown>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="mt-10 mb-10 text-sm text-center">Nenhum entregador online.</p>
      )}
    </Modal>
  );
};

export default DriverInfoModal;
