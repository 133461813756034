import React from "react";

import { ReactComponent as AssignedOrderIcon } from "assets/icons/orders/assigned-marker.svg";
import { ReactComponent as UnAssignedOrderIcon } from "assets/icons/orders/unassigned-marker.svg";
import { ReactComponent as InTransitOrderIcon } from "assets/icons/orders/intransit-marker.svg";
import { ReactComponent as ConcludedOrderIcon } from "assets/icons/orders/concluded-marker.svg";
import { ReactComponent as CancelledOrderIcon } from "assets/icons/orders/cancelled-marker.svg";
import { OrderStatusName } from "interfaces/domain/order.interface";

interface OrderMarkerProps {
  orderStatus: OrderStatusName;
}

const OrderMarker = ({ orderStatus }: OrderMarkerProps) => {
  const getMarker = (orderStatus: OrderStatusName) => {
    if (orderStatus === "UNASSIGNED") {
      return <UnAssignedOrderIcon width="24" height="24" className="align-middle" />;
    } else if (orderStatus === "ASSIGNED") {
      return <AssignedOrderIcon width="24" height="24" className="align-middle" />;
    } else if (orderStatus === "IN_TRANSIT") {
      return <InTransitOrderIcon width="24" height="24" className="align-middle" />;
    } else if (orderStatus === "CONCLUDED") {
      return <ConcludedOrderIcon width="24" height="24" className="align-middle" />;
    } else {
      return <CancelledOrderIcon width="24" height="24" className="align-middle" />;
    }
  };

  return getMarker(orderStatus);
};

export default OrderMarker;
