import styled from "styled-components";

export const MapContainer = styled.div`
  display: grid;
  gap: 2.8125rem;
  grid-template-areas: "map orderfeed";
  grid-template-columns: 1fr 21rem;
  grid-template-rows: 100%;
  width: 100%;
  height: calc(100vh - 280px);
`;

export const Map = styled.div`
  grid-area: map;
`;

export const OrderFeedContainer = styled.div`
  grid-area: orderfeed;
`;

export const ScrollContainer = styled.div`
  margin-top: 0.75rem;
  max-height: 90%;
  overflow: auto;
  padding-right: 0.625rem;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(121, 119, 119);
  }
`;
