import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import appEnv from "utils/appEnv";

const firebaseConfig = {
  apiKey: appEnv.FIREBASE_API_KEY,
  authDomain: appEnv.FIREBASE_AUTH_DOMAIN,
  databaseURL: appEnv.FIREBASE_DATABASE_URL,
  projectId: appEnv.FIREBASE_PROJECT_ID,
  storageBucket: appEnv.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: appEnv.FIREBASE_MESSAGING_SENDER_ID,
  appId: appEnv.FIREBASE_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export default firebase;
