import currency from "currency.js";
import dayjs from "dayjs";
import { OrderStatus, OrderStatusName, StoreOrderReq } from "interfaces/domain/order.interface";
import { CreateOrderFormValues } from "pages/Orders/components/CreateOrder/CreateOrder.interface";

export const getOrderStatus = (orderStatus: OrderStatus[]) => {
  return orderStatus[orderStatus.length - 1].name;
};

export const getOrderTranslatedStatus = (orderStatus: OrderStatusName) => {
  if (orderStatus === "UNASSIGNED") {
    return "Não Atribuido";
  } else if (orderStatus === "ASSIGNED") {
    return "Atribuido";
  } else if (orderStatus === "IN_TRANSIT") {
    return "Em Trânsito";
  } else if (orderStatus === "CONCLUDED") {
    return "Concluido";
  } else {
    return "Cancelado";
  }
};

export const getOrderStatusColor = (orderStatus: OrderStatusName): string => {
  let statusColor;
  if (orderStatus === "UNASSIGNED") {
    statusColor = "#898a8b";
  } else if (orderStatus === "ASSIGNED") {
    statusColor = "#4998F5";
  } else if (orderStatus === "IN_TRANSIT") {
    statusColor = "#58CCA1";
  } else if (orderStatus === "CONCLUDED") {
    statusColor = "#74DE4F";
  } else {
    statusColor = "#F66F4D";
  }
  return statusColor;
};

export const getOrderStatusBorderColor = (orderStatus: OrderStatusName): string => {
  let statusColor;
  if (orderStatus === "UNASSIGNED") {
    statusColor = "#eeefef";
  } else if (orderStatus === "ASSIGNED") {
    statusColor = "#EBF3FC";
  } else if (orderStatus === "IN_TRANSIT") {
    statusColor = "#EDFAF5";
  } else if (orderStatus === "CONCLUDED") {
    statusColor = "#F0FAEC";
  } else {
    statusColor = "#FDEFEC";
  }
  return statusColor;
};

export const getOrderStatusTextColor = (orderStatus: OrderStatusName): string => {
  let statusColor;
  if (orderStatus === "UNASSIGNED") {
    statusColor = "#747474";
  } else if (orderStatus === "ASSIGNED") {
    statusColor = "#4998F5";
  } else if (orderStatus === "IN_TRANSIT") {
    statusColor = "#58CCA1";
  } else if (orderStatus === "CONCLUDED") {
    statusColor = "#74DE4F";
  } else {
    statusColor = "#F66F4D";
  }
  return statusColor;
};

export const buildCreateOrderPayload = (
  storeId: string,
  formValues: CreateOrderFormValues
): StoreOrderReq => {
  const totalPrice = currency(formValues.totalPrice, { decimal: "," }).value;
  const changeFor = currency(formValues.changeFor, { decimal: "," }).value;
  const cleanPhone = (formValues.cellphone || formValues.phone || "").replace(/[^\w][\s]*/gi, "");
  const deliveryFee = currency(formValues.deliveryFee || 0, {
    decimal: ",",
  }).value;

  const orderPayload: StoreOrderReq = {
    displayId: formValues.displayId,
    integrationSource: formValues.integrationSource,
    lastOrderStatus: formValues.lastOrderStatus || "UNASSIGNED",
    orderStatus: formValues.orderStatus || [{ name: "UNASSIGNED" }],
    note: formValues.note || undefined,
    customer: {
      name: formValues.name,
    },
    deliveryAddress: {
      country: "BR",
      state: formValues.state,
      city: formValues.city,
      streetName: formValues.streetName,
      streetNumber: formValues.streetNumber,
      neighborhood: formValues.neighborhood,
      formattedAddress: formValues.formattedAddress,
      postalCode: formValues.postalCode,
      reference: formValues.reference,
      complement: formValues.complement,
      coordinates: formValues.coordinates,
    },
    payments: {
      prepaid: formValues.paymentMethod === "ONLINE" ? totalPrice : 0,
      pending: formValues.paymentMethod === "ONLINE" ? 0 : totalPrice,
      methods: [
        {
          value: totalPrice,
          currency: "BRL",
          method: formValues.paymentMethod === "ONLINE" ? "CREDIT" : formValues.paymentMethod,
          paymentType: formValues.paymentMethod === "ONLINE" ? "ONLINE" : "OFFLINE",
          changeFor,
        },
      ],
    },
    paymentSummary: {
      changeFor,
      deliveryFee,
      subTotalPrice: currency(totalPrice).subtract(deliveryFee).value,
      totalPrice,
    },
    driverRadiusFee: formValues.driverRadiusFee,
    driverKilometerFee: formValues.driverRadiusFee,
    driverDeliveryFee: formValues.driverKilometerFee,
    distance: formValues.distance,
    store: storeId,
    orderTimestamp: formValues.orderTimestamp || dayjs().unix(),
  };
  if (cleanPhone !== "") {
    orderPayload.customer.phone = `55${cleanPhone}`;
  }
  return orderPayload;
};

export const translatePaymentMethod = (paymentMethod: string) => {
  if (paymentMethod === "CASH") {
    return "Dinheiro";
  } else if (paymentMethod === "CREDIT") {
    return "Crédito";
  } else if (paymentMethod === "DEBIT") {
    return "Débito";
  } else if (paymentMethod === "MEAL_VOUCHER" || paymentMethod === "FOOD_VOUCHER") {
    return "Vale Refeição";
  } else if (paymentMethod === "GIFT_CARD") {
    return "Gift Card";
  } else if (paymentMethod === "WALLET") {
    return "Carteira Digital";
  } else if (paymentMethod === "PIX") {
    return "PIX";
  } else {
    return "Outros";
  }
};

export const getStateLabel = (state: string): string => {
  let stateLabel = "";
  switch (state) {
    case "Acre":
      stateLabel = "AC";
      break;
    case "Alagoas":
      stateLabel = "AL";
      break;
    case "Amapá":
      stateLabel = "AP";
      break;
    case "Amazonas":
      stateLabel = "AM";
      break;
    case "Bahia":
      stateLabel = "BA";
      break;
    case "Ceará":
      stateLabel = "CE";
      break;
    case "Distrito Federal":
      stateLabel = "DR";
      break;
    case "Espírito Santo":
      stateLabel = "ES";
      break;
    case "Goiás":
      stateLabel = "GO";
      break;
    case "Maranhão":
      stateLabel = "MA";
      break;
    case "Mato Grosso":
      stateLabel = "MT";
      break;
    case "Mato Grosso do Sul":
      stateLabel = "MS";
      break;
    case "Minas Gerais":
      stateLabel = "MG";
      break;
    case "Pará":
      stateLabel = "PA";
      break;
    case "Paraíba":
      stateLabel = "PB";
      break;
    case "Paraná":
      stateLabel = "PR";
      break;
    case "Pernambuco":
      stateLabel = "PE";
      break;
    case "Piauí":
      stateLabel = "PI";
      break;
    case "Rio de Janeiro":
      stateLabel = "RJ";
      break;
    case "Rio Grande do Norte":
      stateLabel = "RN";
      break;
    case "Rio Grande do Sul":
      stateLabel = "RS";
      break;
    case "Rondônia":
      stateLabel = "RO";
      break;
    case "Roraima":
      stateLabel = "RR";
      break;
    case "Santa Catarina":
      stateLabel = "SC";
      break;
    case "Sergipe":
      stateLabel = "SE";
      break;
    case "Tocantins":
      stateLabel = "TO";
      break;
    default:
      stateLabel = "SP";
      break;
  }
  return stateLabel;
};

export const getFormattedAddress = (
  streetName: string,
  streetNumber: string,
  neighborhood: string,
  city: string,
  state: string,
  postalCode: string
) => {
  return `${streetName}, ${streetNumber} - ${neighborhood}, ${city} - ${state}, ${postalCode}`;
};

export const handleDriverBigName = (firstName: string, lastName: string) => {
  const fullNameArr = `${firstName} ${lastName}`.split(" ");
  const fullName = `${fullNameArr[0]} ${fullNameArr[fullNameArr.length - 1]}`;
  const fullNameLen = fullName.length;
  if (fullNameLen > 20) {
    return `${fullName.substring(0, 15)}.`;
  } else {
    return fullName;
  }
};
