import { ApiClient } from "utils/apiClient";
import Order, {
  OrdersByDriver,
  StoreOrderReq,
  UpdateOrderReq,
  UpdateOrderStatusReq,
} from "interfaces/domain/order.interface";

const OrderService = {
  async createOrder(order: StoreOrderReq): Promise<Order> {
    const { data } = await ApiClient.post("/order", order);
    return data;
  },

  async updateOrder(params: UpdateOrderReq): Promise<Order> {
    const { data } = await ApiClient.put(`/order/${params.orderId}`, params.order);
    return data;
  },

  async updateOrderStatus(params: UpdateOrderStatusReq) {
    const { data } = await ApiClient.post<Order>("/order/status", params);
    return data;
  },

  async deleteOrder(orderId: string): Promise<Order> {
    const { data } = await ApiClient.delete(`/order/${orderId}`);
    return data;
  },

  async getActiveOrders() {
    const { data } = await ApiClient.get<Order[]>("/order/active");
    return data;
  },

  async getActiveiFoodOrders(merchantId: string) {
    const { data } = await ApiClient.get<Order[]>(`/ifood/orders/${merchantId}`);
    return data;
  },

  async getOrdersBetweenDates(startDate: number, endDate: number) {
    const { data } = await ApiClient.get<Order[]>(`/order/${startDate}/${endDate}`);
    return data;
  },

  async assignDriver(orderId: string, driverId: string) {
    const { data } = await ApiClient.post<Order>("/order/assign/driver", {
      orderId,
      driverId,
    });
    return data;
  },

  async batchAssignDriver(orderIds: string[], driverId: string) {
    const { data } = await ApiClient.post<Order[]>("/order/assign/driver/batch", {
      orderIds,
      driverId,
    });
    return data;
  },

  async unassignDriver(orderId: string) {
    const { data } = await ApiClient.put<Order>("/order/assign/driver", {
      orderId,
    });
    return data;
  },

  async getCountOrdersByDriver(params: { startDate: number; endDate: number }) {
    const { data } = await ApiClient.get<OrdersByDriver[]>(
      `/order/metric/orderbydriver/${params.startDate}/${params.endDate}`
    );
    return data;
  },

  async getOrdersByDrivePeriod(driverId: string, startDate: number, endDate: number) {
    const { data } = await ApiClient.get<Order[]>(
      `/order/metric/bydriver/${driverId}/${startDate}/${endDate}`
    );
    return data;
  },
};

export default OrderService;
