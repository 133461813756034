import React from "react";
import FleetmapLogo from "assets/images/logos/fleetmap-logo.png";
import iFoodLogo from "assets/images/logos/ifood-logo.png";
import AcconLogo from "assets/images/logos/accon-logo.png";

interface IntegrationSourceLogoProps {
  integrationSource: string;
  size?: "small" | "default" | "big";
}

const IntegrationSourceLogo: React.FC<IntegrationSourceLogoProps> = (props) => {
  const { integrationSource, size } = props;

  const getSize = (size?: string) => {
    if (size === "small") {
      return "w-20";
    } else if (size === "big") {
      return "w-28";
    } else {
      return "w-24";
    }
  };

  const resolveLogoImage = () => {
    if (integrationSource === "Accon") {
      return <img src={AcconLogo} alt="Logo Accon" />;
    } else if (integrationSource === "iFood") {
      return <img src={iFoodLogo} alt="Logo iFood" />;
    } else {
      return <img src={FleetmapLogo} alt="Logo Fleetmap" />;
    }
  };

  return (
    <figure className={getSize(size)} style={{ height: "2.5rem" }}>
      {resolveLogoImage()}
    </figure>
  );
};

export default IntegrationSourceLogo;
