import React, { useState } from "react";
import Modal from "components/layout/Modal";
import { Form, Input } from "antd";
import Button from "components/layout/Button";
import firebase from "credentials/firebase.credentials";

interface ChangePasswordModalProps {
  isVisible: boolean;
  toggleModal: () => void;
}

interface FormValues {
  oldPassword: string;
  password: string;
  passwordRepeat: string;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props) => {
  const { isVisible, toggleModal } = props;

  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (values: FormValues) => {
    setIsLoading(true);
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || !currentUser.email) return;
    try {
      const authCredential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        values.oldPassword
      );
      await currentUser.reauthenticateWithCredential(authCredential);
      await currentUser.updatePassword(values.password);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Modal title="Alterar senha" width={920} visible={isVisible} onCancel={toggleModal}>
      <Form layout="vertical" onFinish={(values) => submitForm(values)}>
        <Form.Item name="oldPassword" label="Digite sua senha atual">
          <Input.Password className="input-password" />
        </Form.Item>
        <Form.Item name="password" label="Digite sua nova senha" hasFeedback>
          <Input.Password className="input-password" />
        </Form.Item>
        <Form.Item
          name="passwordRepeat"
          label="Confirme sua nova senha"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("As senhas digitadas não são iguais!"));
              },
            }),
          ]}
        >
          <Input.Password className="input-password" />
        </Form.Item>
        <Button
          loading={isLoading}
          text="Alterar senha"
          bgcolor="red"
          textcolor="white"
          htmlType="submit"
        />
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
