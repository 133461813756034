import { DriverPaymentConfig } from "interfaces/domain/store.interface";
import { resolveKilometerDistanceFee, resolveRadiusDistanceFee } from "utils/store.utils";

export default function useDriverPayment(paymentConfig?: DriverPaymentConfig) {
  const getRadiusFee = (storeLatLng: number[], orderLatLng: number[]) => {
    if (!paymentConfig || !paymentConfig.radiusFee) return;
    const driverDistanceConfig = resolveRadiusDistanceFee(
      [storeLatLng[0], storeLatLng[1]],
      [orderLatLng[0], orderLatLng[1]],
      paymentConfig?.radiusFee
    );

    return {
      driverFee: driverDistanceConfig.deliveryFee,
      toRange: driverDistanceConfig.toRange,
    };
  };

  const getKilometerFee = (distance: number) => {
    if (!paymentConfig || !paymentConfig.kilometerFee) return;
    return resolveKilometerDistanceFee(distance, paymentConfig.kilometerFee);
  };

  return {
    getRadiusFee,
    getKilometerFee,
  };
}
