import UserDataCard from "./UserDataCard";
import StoreDataTab from "./StoreDataTab";

const RegistredDataTab = () => {
  return (
    <div className="mt-6 flex flex-col gap-6">
      <UserDataCard />
      <StoreDataTab />
    </div>
  );
};

export default RegistredDataTab;
