import React from "react";
import { Outlet } from "react-router-dom";
import logo from "assets/fleetmap-white-logo.png";
import "./AuthLayout.scss";

const AuthLayout: React.FC = () => {
  return (
    <>
      <div className="auth__topbar">
        <div>
          <img src={logo} alt="Fleetmap logo" />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default AuthLayout;
