import dayjs from "dayjs";
import { default as timezone } from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault("America/Sao_Paulo");

export const getToday = (isViewDate: boolean) => {
  if (isViewDate) {
    return dayjs().format("DD/MM/YYYY");
  }
  return dayjs().format("YYYY/MM/DD");
};

export const formatToViewDateTime = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
};

export const unixToViewDateTime = (timestamp: number) => {
  return dayjs.unix(timestamp).format("DD/MM/YYYY HH:mm:ss");
};

export const formatToViewDate = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const formatToApiDate = (date: string) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const formatToLocalizedDateTime = (date: string) => {
  return dayjs(date).locale("pt-br").format("LLLL");
};

export const getCompleteHour = (timestamp: number) => {
  return dayjs.unix(timestamp).format("HH:mm:ss");
};

export const getUnixFromDate = (date: string) => {
  return dayjs(date).unix();
};

export const diffInSeconds = (a: number, b: number) => {
  return dayjs(dayjs.unix(a)).diff(dayjs.unix(b), "seconds");
};
