import React, { useContext, useState } from "react";
import Modal from "components/layout/Modal";
import ModalTitle from "components/layout/ModalTitle";
import Button from "components/layout/Button";
import { useMutation, useQueryClient } from "react-query";
import DriverService, { InviteDriverReq } from "services/driver.service";
import InputMask from "react-input-mask";
import { ReactComponent as TelephoneIcon } from "assets/icons/telephone-icon.svg";
import { StoreContext } from "contexts/store.context";
import DriverInvite from "interfaces/domain/driverInvite.interface";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface InviteDriverModalProps {
  isVisible: boolean;
  toggleModal: () => void;
}

const InviteDriverModal: React.FC<InviteDriverModalProps> = (props) => {
  const { store } = useContext(StoreContext);
  const { isVisible, toggleModal } = props;
  const [driverTelephone, setDriverTelephone] = useState<string>("");
  const [sendSMS, setSendSMS] = useState(false);

  const queryClient = useQueryClient();
  const inviteDriverMutation = useMutation(
    "inviteDriver",
    (inviteReqParams: InviteDriverReq) =>
      DriverService.inviteDriver({
        storeDisplayName: inviteReqParams.storeDisplayName,
        driverTelephone: inviteReqParams.driverTelephone,
        sendSMS,
      }),
    {
      onSuccess(data) {
        queryClient.setQueryData<DriverInvite[]>("getInvitedDrivers", (oldData) => {
          return oldData ? [...oldData, data] : [data];
        });
      },
    }
  );

  const handleDriverInviteButton = () => {
    if (!store) return;
    const fixedDriverTelephone = driverTelephone.replace(/[^\w][\s]*/gi, "");
    if (fixedDriverTelephone.length === 11) {
      inviteDriverMutation.mutate({
        storeDisplayName: store.displayName,
        driverTelephone: fixedDriverTelephone,
        sendSMS,
      });
      setDriverTelephone("");
    }
  };

  const closeModalAndResetMutation = () => {
    inviteDriverMutation.reset();
  };

  const handleCheckbox = (event: CheckboxChangeEvent) => setSendSMS(event.target.checked);

  return (
    <Modal
      title={ModalTitle({ text: "Convidar novo entregador" })}
      width={680}
      visible={isVisible}
      onCancel={toggleModal}
      afterClose={closeModalAndResetMutation}
    >
      {inviteDriverMutation.isIdle || inviteDriverMutation.isLoading ? (
        <div className="flex flex-col justify-center items-center gap-8">
          <p>
            Digite o <strong>celular</strong> do novo entregador e envio o convite por{" "}
            <strong>SMS.</strong>
          </p>
          <InputMask
            id="driverTelephone"
            className="input"
            mask="(99) 99999-9999"
            onChange={(e) => setDriverTelephone(e.target.value)}
          />
          <Checkbox onChange={handleCheckbox}>Enviar SMS para o entregador ?</Checkbox>
          <div>
            <p className="text-sm">
              *O SMS serve para levar o entregador direto ao aplicativo na loja.
            </p>
            <p className="text-sm">
              *Não é necessário quando o entregador ja tiver o app instalado.
            </p>
          </div>
          <div>
            <Button
              loading={inviteDriverMutation.isLoading}
              icon={<TelephoneIcon />}
              text="Convidar entregador"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={handleDriverInviteButton}
            />
          </div>
        </div>
      ) : null}
      {inviteDriverMutation.isSuccess && (
        <>
          <p className="mb-10">
            Convite <strong>enviado</strong> com <span className="text-green">sucesso!</span>
          </p>
          <Button text="Fechar" bgcolor="red" textcolor="white" onClick={toggleModal} />
        </>
      )}
      {inviteDriverMutation.isError && (
        <>
          <p className="mb-10">
            <span className="text-red">Erro ao enviar o convite.</span>
            <br />
            Verifique se o telefone digitado é valido ou se o entregador ja está conectado a você.
          </p>
          <Button text="Fechar" bgcolor="red" textcolor="white" onClick={toggleModal} />
        </>
      )}
    </Modal>
  );
};

export default InviteDriverModal;
