import { useState } from "react";
import { ReactComponent as DetailsIcon } from "assets/icons/details-icon.svg";
import OrderSearchInput from "pages/Orders/components/OrderSearchInput";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import { OrdersByDriver } from "interfaces/domain/order.interface";
import generatePicker from "antd/es/date-picker/generatePicker";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import ptBR from "antd/lib/date-picker/locale/pt_BR";
import PageTitle from "components/layout/PageTitle";
import OrderService from "services/order.service";
import InnerNavLink from "components/InnerNavLink";
import { getMaskedCPF } from "utils/mask.utils";
import { getToday } from "utils/date.utils";
import Column from "antd/lib/table/Column";
import { useMutation } from "react-query";
import { Avatar, Table, Tooltip } from "antd";
import { CSVLink } from "react-csv";
import OrderDetailsModal from "./OrderDetailsModal";
import Driver from "interfaces/domain/driver.interface";
import RealCurrency from "components/RealCurrency/RealCurrency";

const { RangePicker } = generatePicker(dayjsGenerateConfig);
const dateFormatList = ["DD/MM/YYYY - HH:mm", "DD/MM/YY - HH:mm"];

const MetricsPage = () => {
  const [dates, setDates] = useState<any>([]);
  const [value, setValue] = useState<any>();
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const { mutateAsync, data, isLoading } = useMutation(
    (params: { startDate: number; endDate: number }) =>
      OrderService.getCountOrdersByDriver({ ...params })
  );

  const submitRangePicker = (dates: any) => {
    const startDate = dates[0] && dates[0].unix();
    const endDate = dates[1] && dates[1].unix();
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      mutateAsync({ startDate, endDate });
    }
  };

  const [filtredData, setFiltredData] = useState<OrdersByDriver[] | undefined>([]);
  const onSearch = (searchValue: string) => {
    if (!data) return;
    const filtred = data.filter((order) => {
      return order.driver.firstName.includes(searchValue);
    });
    setFiltredData(filtred);
  };

  const prepareDataToDownload = () => {
    if (data) {
      return data;
    }
    return [];
  };

  const [selectedDriver, setSelectedDriver] = useState<Driver | undefined>();
  const [detailsVisible, setDetailsVisible] = useState(false);
  const toggleDetails = () => setDetailsVisible(!detailsVisible);

  return (
    <>
      <OrderDetailsModal
        driver={selectedDriver}
        startDate={startDate}
        endDate={endDate}
        isVisible={detailsVisible}
        toggleModal={toggleDetails}
      />
      <PageTitle title="Métricas" />
      <div className="mt-8 mb-10">
        <InnerNavLink to="" text="Pedidos por motoboy" />
      </div>
      <div className="flex justify-between mb-4">
        <div className="flex justify-between mb-6">
          <div className="mr-6">
            <RangePicker
              value={value}
              disabledDate={(current) => {
                if (!dates || dates.length === 0) {
                  return false;
                }
                const tooLate = dates[0] && current.diff(dates[0], "days") > 60;
                const tooEarly = dates[1] && dates[1].diff(current, "days") < 60;
                return tooEarly || tooLate;
              }}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setValue(val)}
              disabled={isLoading}
              showTime={{ format: "HH:mm" }}
              format={dateFormatList}
              onOk={submitRangePicker}
              locale={ptBR}
            />
          </div>
          <OrderSearchInput onSearch={onSearch} placeholder="Pesquisar por nome" size="large" />
        </div>
        <CSVLink
          className="exportButton"
          filename={`[Fleetmap] - Pedidos por motoboy (${getToday(true)}).csv`}
          headers={[
            { label: "Nome", key: "driver.firstName" },
            { label: "Sobrenome", key: "driver.lastName" },
            { label: "Document", key: "driver.document" },
            { label: "Qtd. de entregadores", key: "ordersByDriver" },
          ]}
          data={prepareDataToDownload()}
        >
          <DownloadOutlined className="text-xl mr-2" /> Exportar dados
        </CSVLink>
      </div>
      <Table
        pagination={{
          pageSize: 25,
        }}
        rowKey={(e) => e.driver && e.driver._id}
        dataSource={filtredData && filtredData.length > 0 ? filtredData : data}
        loading={isLoading}
      >
        <Column
          title="Entregador"
          dataIndex="driverName"
          key="driverName"
          sorter={(a: OrdersByDriver, b: OrdersByDriver) =>
            a.driver.firstName.localeCompare(b.driver.firstName)
          }
          render={(_value, record: OrdersByDriver) => (
            <>
              <Avatar
                src={record.driver.profileImage}
                icon={!record.driver.profileImage && <UserOutlined />}
                size="large"
              />
              <span className="ml-2">
                {record.driver.firstName} {record.driver.lastName}
              </span>
            </>
          )}
        />
        <Column
          title="Documento"
          dataIndex="document"
          key="document"
          render={(_text, record: OrdersByDriver) => getMaskedCPF(record.driver.document)}
        />
        <Column
          title="Qtd. de pedidos"
          dataIndex="ordersByDriver"
          key="ordersByDriver"
          sorter={(a: OrdersByDriver, b: OrdersByDriver) => a.ordersByDriver - b.ordersByDriver}
          render={(_text, record: OrdersByDriver) => record.ordersByDriver}
        />
        <Column
          title="A pagar por entrega"
          dataIndex="deliveryFee"
          key="deliveryFee"
          sorter={(a: OrdersByDriver, b: OrdersByDriver) => a.deliveryFee - b.deliveryFee}
          render={(_text, record: OrdersByDriver) => (
            <RealCurrency valueToConvert={record.deliveryFee} sign />
          )}
        />
        <Column
          title="A pagar por raio"
          dataIndex="radiusFee"
          key="radiusFee"
          sorter={(a: OrdersByDriver, b: OrdersByDriver) => a.radiusFee - b.radiusFee}
          render={(_text, record: OrdersByDriver) => (
            <RealCurrency valueToConvert={record.radiusFee} sign />
          )}
        />
        <Column
          title="A pagar por distância"
          dataIndex="kilometerFee"
          key="kilometerFee"
          sorter={(a: OrdersByDriver, b: OrdersByDriver) => a.kilometerFee - b.kilometerFee}
          render={(_text, record: OrdersByDriver) => (
            <RealCurrency valueToConvert={record.kilometerFee} sign />
          )}
        />
        <Column
          title="Ações"
          key="actions"
          render={(_text, record: OrdersByDriver) => (
            <div>
              <Tooltip title="Detalhes do período" className="cursor-pointer">
                <DetailsIcon
                  onClick={() => {
                    setSelectedDriver(record.driver);
                    toggleDetails();
                  }}
                />
              </Tooltip>
            </div>
          )}
        />
      </Table>
    </>
  );
};

export default MetricsPage;
