import Order, { StoreOrderReq, UpdateOrderReq } from "interfaces/domain/order.interface";
import { useQueryClient, useMutation } from "react-query";
import DriverService from "services/driver.service";
import OrderService from "services/order.service";

export default function useOrderCreation(mode: string, onSuccessFn?: () => void) {
  const queryClient = useQueryClient();

  const createOrderMutation = useMutation(
    "createOrderMutation",
    (order: StoreOrderReq) => OrderService.createOrder(order),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<any>("activeOrders", (oldData: Order[]) => [...oldData, data]);
        onSuccessFn && onSuccessFn();
      },
    }
  );

  const editOrderMutation = useMutation(
    "editOrderMutation",
    (params: UpdateOrderReq) => OrderService.updateOrder(params),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<any>("activeOrders", (oldData: Order[]) => {
          return oldData.map((order: Order) => {
            if (order._id === data._id) return data;
            return order;
          });
        });
        if (data.driver) {
          const driverRef = DriverService.getDriverRef(data.store, data.driver._id);
          driverRef
            .get()
            .then((data) => data.val() && driverRef.update({ ordersAction: "REFETCH" }));
        }
        onSuccessFn && onSuccessFn();
      },
    }
  );

  const mutate = async (order: StoreOrderReq, orderId?: string) => {
    if (mode === "EDIT" && orderId) {
      editOrderMutation.mutateAsync({ orderId: orderId, order });
    } else {
      createOrderMutation.mutateAsync(order);
    }
  };

  let isLoading = createOrderMutation.isLoading || editOrderMutation.isLoading;
  let isSuccess = createOrderMutation.isSuccess || editOrderMutation.isSuccess;

  return {
    isSuccess,
    isLoading,
    mutate,
  };
}
