import { useContext, useState } from "react";
import { StoreContext } from "contexts/store.context";
import IntegrationCard from "./IntegrationCard";
import { ReactComponent as IFoodIcon } from "assets/icons/ifood-logo.svg";
import IFoodIntegrationModal from "./iFoodIntegrationModal";
import { ActivateIntegrationPayload } from "interfaces/domain/store.interface";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import StoreService from "services/store.service";

const IntegrationDataTab = () => {
  const { store, setStore } = useContext(StoreContext);

  const [visibleModal, setVisibleModal] = useState("");
  const toggleVisibleModal = (modalName?: string) => {
    setVisibleModal("");
    if (!visibleModal && modalName && modalName !== visibleModal) {
      setVisibleModal(modalName);
    }
  };

  const activateIntegratorMutation = useMutation(
    "activateIntegratorMutation",
    (payload: ActivateIntegrationPayload) => StoreService.activateIntegrator(payload),
    {
      onSuccess(data) {
        setStore(data);
      },
    }
  );

  const activateIntegrator = async (source: string, checked: boolean) => {
    toast.promise(
      activateIntegratorMutation.mutateAsync({
        source: source,
        isActive: checked,
      }),
      {
        loading: "Carregando...",
        success: `Status da integração com ${source} alterado com sucesso!`,
        error: "Erro ao alterar status da integração.",
      }
    );
  };

  return (
    <>
      <IFoodIntegrationModal
        merchantId={store?.integrators?.iFood?.iFoodMerchantId}
        isVisible={visibleModal === "iFoodIntegrationModal"}
        toggleModal={() => toggleVisibleModal("iFoodIntegrationModal")}
        setStore={setStore}
      />
      <div className="mt-6 flex gap-6">
        <IntegrationCard
          source="iFood"
          icon={<IFoodIcon />}
          description="Receba seus pedidos iFood na plataforma. É necessário aprovação da integração no portal do parceiro."
          isConfigured={store?.integrators?.iFood?.iFoodMerchantId ? true : false}
          isActive={store?.integrators?.iFood?.isActive}
          onSwitch={activateIntegrator}
          onConfigure={() => toggleVisibleModal("iFoodIntegrationModal")}
        />
      </div>
    </>
  );
};

export default IntegrationDataTab;
