import React from "react";
import {
  getOrderStatusBorderColor,
  getOrderStatusColor,
  getOrderTranslatedStatus,
} from "utils/order.utils";
import { OrderStatusName } from "interfaces/domain/order.interface";
import styled from "styled-components";

interface OrderStatusTagProps {
  status: OrderStatusName;
  bg?: boolean;
}

const StyledTag = styled.span<OrderStatusTagProps>`
  color: ${(props) => getOrderStatusColor(props.status)};
  ${(props) => (props.bg ? `background-color: ${getOrderStatusBorderColor(props.status)};` : null)}
  ${(props) => (props.bg ? `padding: 0.5rem 1rem;` : null)}
  font-weight: 500;
  border-radius: 0.875rem;
  font-size: 0.8125rem;
`;

const OrderStatusTag: React.FC<OrderStatusTagProps> = ({ status, bg }) => {
  return (
    <StyledTag status={status} bg={bg}>
      {getOrderTranslatedStatus(status)}
    </StyledTag>
  );
};

export default OrderStatusTag;
