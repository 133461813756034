import React from "react";
import { Button, Switch, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Title from "antd/lib/typography/Title";

interface DataCardProps {
  title: string;
  description?: React.ReactNode;
  enableable?: boolean;
  isEnabled?: boolean;
  toggleEnabled?: (state: boolean) => void;
  editable?: boolean;
  toggleEditing?: () => void;
}

const StyledDataCard = styled.div`
  padding: 2.5rem;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(108, 108, 108, 0.25);
  border: 1.5px solid #f5f5f5;
  border-radius: 10px;
  position: relative;
  z-index: 10;
`;

const DataCard: React.FC<DataCardProps> = (props) => {
  const {
    children,
    title,
    description,
    enableable,
    isEnabled,
    toggleEnabled,
    editable,
    toggleEditing,
  } = props;

  return (
    <StyledDataCard>
      <div className="flex justify-between mb-8">
        <div>
          <Title level={5}>{title}</Title>
          {description}
        </div>
        <div className="flex gap-2">
          {editable && (
            <Tooltip title="Editar">
              <Button
                className="bordered-icon"
                icon={<EditOutlined />}
                onClick={() => {
                  if (toggleEditing) toggleEditing();
                }}
              />
            </Tooltip>
          )}
          {enableable && (
            <Tooltip title="Habilitar">
              <Switch checked={isEnabled} onChange={(e) => toggleEnabled && toggleEnabled(e)} />
            </Tooltip>
          )}
        </div>
      </div>
      {children}
    </StyledDataCard>
  );
};

export default DataCard;
