import { useContext, useEffect, useState } from "react";
import PageTitle from "components/layout/PageTitle";
import { Form, Input, Divider, Alert, Tag } from "antd";
import Button from "components/layout/Button";
import { FleetColors } from "components/layout/layout.styles";
import { LoginData } from "interfaces/authService.interface";
import firebase from "credentials/firebase.credentials";
import { useMutation } from "react-query";
import AuthService from "services/auth.service";
import { StoreContext } from "contexts/store.context";
import { useNavigate } from "react-router-dom";
import ResetPasswordModal from "./ResetPasswordModal";
import "./Login.scss";

const LoginPage = () => {
  const { user, setUser, setStore } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);
  const toggleResetPassword = () => setIsResetPasswordVisible(!isResetPasswordVisible);

  const navigate = useNavigate();

  useEffect(() => {
    if (user._id) {
      navigate("/");
    }
  }, [navigate, user]);

  const loginMutation = useMutation(
    "loginMutation",
    ({ email, password, token }: LoginData) => AuthService.login({ email, password, token }),
    {
      onSuccess(user) {
        if (!user.stores) return;
        setUser(user);
        setStore(user.stores[0]);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("store", JSON.stringify(user.stores[0]));
        navigate("/");
      },
      onError() {
        setIsLoading(false);
      },
    }
  );

  const onFinish = async (data: LoginData) => {
    setIsLoading(true);
    const firebaseAuth = firebase.auth();
    try {
      const credentials = await firebaseAuth.signInWithEmailAndPassword(data.email, data.password);
      if (credentials && credentials.user) {
        localStorage.setItem("cred", JSON.stringify(credentials));
        loginMutation.mutate({
          email: data.email,
          password: data.password,
          token: await credentials.user.getIdToken(true),
        });
      }
    } catch (error) {
      await firebaseAuth.signOut();
      setIsLoading(false);
    }
  };

  return (
    <>
      <ResetPasswordModal isVisible={isResetPasswordVisible} toggleModal={toggleResetPassword} />
      <div className="flex flex-col items-center">
        <div className="content">
          <div className="formContainer">
            <PageTitle className="text-center" title="Login Fleetmap" />
            {loginMutation.isError && (
              <div className="mb-6">
                <Alert message="Email ou senha inválidos" type="warning" showIcon closable />
              </div>
            )}
            <Form
              name="LoginForm"
              className="login-form mb-10"
              layout="vertical"
              initialValues={{
                email: "",
                password: "",
              }}
              onFinish={onFinish}
            >
              <Form.Item name="email" label="Email">
                <Input className="input" disabled={isLoading} />
              </Form.Item>
              <Form.Item name="password" label="Senha">
                <Input.Password className="input" disabled={isLoading} />
              </Form.Item>
              <div className="flex justify-end">
                <p className="text-right mb-6">Esqueceu a senha?&nbsp;</p>
                <span
                  className="cursor-pointer"
                  style={{ color: FleetColors.fleetRed, fontWeight: "bold" }}
                  onClick={toggleResetPassword}
                >
                  clique aqui!
                </span>
              </div>
              <Button
                loading={isLoading}
                className="w-full d-block"
                text="Fazer login"
                bgcolor="red"
                textcolor="white"
                htmlType="submit"
              />
            </Form>
          </div>
          <Divider style={{ backgroundColor: "#C8C8C8", marginTop: "60px" }} />
          <div className="flex justify-center mt-12">
            <Tag>v1.3.0</Tag>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
