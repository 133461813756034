import { StoreContext } from "contexts/store.context";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import appEnv from "utils/appEnv";
import PartnerMarker from "assets/icons/orders/partner-marker.svg";
import OrderMarkerIcon from "./OrderMarkerIcon";
import DriverService from "services/driver.service";
import { FirebaseDriverData, FirebaseDriverSnapshot } from "../OrdersMap/OrdersMap.interface";
import DriverMarkerIcon from "./DriverMarkerIcon";
import styled from "styled-components";
import { Address } from "interfaces/domain/global.interface";
import Order from "interfaces/domain/order.interface";
import { SelectOrdersMetadata } from "interfaces/ordersPage.interface";
import { FaMapMarker } from "react-icons/fa";
import { MdCenterFocusStrong } from "react-icons/md";
import MapActionContainer from "../OrdersMap/MapActionContainer";
import MapAction from "../OrdersMap/MapAction";
import notification from "antd/lib/notification";
import OrderInfoPanel from "../OrdersMap/OrderInfoPanel";
import useMapFunctions from "./useMapFunctions";
import Map, {
  FullscreenControl,
  Layer,
  LayerProps,
  Marker,
  NavigationControl,
  Source,
  SourceProps,
  ViewStateChangeEvent,
  useMap,
  Popup,
} from "react-map-gl";
import DriverMarkerPopup from "../DriverMarkerPopup";
import Tooltip from "antd/lib/tooltip";
import { ReactComponent as DriversInfoIcon } from "assets/icons/orders/driversinfo-marker.svg";
import DriverInfoModal from "../OrdersMap/DriverInfoModal";
import { isDriverOnDuty } from "utils/driver.utils";

const markerStyle: React.CSSProperties = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  width: "36px",
  height: "36px",
  borderRadius: "50%",
  cursor: "pointer",
};

const driverMarkerStyle: React.CSSProperties = {
  width: "30px",
  height: "30px",
};

const MapWrapper = styled.div`
  height: 100%;
  position: relative;
`;

interface MapPageProps {
  orders: Order[] | undefined;
  selectedOrders: SelectOrdersMetadata[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<SelectOrdersMetadata[]>>;
  toggleOrderDetails: (order: Order) => void;
  openAssignModalAndSetOrder: (orderId: string, driverId?: string) => void;
}

const MapPage: React.FC<MapPageProps> = (props) => {
  const {
    orders,
    selectedOrders,
    setSelectedOrders,
    toggleOrderDetails,
    openAssignModalAndSetOrder,
  } = props;
  const { store } = useContext(StoreContext);
  const { orderMap } = useMap();
  const [selectedOrdersFeatures, setSelectedOrdersFeatures] = useState<any>([]);
  const [onlineDrivers, setOnlineDrivers] = useState<FirebaseDriverData[]>([]);
  const [driverPopup, setDriverPopup] = useState<FirebaseDriverData>();
  const [lastHoverOrder, setLastHoverOrder] = useState<Order>();
  const [viewport, setViewPort] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 15,
    transitionDuration: 100,
  });
  const [driverModalIsVisible, setDriverModalIsVisible] = useState(false);
  const { centerOnPartner, mapFitOrders } = useMapFunctions();
  const toggleDriverModal = () => setDriverModalIsVisible(!driverModalIsVisible);

  // const lineStringSource: SourceProps = {
  //   id: "lineStringSource",
  //   type: "geojson",
  //   data: {
  //     type: "FeatureCollection",
  //     features: orderFeatures,
  //   },
  // };

  // const lineStringLayer: LayerProps = {
  //   id: "lineStringLayer",
  //   source: "lineStringSource",
  //   type: "line",
  //   layout: {
  //     "line-join": "round",
  //     "line-cap": "round",
  //   },
  //   paint: {
  //     "line-color": "#58CCA1",
  //     "line-width": 3,
  //   },
  // };

  const selectedOrdersSource: SourceProps = {
    id: "selectedOrdersSource",
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: selectedOrdersFeatures,
    },
  };

  const selectedOrdersLayer: LayerProps = {
    id: "selectedOrdersLayer",
    source: "selectedOrdersSource",
    type: "circle",
    paint: {
      "circle-radius": 16,
      "circle-stroke-color": "#ef233c",
      "circle-stroke-width": 3,
      "circle-opacity": 0,
    },
  };

  useLayoutEffect(() => {
    if (!store) return;
    setViewPort({
      longitude: store.lng,
      latitude: store.lat,
      zoom: 15,
      transitionDuration: 100,
    });
  }, [store]);

  function _onViewportChange(viewport: ViewStateChangeEvent) {
    return setViewPort({ ...viewport.viewState, transitionDuration: 3000 });
  }

  useEffect(() => {
    if (!store) return;
    const driversRef = DriverService.getDriversRef(store._id);
    const driversValueListener = driversRef.on("value", (snapshot) => {
      const driversSnapshot = snapshot.val() as FirebaseDriverSnapshot;
      if (!driversSnapshot) return;
      const onlineDrivers = Object.values(driversSnapshot) as FirebaseDriverData[];
      setOnlineDrivers(onlineDrivers.filter((driver) => isDriverOnDuty(driver.status)));
    });
    const driversRemoveListener = driversRef.on("child_removed", (snapshot) => {
      const driverSnapshot = snapshot.val() as FirebaseDriverData;
      setOnlineDrivers((oldDrivers) =>
        oldDrivers.filter((driver) => driver.id !== driverSnapshot.id)
      );
    });
    return () => {
      driversRef.off("value", driversValueListener);
      driversRef.off("child_removed", driversRemoveListener);
    };
  }, [store]);

  useEffect(() => {
    setSelectedOrdersFeatures(
      selectedOrders.map((order) => {
        return {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [
              order.deliveryAddress.coordinates.lng,
              order.deliveryAddress.coordinates.lat,
            ],
          },
        };
      })
    );
  }, [selectedOrders]);

  const addMarkerToSelected = (orderId: string, displayId: string, deliveryAddress: Address) => {
    setSelectedOrders((existingOrders) => {
      const markerFound = existingOrders.find((existingOrder) => existingOrder.orderId === orderId);

      if (markerFound) {
        return existingOrders.filter((existingOrder) => existingOrder.orderId !== orderId);
      }

      if (selectedOrders.length === 5) {
        notification["error"]({
          message: "Limite de seleção de pedidos",
          description: "Só é possivel selecionar até 5 pedidos ao mesmo tempo.",
        });
        return existingOrders;
      }

      return [...existingOrders, { orderId, displayId, deliveryAddress }];
    });
  };

  const onOrderMarkerClick = (order: Order) => {
    if (!orderMap) return;

    addMarkerToSelected(order._id, order.displayId, order.deliveryAddress);

    orderMap.flyTo({
      center: [order.deliveryAddress.coordinates.lng, order.deliveryAddress.coordinates.lat],
    });
  };

  const openDriverPopup = (driver: FirebaseDriverData) => {
    if (driverPopup) return setDriverPopup(undefined);
    return setDriverPopup(driver);
  };

  return (
    <MapWrapper className="map">
      <DriverInfoModal
        isVisible={driverModalIsVisible}
        toggleModal={toggleDriverModal}
        storeId={store?._id}
      />
      <Map
        id="orderMap"
        {...viewport}
        onMove={_onViewportChange}
        mapStyle="mapbox://styles/fleetmap/cks5cqgg0cadh18rz01vdgiwv"
        mapboxAccessToken={appEnv.MAPBOX_ACCESS_KEY}
        reuseMaps
      >
        <NavigationControl />
        <FullscreenControl />
        <Source {...selectedOrdersSource}>
          <Layer {...selectedOrdersLayer} />
        </Source>
        <Marker
          latitude={store?.lat}
          longitude={store?.lng}
          style={{
            width: "26px",
            height: "26px",
          }}
        >
          <img src={PartnerMarker} alt="Marker da loja no mapa" />
        </Marker>
        {orders &&
          orders.map((order) => (
            <Marker
              key={order._id}
              latitude={order.deliveryAddress.coordinates.lat}
              longitude={order.deliveryAddress.coordinates.lng}
              style={markerStyle}
              onClick={() => onOrderMarkerClick(order)}
            >
              <div onMouseEnter={() => setLastHoverOrder(order)}>
                <OrderMarkerIcon orderStatus={order.lastOrderStatus} />
              </div>
            </Marker>
          ))}
        {driverPopup && (
          <Popup
            onClose={() => setDriverPopup(undefined)}
            closeOnMove={false}
            closeOnClick={false}
            longitude={driverPopup.coordinates.lng}
            latitude={driverPopup.coordinates.lat}
            anchor="bottom"
          >
            <DriverMarkerPopup onlineDriver={driverPopup} />
          </Popup>
        )}
        {onlineDrivers.map((driver) => (
          <Marker
            key={driver.id}
            latitude={driver.coordinates.lat}
            longitude={driver.coordinates.lng}
            style={{ ...markerStyle, ...driverMarkerStyle }}
            onClick={() => openDriverPopup(driver)}
          >
            <DriverMarkerIcon driverStatus={driver.status} />
          </Marker>
        ))}
      </Map>
      <OrderInfoPanel
        order={lastHoverOrder}
        setLastHoverOrder={setLastHoverOrder}
        toggleOrderDetails={toggleOrderDetails}
        openAssignModalAndSetOrder={openAssignModalAndSetOrder}
      />
      <MapActionContainer>
        <MapAction
          tooltip="Centralizar loja"
          icon={<MdCenterFocusStrong style={{ width: "1.8125rem", height: "1.8125rem" }} />}
          onClick={centerOnPartner}
        />
        {orders && (
          <MapAction
            tooltip="Centralizar pedidos"
            icon={<FaMapMarker style={{ width: "1rem", height: "1rem" }} />}
            onClick={() => mapFitOrders(orders)}
          />
        )}
      </MapActionContainer>
      <Tooltip title="Visualizar entregadores online" className="cursor-pointer">
        <div className="driversInfo-button" onClick={toggleDriverModal}>
          <DriversInfoIcon />
        </div>
      </Tooltip>
    </MapWrapper>
  );
};

export default MapPage;
