import { Popconfirm, Space, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import StoreService from "services/store.service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getMaskedCellphone } from "utils/mask.utils";
import Driver from "interfaces/domain/driver.interface";
import { ReactComponent as RemoveIcon } from "assets/icons/remove-icon.svg";
import { useState } from "react";
import DriverInvite from "interfaces/domain/driverInvite.interface";
import DriverService from "services/driver.service";
import OrderSearchInput from "pages/Orders/components/OrderSearchInput";

const InvitedDriversTable = () => {
  const [filtredData, setFiltredData] = useState<DriverInvite[] | undefined>();
  const [inviteToRevoke, setInviteToRevoke] = useState<string>();

  const { data, isLoading } = useQuery("getInvitedDrivers", StoreService.getInvitedDrivers, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const queryClient = useQueryClient();
  const revokeInviteMutation = useMutation(
    "revokeInviteMutation",
    (inviteId: string) => DriverService.revokeDriverInvite(inviteId),
    {
      onSuccess: () => {
        queryClient.setQueryData<any>("getInvitedDrivers", (oldData: any) => {
          return oldData.filter((invite: DriverInvite) => invite._id !== inviteToRevoke);
        });
      },
    }
  );

  const onSearch = (searchValue: string) => {
    if (!data) return;
    const filtred = data.filter((invite) => {
      const firstNameLowed = invite.driverTelephone;
      const searchValueLowed = searchValue.toLowerCase();
      return firstNameLowed.includes(searchValueLowed);
    });
    setFiltredData(filtred);
  };

  return (
    <>
      <div className="flex mb-6">
        <OrderSearchInput onSearch={onSearch} placeholder="Pesquisar pelo celular" size="large" />
      </div>
      <Table
        pagination={{
          pageSize: 25,
        }}
        dataSource={filtredData && filtredData.length > 0 ? filtredData : data}
        loading={isLoading}
      >
        <Column
          title="Telefone"
          dataIndex="driverTelephone"
          key="driverTelephone"
          render={(text) => <>{getMaskedCellphone(text.slice(2))}</>}
        />

        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={() => <Tag color="processing">CONVITE ENVIADO</Tag>}
        />
        <Column
          title="Ações"
          key="actions"
          render={(_text, record: Driver) => (
            <Space size="middle">
              <Popconfirm
                title="Deseja realmente cancelar este convite ?"
                okText="Sim"
                cancelText="Não"
                onConfirm={() => inviteToRevoke && revokeInviteMutation.mutateAsync(inviteToRevoke)}
                onVisibleChange={(visible) => visible && setInviteToRevoke(record._id)}
              >
                <p className="cursor-pointer m-0">
                  <span className="align-middle">
                    <RemoveIcon />
                  </span>
                </p>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default InvitedDriversTable;
