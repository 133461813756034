import { useQuery } from "react-query";
import HereService from "services/here.service";

export default function useHereGeocode(formattedAddress: string) {
  const { data, isIdle, isLoading, isSuccess, isError, refetch } = useQuery(
    ["getHereGeocodedAddress", formattedAddress],
    () => HereService.geocodeAddress(formattedAddress),
    {
      enabled: formattedAddress ? true : false,
    }
  );

  return { data, isIdle, isLoading, isSuccess, isError, refetch };
}
