import styled from "styled-components";
import { Button } from "antd";

export enum FleetColors {
  fleetWhite = "#FFFFFF",
  fleetRed = "#EF233C",
  fleetGray = "#EAEAEA",
  fleetDarkGray = "#717579",
  fleetBlack = "#2D3134",
  fleetGreen = "#74DE4F",
}

export type PossibleColors = "white" | "red" | "gray" | "darkGray" | "black" | "green";

export const pickRightColor = (bgColor: string) => {
  let rightColor;
  switch (bgColor) {
    case "white":
      rightColor = FleetColors.fleetWhite;
      break;
    case "red":
      rightColor = FleetColors.fleetRed;
      break;
    case "gray":
      rightColor = FleetColors.fleetGray;
      break;
    case "darkGray":
      rightColor = FleetColors.fleetDarkGray;
      break;
    case "black":
      rightColor = FleetColors.fleetBlack;
      break;
    case "green":
      rightColor = FleetColors.fleetGreen;
      break;
    default:
      rightColor = "pink";
      break;
  }
  return rightColor;
};

export const StyledTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: rgba(45, 49, 52, 1);
  margin-bottom: 1.25rem;
`;

interface StyledButtonProps {
  bgcolor: string;
  textcolor: string;
  bordercolor?: string;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  background: ${(props) => props.bgcolor};
  color: ${(props) => props.textcolor};
  border: 1px solid;
  border-radius: 10px;
  border-color: ${(props) => props.bordercolor};
  font-weight: 500;
  height: 3.375rem;
  padding: 0.9375rem 1.5rem;
  cursor: pointer;

  :hover,
  :focus,
  :active {
    background: ${(props) => props.bgcolor};
    color: ${(props) => props.textcolor};
  }
`;

export const StyledSearchInput = styled.input`
  background-color: "#F4F4F4";
  color: "#C1C1C1";
`;
