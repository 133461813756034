import React from "react";
import Modal from "components/layout/Modal";
import { Avatar, Descriptions } from "antd";
import Driver from "interfaces/domain/driver.interface";
import { getMaskedCellphone, getMaskedCPF } from "utils/mask.utils";
import { UserOutlined } from "@ant-design/icons";

interface DriverDetailsModalProps {
  driver: Driver | undefined;
  isVisible: boolean;
  toggleModal: () => void;
}

const DriverDetailsModal: React.FC<DriverDetailsModalProps> = (props) => {
  const { driver, isVisible, toggleModal } = props;
  return (
    <Modal width={1124} visible={isVisible} onCancel={toggleModal}>
      {driver && (
        <div className="flex justify-center items-center gap-6">
          <div className="mr-8">
            <Avatar
              src={driver.profileImage}
              icon={!driver.profileImage && <UserOutlined />}
              size={140}
            />
          </div>
          <div>
            <Descriptions layout="vertical" title="Detalhes do entregador">
              <Descriptions.Item
                label="Nome Completo"
                labelStyle={{ fontWeight: "bold" }}
              >{`${driver.firstName} ${driver.lastName}`}</Descriptions.Item>
              <Descriptions.Item label="Documento" labelStyle={{ fontWeight: "bold" }}>
                {typeof driver.document === "string" && getMaskedCPF(driver.document)}
              </Descriptions.Item>
              <Descriptions.Item label="Telefone" labelStyle={{ fontWeight: "bold" }}>
                {typeof driver.document === "string" && getMaskedCellphone(driver.telephone.slice(2))}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DriverDetailsModal;
