import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";

const StyledAvatarContainer = styled.div`
  border: 3px solid #ef233c;
  border-radius: 50px;
  vertical-align: middle;
`;

interface AvatarContainerProps {
  icon?: React.ReactNode;
}

const AvatarContainer: React.FC<AvatarContainerProps> = (props) => {
  const { icon } = props;
  return (
    <StyledAvatarContainer>
      <Avatar size={58} icon={icon ? icon : <UserOutlined />} />
    </StyledAvatarContainer>
  );
};

export default AvatarContainer;
