import React from "react";
import styled from "styled-components";

interface DriverStatusTagProps {
  status: string;
  bg?: boolean;
}

const getStatusColor = (status: string) => {
  let statusColor;
  if (status === "AVAILABLE") {
    statusColor = "#74DE4F";
  } else if (status === "IN_TRANSIT") {
    statusColor = "#45DCCA";
  } else {
    statusColor = "#898a8b";
  }
  return statusColor;
};

const getBgColor = (status: string) => {
  let statusColor;
  if (status === "AVAILABLE") {
    statusColor = "#F0FAEC";
  } else if (status === "IN_TRANSIT") {
    statusColor = "#EBFAF8";
  } else {
    statusColor = "#eeefef";
  }
  return statusColor;
};

const StyledTag = styled.span<DriverStatusTagProps>`
  color: ${(props) => getStatusColor(props.status)};
  ${(props) => (props.bg ? `background-color: ${getBgColor(props.status)};` : null)}
  ${(props) => (props.bg ? `padding: 0.5rem 1rem;` : null)}
  font-weight: 500;
  border-radius: 0.875rem;
  font-size: 0.8125rem;
`;

const DriverStatusTag: React.FC<DriverStatusTagProps> = ({ status, bg }) => {
  const getTranslatedStatus = (status: string) => {
    let statusName;
    if (status === "AVAILABLE") {
      statusName = "Disponível";
    } else if (status === "IN_TRANSIT") {
      statusName = "Saiu para entrega";
    } else {
      statusName = "Retornando";
    }
    return statusName;
  };

  return (
    <StyledTag status={status} bg={bg}>
      {getTranslatedStatus(status)}
    </StyledTag>
  );
};

export default DriverStatusTag;
