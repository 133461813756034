import React from "react";

interface RealCurrencyProps {
  valueToConvert?: number;
  sign?: boolean;
}

const RealCurrency = ({ valueToConvert, sign }: RealCurrencyProps) => {
  const renderConvertedNumber = () => {
    if (sign && valueToConvert) {
      return valueToConvert.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    } else if (!sign && valueToConvert) {
      return valueToConvert.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    } else {
      return "--";
    }
  };

  return <>{renderConvertedNumber()}</>;
};

export default RealCurrency;
