import { StoreContext } from "contexts/store.context";
import Order from "interfaces/domain/order.interface";
import mapboxgl from "mapbox-gl";
import { useContext } from "react";
import { useMap } from "react-map-gl";

export default function useMapFunctions() {
  const { store } = useContext(StoreContext);
  const { orderMap } = useMap();

  const centerOnPartner = () => {
    if (!orderMap || !store) return;
    orderMap.flyTo({ center: [store?.lng, store?.lat], zoom: 15 });
  };

  const mapFitOrders = (orders: Order[]) => {
    if (!orderMap || !store) return;
    const bounds = new mapboxgl.LngLatBounds([
      [store.lng, store.lat],
      [store.lng, store.lat],
    ]);
    orders?.forEach((order) => {
      bounds.extend([order.deliveryAddress.coordinates.lng, order.deliveryAddress.coordinates.lat]);
    });
    orderMap.fitBounds(bounds, {
      padding: 80,
    });
  };

  return {
    centerOnPartner,
    mapFitOrders,
  };
}
