import React, { useState } from "react";
import Modal from "components/layout/Modal";
import ModalTitle from "components/layout/ModalTitle";
import Button from "components/layout/Button";
import { useMutation, useQueryClient } from "react-query";
import OrderService from "services/order.service";
import CancelOrder from "assets/images/cancel-order.png";
import Order from "interfaces/domain/order.interface";
import DriverService from "services/driver.service";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

interface CancelOrderModalProps {
  orderId: string;
  isVisible: boolean;
  toggleModal: () => void;
  toggleOrderDetails: () => void;
}

const CancelOrderModal: React.FC<CancelOrderModalProps> = (props) => {
  const { orderId, isVisible, toggleModal, toggleOrderDetails } = props;
  const [isChecked, setChecked] = useState(false);

  const queryClient = useQueryClient();
  const cancelOrderMutation = useMutation(
    "cancelOrderMutation",
    () => OrderService.updateOrderStatus({ orderId, orderStatus: "CANCELLED" }),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData<any>("activeOrders", (oldData: any) => {
          return oldData.filter((order: Order) => order._id !== data._id);
        });
        if (data.driver) {
          const driverRef = DriverService.getDriverRef(data.store, data.driver._id);
          await driverRef.update({ ordersAction: "REFETCH" });
        }
        toggleOrderDetails();
      },
    }
  );

  const deleteOrderMutation = useMutation((orderId: string) => OrderService.deleteOrder(orderId), {
    onMutate() {
      queryClient.setQueryData<Order[]>("activeOrders", (data) => {
        if (!data) return [];
        return data.filter((order) => order._id !== orderId);
      });
      toggleOrderDetails();
    },
  });

  const handleCancelOrder = () => {
    if (isChecked) {
      deleteOrderMutation.mutate(orderId);
    } else {
      cancelOrderMutation.mutate();
    }
  };

  const resetMutationAfterClose = () => {
    setChecked(false);
    cancelOrderMutation.reset();
    deleteOrderMutation.reset();
  };

  const handleCheckbox = (event: CheckboxChangeEvent) => setChecked(event.target.checked);

  const mutationsIsIdle = deleteOrderMutation.isLoading && cancelOrderMutation.isLoading;
  const mutationsIsLoading = deleteOrderMutation.isIdle && cancelOrderMutation.isIdle;

  return (
    <Modal
      title={ModalTitle({ text: "Cancelar pedido" })}
      width={630}
      visible={isVisible}
      onCancel={toggleModal}
      afterClose={resetMutationAfterClose}
    >
      <div className="flex flex-col items-center gap-4">
        {(mutationsIsIdle || mutationsIsLoading) && (
          <>
            <figure>
              <img src={CancelOrder} alt="Cancelar pedido" />
            </figure>
            <p>
              Você tem certeza que deseja <strong>cancelar</strong> este pedido?
            </p>
            <Checkbox checked={isChecked} onChange={handleCheckbox}>
              Excluir o pedido permanentemente
            </Checkbox>
            <Button
              loading={cancelOrderMutation.isLoading}
              text="Cancelar pedido"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={handleCancelOrder}
            />
          </>
        )}
        {(deleteOrderMutation.isSuccess || cancelOrderMutation.isSuccess) && (
          <>
            <p className="w-80 mb-10">
              O pedido foi <strong>cancelado</strong> com sucesso!
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
        {(deleteOrderMutation.isError || cancelOrderMutation.isError) && (
          <>
            <p className="w-80 mb-10">
              <span className="text-red">
                <strong>Erro ao cancelar pedido.</strong>
              </span>
              <br />
              Tente novamente.
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default CancelOrderModal;
