import { LoginData } from "interfaces/authService.interface";
import User from "interfaces/domain/user.interface";
import { ApiClient } from "utils/apiClient";

const AuthService = {
  async login({ email, password, token }: LoginData) {
    const { data } = await ApiClient.post<User>("/auth/user/login", {
      email,
      password,
      token,
    });
    return data;
  },

  async logout() {},
};

export default AuthService;
