import { useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import BatchAssignDriverModal from "./components/BatchAssignDriverModal";
import { SelectOrdersMetadata } from "interfaces/ordersPage.interface";
import AssignDriverModal from "./components/AssignDriverModal";
import OrderDetails from "./components/OrderDetails";
import { StoreContext } from "contexts/store.context";
import Order from "interfaces/domain/order.interface";
import PageTitle from "components/layout/PageTitle";
import CreateOrder from "./components/CreateOrder";
import OrderService from "services/order.service";
import OrderHistoryTab from "./OrderHistoryTab";
import OrderListTab from "./OrderListTab";
import OrderMapTab from "./OrderMapTab";
import { useQuery } from "react-query";
import "./Orders.scss";
import "./MapStyle.scss";

const OrdersPage = () => {
  const { setMapInstance, store, orders, setOrders } = useContext(StoreContext);

  const [selectedOrders, setSelectedOrders] = useState<SelectOrdersMetadata[]>([]);
  const [isDetailsVisible, setDetailsVisible] = useState(false);

  const [createOrderVisible, setCreateOrderVisible] = useState(false);
  const [orderToEdit, setOrderToEdit] = useState<Order>();
  const toggleCreateOrder = (order?: Order) => {
    if (order && order._id) setOrderToEdit(order);
    setCreateOrderVisible(!createOrderVisible);
  };

  const [visibleModal, setVisibleModal] = useState("");
  const toggleVisibleModal = (modalName?: string) => {
    setVisibleModal("");
    if (!visibleModal && modalName) {
      setVisibleModal(modalName);
    }
  };

  const [orderToDetail, setOrderToDetail] = useState<Order>();
  const toggleDetails = () => setDetailsVisible(!isDetailsVisible);

  const [singleSelectedOrder, setSingleSelectedOrder] = useState("");
  const [preSelectedDriver, setPreSelectedDriver] = useState("");
  const [batchSelectedOrders, setBatchSelectedOrders] = useState<SelectOrdersMetadata[]>([]);

  const openAssignModalAndSetOrder = (orderId: string, driverId?: string) => {
    setSingleSelectedOrder(orderId);
    if (driverId) setPreSelectedDriver(driverId);
    toggleVisibleModal("assignDriverModal");
  };

  const openBatchAssignModal = (orders: SelectOrdersMetadata[]) => {
    setBatchSelectedOrders(orders);
    toggleVisibleModal("batchAssignDriverModal");
  };

  const toggleOrderDetails = (order: Order) => {
    setOrderToDetail(order);
    toggleDetails();
  };

  const { isLoading: isLoadingOrders, isError: isErrorOrders } = useQuery(
    "activeOrders",
    OrderService.getActiveOrders,
    {
      refetchInterval: process.env.NODE_ENV === "production" ? 15000 : false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      retry: false,
      onSuccess: (data) => {
        setOrders(data);
      },
    }
  );

  useQuery(
    "activeiFoodOrders",
    () => OrderService.getActiveiFoodOrders(store?.integrators.iFood?.iFoodMerchantId || ""),
    {
      enabled:
        !!store?.integrators?.iFood?.isActive &&
        store?.integrators.iFood?.iFoodMerchantId !== undefined,
      refetchInterval: process.env.NODE_ENV === "production" ? 30000 : false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      retry: false,
      onSuccess: (data) => {
        setOrders((oldOrders) => [...oldOrders, ...data]);
      },
    }
  );

  const handleBatchOrderAssign = () => {
    openBatchAssignModal(selectedOrders);
  };

  const OrderMapTabProps = {
    store,
    orders,
    selectedOrders,
    isErrorOrders,
    isLoadingOrders,
    setMapInstance,
    handleBatchOrderAssign,
    toggleCreateOrder,
    setSelectedOrders,
    toggleOrderDetails,
    openAssignModalAndSetOrder,
  };

  const OrderListTabProps = {
    orders,
    openAssignModalAndSetOrder,
    toggleOrderDetails,
    toggleCreateOrder,
  };

  return (
    <>
      <AssignDriverModal
        storeId={store?._id || ""}
        isVisible={visibleModal === "assignDriverModal"}
        toggleModal={() => toggleVisibleModal("assignDriverModal")}
        singleSelectedOrder={singleSelectedOrder}
        preSelectedDriver={preSelectedDriver}
        orderToDetail={orderToDetail}
        setOrderToDetail={setOrderToDetail}
        setPreSelectedDriver={setPreSelectedDriver}
      />
      <BatchAssignDriverModal
        storeId={store?._id || ""}
        isVisible={visibleModal === "batchAssignDriverModal"}
        toggleModal={() => toggleVisibleModal("batchAssignDriverModal")}
        setSelectedOrders={setSelectedOrders}
        batchSelectedOrders={batchSelectedOrders}
        setBatchSelectedOrders={setBatchSelectedOrders}
      />
      <CreateOrder
        order={orderToEdit}
        isVisible={createOrderVisible}
        toggleVisibility={toggleCreateOrder}
        setOrderToEdit={setOrderToEdit}
      />
      <OrderDetails
        isVisible={isDetailsVisible}
        toggleDetails={toggleDetails}
        order={orderToDetail}
        openAssignModalAndSetOrder={openAssignModalAndSetOrder}
      />
      <PageTitle title="Pedidos" />
      <Routes>
        <Route path="/" element={<OrderMapTab {...OrderMapTabProps} />} />
        <Route path="/orders/list" element={<OrderListTab {...OrderListTabProps} />} />
        <Route
          path="orders/historic"
          element={<OrderHistoryTab toggleOrderDetails={toggleOrderDetails} />}
        />
      </Routes>
    </>
  );
};

export default OrdersPage;
