import { useQuery } from "react-query";
import CEPService from "services/cep.service";

export default function useGetAddressFromCep(cep: string, enabled?: boolean) {
  const { data, isIdle, isLoading, isSuccess, isError, refetch } = useQuery(
    ["getAddressFromCep", cep],
    () => CEPService.getStreetFromCEP(cep),
    {
      enabled: cep ? true : false,
    }
  );

  return {
    data,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
}
