import "mapbox-gl/dist/mapbox-gl.css";
import "antd/dist/antd.min.css";
import "./AntdRewrites.css";
import "./App.css";

import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import StoreContextProvider from "contexts/store.context";
import { QueryClient, QueryClientConfig, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Toaster } from "react-hot-toast";
import { MapProvider } from "react-map-gl";
import FleetmapRouter from "router";

const QueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false, refetchIntervalInBackground: true },
    mutations: { retry: false },
  },
};

const App = () => {
  const queryClient = new QueryClient(QueryConfig);
  return (
    <div className="App">
      <Toaster />
      <ConfigProvider locale={ptBR}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <StoreContextProvider>
            <MapProvider>
              <FleetmapRouter />
            </MapProvider>
          </StoreContextProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </div>
  );
};

export default App;
