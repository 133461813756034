import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/search_icon.svg";
import { IoMdClose } from "react-icons/io";

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
  border-radius: 0.875rem;
  padding: 0.3rem 1rem;
`;

const StyledInput = styled.input`
  min-width: 150px;
  background-color: #f4f4f4;
  padding: 0;
  border: none;
  outline: none;
`;

interface OrderSearchInputProps {
  onSearch: (displayId: string) => void;
  placeholder: string;
  size?: "small" | "medium" | "large";
}

const OrderSearchInput: React.FC<OrderSearchInputProps & React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const [inputValue, setInputValue] = useState("");
  const { onSearch, placeholder, size } = props;

  const resolveInputSize = (size: string | undefined) => {
    if (size === "medium") {
      return "17.5rem";
    } else if (size === "large") {
      return "20rem";
    } else {
      return "14.1rem";
    }
  };

  return (
    <InputContainer className={props.className} style={{ width: resolveInputSize(size) }}>
      <StyledInput
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
          if (event.target.value === "") onSearch("");
        }}
        placeholder={placeholder}
        onKeyPress={(event) => event.key === "Enter" && onSearch(inputValue)}
      />
      <div className="flex gap-2">
        <div style={{ width: "0.875rem" }}>
          {inputValue && (
            <button
              onClick={() => {
                onSearch("");
                setInputValue("");
              }}
            >
              <IoMdClose />
            </button>
          )}
        </div>
        <button onClick={() => onSearch(inputValue)}>
          <SearchIcon />
        </button>
      </div>
    </InputContainer>
  );
};

export default OrderSearchInput;
