import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import "./InnerNavLink.style.css";

interface InnerNavLinkProps {
  to: string;
  text: string;
}

const StyledNavLink = styled(NavLink)`
  padding: 0.9375rem;
  font-size: 14px;
  border-radius: 14px;
  margin-right: 1.5rem;

  &.active {
    background-color: #eaeaea;
  }
`;

const StyledSpan = styled.span`
  color: #2d3134;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
`;

const InnerNavLink: React.FC<InnerNavLinkProps> = (props) => {
  const { to, text } = props;
  return (
    <StyledNavLink to={to} end>
      <StyledSpan>{text}</StyledSpan>
    </StyledNavLink>
  );
};

export default InnerNavLink;
