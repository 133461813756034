import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import DriverPaymentTab from "pages/Settings/DriverPaymentTab";
import IntegrationDataTab from "pages/Settings/IntegrationDataTab";
import RegistredDataTab from "pages/Settings/RegistredDataTab";

import AuthLayout from "layouts/authLayout";
import LoginPage from "pages/Login";
import RegisterPage from "pages/Register";

import TopbarLayout from "layouts/topbarLayout";
import OrdersPage from "pages/Orders";
import DriversPage from "pages/Drivers";
import MetricsPage from "pages/Metrics";
import AssociatedDriversTable from "pages/Drivers/AssociatedDriversTable";
import InvitedDriversTable from "pages/Drivers/InvitedDriversTable";
import SettingsPage from "pages/Settings";

const FleetmapRouter = () => {
  return (
    <HashRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/auth/login" element={<LoginPage />} />
          <Route path="/auth/register" element={<RegisterPage />} />
        </Route>
        <Route element={<TopbarLayout />}>
          <Route path="/*" element={<OrdersPage />} />
          <Route element={<DriversPage />}>
            <Route path="/drivers" element={<AssociatedDriversTable />} />
            <Route path="/drivers/invited" element={<InvitedDriversTable />} />
          </Route>
          <Route path="/metrics" element={<MetricsPage />} />
          <Route path="/settings" element={<SettingsPage />}>
            <Route index element={<RegistredDataTab />} />
            <Route path="integrations" element={<IntegrationDataTab />} />
            <Route path="driverpayment" element={<DriverPaymentTab />} />
          </Route>
          <Route path="*" element={() => <Navigate to="/" />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default FleetmapRouter;
