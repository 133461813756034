import React from "react";
import Modal from "components/layout/Modal";
import ModalTitle from "components/layout/ModalTitle";
import Button from "components/layout/Button";
import { useMutation, useQueryClient } from "react-query";
import OrderService from "services/order.service";
import { UpdateOrderStatusReq } from "interfaces/domain/order.interface";
import DriverService from "services/driver.service";

interface ConcludeOrderModalProps {
  orderId: string;
  isVisible: boolean;
  toggleModal: () => void;
  toggleOrderDetails: () => void;
}

const ConcludeOrderModal: React.FC<ConcludeOrderModalProps> = (props) => {
  const { orderId, isVisible, toggleModal, toggleOrderDetails } = props;

  const queryClient = useQueryClient();
  const concludeOrderMutation = useMutation(
    "updateOrderStatus",
    (params: UpdateOrderStatusReq) => OrderService.updateOrderStatus(params),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(["activeOrders"]);
        if (data.driver) {
          const driverRef = DriverService.getDriverRef(data.store, data.driver._id);
          await driverRef.update({ ordersAction: "REFETCH" });
        }
        toggleOrderDetails();
      },
    }
  );

  const handleConcludeOrder = () => {
    concludeOrderMutation.mutate({ orderId, orderStatus: "CONCLUDED" });
  };

  const resetMutationAfterClose = () => {
    concludeOrderMutation.reset();
  };

  return (
    <Modal
      title={ModalTitle({ text: "Concluir pedido" })}
      width={630}
      visible={isVisible}
      onCancel={toggleModal}
      afterClose={resetMutationAfterClose}
    >
      <div className="flex flex-col gap-6">
        {concludeOrderMutation.isIdle && (
          <>
            <p>
              Você tem certeza que deseja <strong>concluir</strong> este pedido?
            </p>
            <Button
              loading={concludeOrderMutation.isLoading}
              text="Concluir pedido"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={handleConcludeOrder}
            />
          </>
        )}
        {concludeOrderMutation.isSuccess && (
          <>
            <p>
              O pedido foi <strong>concluído</strong> com sucesso!
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
        {concludeOrderMutation.isError && (
          <>
            <p>
              <span className="text-red">
                <strong>Erro ao concluir pedido.</strong>
              </span>
              <br />
              Tente novamente.
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConcludeOrderModal;
