import { CEPData } from "pages/Orders/components/CreateOrder/CreateOrder.interface";
import { ViaCEPApiClient } from "utils/apiClient";
import appEnv from "utils/appEnv";

const CEPService = {
  async getStreetFromCEP(cep: string): Promise<CEPData | undefined> {
    const { data } = await ViaCEPApiClient.get(`${appEnv.CEP_API}/${cep}/json`);
    return data;
  },
};

export default CEPService;
