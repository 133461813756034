import React, { useState } from "react";
import { Avatar, Dropdown, Space, Table, Tooltip, Button as AntdButton, Menu } from "antd";
import Column from "antd/lib/table/Column";
import Order, { OrderStatusName } from "interfaces/domain/order.interface";
import { unixToViewDateTime } from "utils/date.utils";
import IntegrationSourceLogo from "components/IntegrationSourceLogo";
import Driver from "interfaces/domain/driver.interface";
import { ClearOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import OrderStatusTag from "components/OrderStatusTag";
import { ReactComponent as DetailsIcon } from "assets/icons/details-icon.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import Button from "components/layout/Button";
import InnerNavLink from "components/InnerNavLink";
import OrderMarker from "./components/OrderIcons";
import WhatsAppButton from "components/WhatsAppButton";
import OrderSearchInput from "./components/OrderSearchInput";
import { handleDriverBigName } from "utils/order.utils";

interface OrderListTabProps {
  orders: Order[];
  openAssignModalAndSetOrder: (orderId: string, driverId?: string | undefined) => void;
  toggleOrderDetails: (order: Order) => void;
  toggleCreateOrder: (order?: Order) => void;
}

const OrderListTab: React.FC<OrderListTabProps> = (props) => {
  const { orders, openAssignModalAndSetOrder, toggleOrderDetails, toggleCreateOrder } = props;

  const onSearch = (searchValue: string) => {
    if (!orders) return;
    const filtred = orders.filter((order) => {
      return order.displayId.includes(searchValue);
    });
    setFiltredOrders(filtred);
  };

  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [filtredOrders, setFiltredOrders] = useState<Order[]>();
  const clearOrderFilters = () => {
    setFiltredOrders([]);
    setIsFiltering(false);
  };
  const filterOrderByStatus = (status: OrderStatusName) => {
    if (!orders) return;
    clearOrderFilters();
    setIsFiltering(true);
    setFiltredOrders(orders.filter((order) => order.lastOrderStatus === status));
  };

  const StatusSortMenu = () => (
    <Menu selectable={false}>
      <Menu.Item
        key="0"
        icon={<OrderMarker orderStatus="UNASSIGNED" />}
        onClick={() => filterOrderByStatus("UNASSIGNED")}
      >
        <span>Não atribuido</span>
      </Menu.Item>
      <Menu.Item
        key="1"
        icon={<OrderMarker orderStatus="ASSIGNED" />}
        onClick={() => filterOrderByStatus("ASSIGNED")}
      >
        Atribuído
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<OrderMarker orderStatus="IN_TRANSIT" />}
        onClick={() => filterOrderByStatus("IN_TRANSIT")}
      >
        Em transito
      </Menu.Item>
    </Menu>
  );

  const getTableData = () => {
    if (filtredOrders && filtredOrders.length) {
      return filtredOrders;
    } else if (!filtredOrders?.length && isFiltering) {
      return [];
    } else {
      return orders;
    }
  };

  return (
    <>
      <div className="flex items-center mb-6">
        <InnerNavLink to="/" text="Mapa" />
        <InnerNavLink to={`/orders/list`} text="Lista" />
        <InnerNavLink to={`/orders/historic`} text="Histórico" />
      </div>
      <div className="flex justify-between mb-6">
        <div className="flex justify-between items-center">
          <div className="mr-6">
            <Dropdown className="mr-2" overlay={StatusSortMenu} trigger={["click"]}>
              <Tooltip title="Filtrar pedidos pelo status" className="cursor-pointer">
                <AntdButton className="filter-button">
                  Status <DownOutlined />
                </AntdButton>
              </Tooltip>
            </Dropdown>
            <Tooltip title="Limpar filtros" className="cursor-pointer">
              <AntdButton
                disabled={!isFiltering}
                className="filter-button"
                icon={<ClearOutlined />}
                onClick={clearOrderFilters}
              />
            </Tooltip>
          </div>
          <OrderSearchInput onSearch={onSearch} placeholder="Pesquisar por pedido" />
        </div>
        <Button
          loading={false}
          text="Novo pedido"
          bgcolor={"red"}
          textcolor={"white"}
          onClick={() => toggleCreateOrder()}
        />
      </div>
      <Table dataSource={getTableData()} loading={false} rowKey="_id" size="middle">
        <Column
          title="Pedido"
          dataIndex="displayId"
          key="displayId"
          render={(value) => <b>#{value}</b>}
        />
        <Column
          title="Via"
          dataIndex="integrationSource"
          key="integrationSource"
          render={(value) => <IntegrationSourceLogo integrationSource={value} size="default" />}
        />
        <Column
          title="Hora do pedido"
          dataIndex="orderTimestamp"
          key="orderTimestamp"
          render={(value) => unixToViewDateTime(value)}
        />
        <Column
          title="Status"
          dataIndex="lastOrderStatus"
          key="lastOrderStatus"
          render={(value: OrderStatusName) => <OrderStatusTag status={value} bg />}
        />
        <Column
          title="Entregador"
          dataIndex="driver"
          key="driver"
          render={(value: Driver, record: Order) => (
            <>
              {value ? (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <span>
                      <Avatar
                        src={value.profileImage}
                        icon={!value.profileImage && <UserOutlined />}
                      />
                    </span>
                    <span>{handleDriverBigName(value.firstName, value.lastName)}</span>
                    <WhatsAppButton driverNumber={value.telephone} />
                  </div>
                  <p
                    className="text-red cardLink"
                    onClick={() => {
                      if (value) {
                        return openAssignModalAndSetOrder(record._id, value._id);
                      }
                    }}
                  >
                    Alterar entregador
                  </p>
                </div>
              ) : (
                <div className="flex items-center">
                  <span
                    className="text-red cardLink"
                    onClick={() => openAssignModalAndSetOrder(record._id)}
                  >
                    Atribuir entregador
                  </span>
                </div>
              )}
            </>
          )}
        />
        <Column
          title="Ações"
          key="actions"
          render={(_text, record: Order) => (
            <Space size="middle">
              <Tooltip title="Detalhes" className="cursor-pointer">
                <DetailsIcon onClick={() => toggleOrderDetails(record)} />
              </Tooltip>
              <Tooltip title="Editar" className="cursor-pointer">
                <EditIcon onClick={() => toggleCreateOrder(record)} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default OrderListTab;
