import React from "react";
import { PossibleColors, StyledButton, pickRightColor } from "../layout.styles";

interface ButtonProps {
  text: string;
  icon?: React.ReactNode;
  loading?: boolean;
  bgcolor: PossibleColors;
  textcolor: PossibleColors;
  borderColor?: PossibleColors;
  disabled?: boolean;
  onClick?: (param?: any) => void;
  className?: string;
  htmlType?: "button" | "reset" | "submit" | undefined;
}

const Button = (props: ButtonProps) => {
  const {
    text,
    icon,
    loading,
    bgcolor,
    textcolor,
    borderColor,
    disabled,
    onClick,
    className,
    htmlType,
  } = props;

  return (
    <StyledButton
      className={className}
      type="primary"
      icon={icon}
      loading={loading}
      bgcolor={pickRightColor(bgcolor)}
      textcolor={pickRightColor(textcolor)}
      bordercolor={pickRightColor(borderColor ? borderColor : "#ef233c")}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
