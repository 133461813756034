import React from "react";
import { StyledTitle } from "../layout.styles";

interface PageTitleProps {
  title: string;
  className?: string;
}

const PageTitle = ({ title, className }: PageTitleProps) => {
  return <StyledTitle className={className}>{title}</StyledTitle>;
};

export default PageTitle;
