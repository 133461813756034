import { useState } from "react";
import RealCurrency from "components/RealCurrency/RealCurrency";
import Order from "interfaces/domain/order.interface";
import { Avatar, Drawer, Timeline } from "antd";
import BackBtnArrowImg from "assets/images/UX-btnArrow.png";
import Button from "components/layout/Button";
import {
  getOrderStatusColor,
  getOrderTranslatedStatus,
  handleDriverBigName,
  translatePaymentMethod,
} from "utils/order.utils";
import { formatToViewDateTime } from "utils/date.utils";
import { getMaskedCellphone } from "utils/mask.utils";
import { UserOutlined } from "@ant-design/icons";
import IntegrationSourceLogo from "components/IntegrationSourceLogo";
import CancelOrderModal from "components/CancelOrderModal";
import WhatsAppButton from "components/WhatsAppButton";
import "./OrderDetails.scss";
import ConcludeOrderModal from "./ConcludeOrderModal";

interface OrderDetailsProps {
  order: Order | undefined;
  isVisible: boolean;
  toggleDetails: () => void;
  openAssignModalAndSetOrder: (orderId: string, driverId?: string) => void;
}

interface DetailValueProps {
  value: string | number | undefined;
}

const DetailValue = ({ value }: DetailValueProps) => <p className="mb-4">{value ? value : "--"}</p>;

const OrderDetails = ({
  order,
  isVisible,
  toggleDetails,
  openAssignModalAndSetOrder,
}: OrderDetailsProps) => {
  const [visibleModal, setVisibleModal] = useState("");
  const toggleVisibleModal = (modalName?: string) => {
    setVisibleModal("");
    if (!visibleModal && modalName && modalName !== visibleModal) {
      setVisibleModal(modalName);
    }
  };

  return (
    <>
      {order && order.lastOrderStatus !== "CONCLUDED" && order.lastOrderStatus !== "CANCELLED" && (
        <>
          <CancelOrderModal
            orderId={order._id}
            isVisible={visibleModal === "CancelOrderModal"}
            toggleModal={() => toggleVisibleModal("CancelOrderModal")}
            toggleOrderDetails={toggleDetails}
          />
          <ConcludeOrderModal
            orderId={order._id}
            isVisible={visibleModal === "ConcludeOrderModal"}
            toggleModal={() => toggleVisibleModal("ConcludeOrderModal")}
            toggleOrderDetails={toggleDetails}
          />
        </>
      )}
      <Drawer
        height={"100%"}
        placement={"bottom"}
        onClose={toggleDetails}
        visible={isVisible}
        key={"bottom"}
        bodyStyle={{ fontFamily: "Poppins" }}
        destroyOnClose
        closable={false}
      >
        <div className="orderdetails">
          {order && (
            <>
              <div className="flex justify-between">
                <div className="cursor-pointer" onClick={toggleDetails}>
                  <span>
                    <img className="align-middle" src={BackBtnArrowImg} alt="Imagem de voltar" />
                  </span>
                  <span className="ml-3">Voltar</span>
                </div>
                <div>
                  <h2 className="text-2xl font-semibold ml-16">
                    Detalhes do pedido
                    <span className="orderId orderId--detail"> #{order.displayId}</span>
                  </h2>
                </div>
                <div className="flex gap-6">
                  <Button
                    loading={false}
                    text="Cancelar pedido"
                    bgcolor={"white"}
                    textcolor={"red"}
                    onClick={() => toggleVisibleModal("CancelOrderModal")}
                  />
                  {order.driver && (
                    <Button
                      text="Concluir pedido"
                      bgcolor="red"
                      textcolor={"white"}
                      onClick={() => toggleVisibleModal("ConcludeOrderModal")}
                    />
                  )}
                </div>
              </div>

              <div className="flex">
                <div>
                  <div className="mt-20 mb-20 flex">
                    <div className="orderdetails__info">
                      <p className="font-semibold mb-5">Informações do pedido</p>
                      <p className="orderdetails__label">Observação</p>
                      <DetailValue value={order.note} />
                      <div className="">
                        <p className="orderdetails__label">Forma de pagamento</p>
                        {order.payments.methods.length ? (
                          order.payments.methods.map((method) => (
                            <DetailValue
                              key={method.method}
                              value={translatePaymentMethod(method.method)}
                            />
                          ))
                        ) : (
                          <DetailValue value="--" />
                        )}
                      </div>
                      <div>
                        <p className="orderdetails__label">Detalhes do pagamento</p>
                        <DetailValue
                          value={
                            order.payments.prepaid > 0
                              ? "PAGO ONLINE, não cobrar o cliente"
                              : "Cobrar o cliente na entrega"
                          }
                        />
                      </div>
                      <div className="flex justify-between w-96">
                        <div>
                          <p className="orderdetails__label">Sub Total</p>
                          <RealCurrency valueToConvert={order.paymentSummary.subTotalPrice} sign />
                        </div>
                        <div>
                          <p className="orderdetails__label">Taxa de Entrega</p>
                          <RealCurrency valueToConvert={order.paymentSummary.deliveryFee} sign />
                        </div>
                        <div>
                          <p className="orderdetails__label">Total</p>
                          <RealCurrency valueToConvert={order.paymentSummary.totalPrice} sign />
                        </div>
                        <div>
                          <p className="orderdetails__label">Troco</p>
                          <RealCurrency valueToConvert={order.paymentSummary.changeFor} sign />
                        </div>
                      </div>
                    </div>

                    <div className="orderdetails__info">
                      <p className="font-semibold mb-5">Status do pedido</p>
                      <Timeline mode="left">
                        {order.orderStatus.map((status) => (
                          <Timeline.Item
                            key={status.createdAt}
                            label={formatToViewDateTime(status.createdAt)}
                            color={getOrderStatusColor(status.name)}
                          >
                            {getOrderTranslatedStatus(status.name)}
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="orderdetails__info">
                      <p className="font-semibold mb-5">Informações do destino</p>
                      <p className="orderdetails__label">Nome do cliente</p>
                      <DetailValue value={order.customer.name} />
                      <p className="orderdetails__label">Endereço</p>
                      <DetailValue value={order.deliveryAddress.formattedAddress} />
                      <p className="orderdetails__label">Complemento</p>
                      <DetailValue value={order.deliveryAddress.complement} />
                      <p className="orderdetails__label">Referência</p>
                      <DetailValue value={order.deliveryAddress.reference} />
                      <p className="orderdetails__label">Distância</p>
                      <DetailValue value={`${order.distance || 0} km`} />
                    </div>

                    <div className="orderdetails__info">
                      <p className="font-semibold mb-5">Informações do entregador</p>
                      <p className="orderdetails__label mb-4">Nome do entregador</p>
                      {order.driver ? (
                        <>
                          <span className="mr-2">
                            <Avatar
                              src={order.driver.profileImage}
                              icon={!order.driver.profileImage && <UserOutlined />}
                              size="large"
                            />
                          </span>
                          <span>
                            {handleDriverBigName(order.driver.firstName, order.driver.lastName)}
                          </span>
                        </>
                      ) : (
                        <div className="mb-4">
                          <span className="mr-2">
                            <Avatar icon={<UserOutlined />} size="large" />
                          </span>
                          <span
                            className="text-red cursor-pointer"
                            onClick={() => openAssignModalAndSetOrder(order._id)}
                          >
                            Atribuir entregador
                          </span>
                        </div>
                      )}
                      {order.driver &&
                      order.driver._id &&
                      order.lastOrderStatus !== "CONCLUDED" &&
                      order.lastOrderStatus !== "CANCELLED" ? (
                        <p
                          className="text-red cursor-pointer mt-4 mb-6"
                          onClick={() => {
                            if (order.driver) {
                              return openAssignModalAndSetOrder(order._id, order.driver._id);
                            }
                          }}
                        >
                          Alterar entregador
                        </p>
                      ) : null}
                      {order.driver && (
                        <>
                          <p className="orderdetails__label">Contato do entregador</p>
                          <div className="flex items-center gap-2">
                            <p>{getMaskedCellphone(order.driver.telephone.slice(2))}</p>
                            <WhatsAppButton driverNumber={order.driver.telephone} />
                          </div>
                        </>
                      )}
                      <p className="orderdetails__label">Pagamento por entrega</p>
                      <RealCurrency valueToConvert={order.driverDeliveryFee} sign />
                      <p className="orderdetails__label">
                        Pagamento por distancia da entrega em raio
                      </p>
                      <RealCurrency valueToConvert={order.driverRadiusFee} sign />
                    </div>
                  </div>
                </div>
                <div className="orderdetails__info flex flex-col justify-center">
                  <p className="font-semibold mb-3">Pedido feito via</p>
                  <IntegrationSourceLogo integrationSource={order.integrationSource} size="big" />
                </div>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default OrderDetails;
