import React, { useEffect, useState } from "react";
import { SelectOrdersMetadata } from "interfaces/ordersPage.interface";
import Order from "interfaces/domain/order.interface";
import { getCompleteHour } from "utils/date.utils";
import OrderMarker from "./OrderIcons";
import { ReactComponent as ArrowDownIcon } from "assets/icons/orders/arrowdown-icon.svg";
import OrderStatusDropdown from "./OrderStatusDropdown";
import "../Orders.scss";
import { Avatar, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import WhatsAppButton from "components/WhatsAppButton";
import { handleDriverBigName } from "utils/order.utils";
import { Address } from "interfaces/domain/global.interface";

interface OrderCardProps {
  order: Order;
  selectedOrders: SelectOrdersMetadata[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<SelectOrdersMetadata[]>>;
  toggleOrderDetails: (order: Order) => void;
  openAssignModalAndSetOrder: (orderId: string, driverId?: string) => void;
}

const OrderCard = (props: OrderCardProps) => {
  const {
    order,
    selectedOrders,
    setSelectedOrders,
    toggleOrderDetails,
    openAssignModalAndSetOrder,
  } = props;

  const [isSelected, setIsSelected] = useState(false);

  const handleCardClick = (orderId: string, displayId: string, deliveryAddress: Address) => {
    setIsSelected(!isSelected);
    if (isSelected) {
      setSelectedOrders((existingOrders) =>
        existingOrders.filter((existingOrder) => existingOrder.orderId !== orderId)
      );
    } else {
      if (selectedOrders.length === 5) {
        notification["error"]({
          message: "Limite de seleção de pedidos",
          description: "Só é possivel selecionar até 5 pedidos ao mesmo tempo.",
        });
      } else {
        setSelectedOrders((existingOrders) => [
          ...existingOrders,
          { orderId, displayId, deliveryAddress },
        ]);
      }
    }
  };

  useEffect(() => {
    const selectedOrder = selectedOrders.find(
      (selectedOrder) => selectedOrder.orderId === order._id
    );
    if ((selectedOrder && !isSelected) || (!selectedOrder && isSelected)) {
      setIsSelected(!isSelected);
    }
  }, [isSelected, order._id, selectedOrders]);

  const lastOrderStatus = order.lastOrderStatus;

  return (
    <>
      <div
        className={`orderSmallCard ${isSelected && "orderSmallCard--selected"}`}
        onClick={() => handleCardClick(order._id, order.displayId, order.deliveryAddress)}
      >
        <div className="flex justify-between">
          <div className="flex gap-2">
            <span
              className="orderId"
              onClick={(e) => {
                e.stopPropagation();
                toggleOrderDetails(order);
              }}
            >
              #{order.displayId}
            </span>
            <span className="orderTime">{getCompleteHour(order.orderTimestamp)}</span>
          </div>
          <div className="flex gap-1">
            <span>
              <OrderMarker orderStatus={lastOrderStatus} />
            </span>
            <OrderStatusDropdown order={order}>
              <div onClick={(e) => e.stopPropagation()}>
                <span>
                  <ArrowDownIcon className="align-middle" />
                </span>
              </div>
            </OrderStatusDropdown>
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <div className="flex items-center gap-2">
            {order.driver && (
              <>
                <div>
                  <Avatar
                    src={order.driver.profileImage}
                    icon={!order.driver.profileImage && <UserOutlined />}
                    size="small"
                  />
                </div>
                <span>{handleDriverBigName(order.driver.firstName, order.driver.lastName)}</span>
                <WhatsAppButton driverNumber={order.driver.telephone} />
              </>
            )}
            {!order.driver && (
              <>
                {selectedOrders.length <= 1 ? (
                  <>
                    <Avatar icon={<UserOutlined />} size="small" />
                    <span
                      className="ml-2 cardLink text-red cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        return openAssignModalAndSetOrder(order._id);
                      }}
                    >
                      Atribuir entregador
                    </span>
                  </>
                ) : (
                  <span className="cursor-pointer">Seleção de pedidos</span>
                )}
              </>
            )}
          </div>
          <div>
            {order.driver && order.driver._id && selectedOrders.length <= 1 && (
              <span
                className="cardLink text-red cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (order.driver) {
                    return openAssignModalAndSetOrder(order._id, order.driver._id);
                  }
                }}
              >
                Alterar
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCard;
