import { useState } from "react";
import { Avatar, Space, Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import StoreService from "services/store.service";
import { useQuery } from "react-query";
import { getMaskedCellphone, getMaskedCPF } from "utils/mask.utils";
import Driver from "interfaces/domain/driver.interface";
import { ReactComponent as RemoveIcon } from "assets/icons/remove-icon.svg";
import DisassociateDriverModal from "./DisassociateDriverModal";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as DetailsIcon } from "assets/icons/details-icon.svg";
import DriverDetailsModal from "./DriverDetailsModal";
import WhatsAppButton from "components/WhatsAppButton";
import OrderSearchInput from "pages/Orders/components/OrderSearchInput";

const AssociatedDriversTable = () => {
  const [filtredData, setFiltredData] = useState<Driver[] | undefined>();

  const [isDetailModalVisible, setDetailModalVisible] = useState(false);
  const [driverToDetail, setDriverToDetail] = useState<Driver>({} as Driver);
  const toggleDetailModal = () => setDetailModalVisible(!isDetailModalVisible);

  const [isVisible, setIsVisible] = useState(false);
  const [driverToDisassociate, setDriverToDisassociate] = useState("");

  const { data, isLoading } = useQuery("getAssociatedDrivers", StoreService.getAssociatedDrivers, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleDisassociateButton = (driverId: string) => {
    setDriverToDisassociate(driverId);
    toggleModal();
  };

  const toggleModal = () => setIsVisible(!isVisible);

  const onSearch = (searchValue: string) => {
    if (!data) return;
    const filtred = data.filter((driver) => {
      const firstNameLowed = driver.firstName.toLowerCase();
      const searchValueLowed = searchValue.toLowerCase();
      return firstNameLowed.includes(searchValueLowed);
    });
    setFiltredData(filtred);
  };

  return (
    <>
      <DriverDetailsModal
        driver={driverToDetail}
        isVisible={isDetailModalVisible}
        toggleModal={toggleDetailModal}
      />
      <DisassociateDriverModal
        isVisible={isVisible}
        toggleModal={toggleModal}
        driverToDisassociate={driverToDisassociate}
        setDriverToDisassociate={setDriverToDisassociate}
      />
      <div className="flex mb-6">
        <OrderSearchInput onSearch={onSearch} placeholder="Pesquisar por nome" size="large" />
      </div>
      <Table
        pagination={{
          pageSize: 25,
        }}
        dataSource={filtredData && filtredData.length > 0 ? filtredData : data}
        loading={isLoading}
        rowKey="_id"
      >
        <Column
          title="Entregador"
          dataIndex="driverName"
          key="driverName"
          sorter={(a: Driver, b: Driver) => a.firstName.localeCompare(b.firstName)}
          render={(_value, record: Driver) => (
            <>
              <Avatar
                src={record.profileImage}
                icon={!record.profileImage && <UserOutlined />}
                size="large"
              />
              <span className="ml-2">
                {record.firstName} {record.lastName}
              </span>
            </>
          )}
        />
        <Column
          title="Documento"
          dataIndex="document"
          key="document"
          render={(text) => getMaskedCPF(text)}
        />
        <Column
          title="Telefone"
          dataIndex="telephone"
          key="telephone"
          render={(text) => (
            <div className="flex items-center gap-2">
              {getMaskedCellphone(text.slice(2))}
              <WhatsAppButton driverNumber={text} />
            </div>
          )}
        />

        <Column
          title="Ações"
          key="actions"
          render={(_text, record: Driver) => (
            <Space size="middle">
              <Tooltip title="Visualizar" className="cursor-pointer">
                <DetailsIcon
                  onClick={() => {
                    setDriverToDetail(record);
                    toggleDetailModal();
                  }}
                />
              </Tooltip>
              <Tooltip title="Desassociar entregador" className="cursor-pointer">
                <RemoveIcon onClick={() => handleDisassociateButton(record._id)} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default AssociatedDriversTable;
