import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Button as AntdButton, message } from "antd";
import AvatarContainer from "components/AvatarContainer";
import DataCard from "components/DataCard";
import Button from "components/layout/Button";
import ChangePasswordModal from "./ChangePasswordModal";
import { useMutation } from "react-query";
import UserService, { UpdateUserDataReq } from "services/user.service";
import { StoreContext } from "contexts/store.context";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

const UserDataCard: React.FC = () => {
  const { user, setUser } = useContext(StoreContext);
  const [userDataForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [passModalVisible, setPassModalVisible] = useState(false);
  const togglePassModal = () => setPassModalVisible(!passModalVisible);

  const [editUserData, setEditUserData] = useState(false);
  const toggleUserDataEdit = () => setEditUserData(!editUserData);

  useEffect(() => {
    if (!user._id) return;
    userDataForm.setFieldsValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  }, [userDataForm, user]);

  const updateUserDataMutation = useMutation(
    "updateUserDataMutation",
    (userData: UpdateUserDataReq) => UserService.updateUserData(userData),
    {
      onSuccess(data) {
        message.success("Dados alterados com sucesso!");
        toggleUserDataEdit();
        setUser(data);
        localStorage.setItem("user", JSON.stringify(data));
      },
      onError() {
        message.error("Erro ao alterar dados, tente novamente.");
      },
    }
  );

  const submitForm = async (values: FormValues) => {
    setIsLoading(true);
    updateUserDataMutation.mutate({
      userId: user._id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    });
    setIsLoading(false);
  };

  return (
    <>
      <ChangePasswordModal isVisible={passModalVisible} toggleModal={togglePassModal} />
      <DataCard title="Dados pessoais" editable toggleEditing={toggleUserDataEdit}>
        <div className="flex">
          <div className="flex flex-col items-center mr-16 w-56">
            <AvatarContainer />
            <p className="mt-3 text-base text-clip text-center">
              {user.firstName} {user.lastName}
            </p>
            <p>Usuário</p>
            <AntdButton type="text" danger onClick={togglePassModal}>
              Alterar senha
            </AntdButton>
          </div>
          <div className="w-full">
            <Form form={userDataForm} layout="vertical" onFinish={(values) => submitForm(values)}>
              <div className="grid grid-cols-3 gap-4 items-center">
                <Form.Item
                  name="firstName"
                  label="Nome"
                  rules={[{ required: true, type: "string" }]}
                >
                  <Input className="input" disabled={!editUserData || isLoading} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Sobrenome"
                  rules={[{ required: true, type: "string" }]}
                >
                  <Input className="input" disabled={!editUserData || isLoading} />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, type: "email" }]}>
                  <Input className="input" disabled={!editUserData || isLoading} />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
        {editUserData && (
          <div className="flex justify-end">
            <Button
              onClick={() => userDataForm.submit()}
              loading={isLoading}
              text="Salvar alteração"
              textcolor="white"
              bgcolor="red"
              htmlType="submit"
            />
          </div>
        )}
      </DataCard>
    </>
  );
};

export default UserDataCard;
