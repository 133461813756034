import Avatar from "antd/lib/avatar";
import Modal from "components/layout/Modal";
import ModalTitle from "components/layout/ModalTitle";
import Driver from "interfaces/domain/driver.interface";
import { getMaskedCPF, getMaskedCellphone } from "utils/mask.utils";
import { UserOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import OrderService from "services/order.service";
import Table from "antd/lib/table/Table";
import Column from "antd/lib/table/Column";
import Order, { OrderStatusName } from "interfaces/domain/order.interface";
import OrderStatusTag from "components/OrderStatusTag";
import { unixToViewDateTime } from "utils/date.utils";
import RealCurrency from "components/RealCurrency/RealCurrency";
import styled from "styled-components";
import WhatsAppButton from "components/WhatsAppButton";

interface OrderDetailsModalProps {
  driver: Driver | undefined;
  startDate: number;
  endDate: number;
  isVisible: boolean;
  toggleModal: () => void;
}

const StyledDriverInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1.75rem;
  padding: 2.8125rem 2rem;
  background: #f6f6f6;
  border: 0.0625rem solid #eaeaea;
  border-radius: 0.625rem;
`;

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = (props) => {
  const { driver, startDate, endDate, isVisible, toggleModal } = props;

  const { data, isLoading } = useQuery(
    ["getOrdersByDrivePeriod", driver?._id, startDate, endDate],
    () => OrderService.getOrdersByDrivePeriod(driver?._id || "", startDate, endDate),
    { enabled: !!driver?._id }
  );

  return (
    <Modal
      title={ModalTitle({
        text: "Detalhes do período",
      })}
      width="80%"
      visible={isVisible}
      onCancel={toggleModal}
    >
      <div className="flex flex-col gap-8">
        <div className="flex justify-center items-center gap-6">
          <StyledDriverInfo>
            <div className="flex items-center gap-4">
              <Avatar
                src={driver?.profileImage}
                icon={!driver?.profileImage && <UserOutlined />}
                size={140}
              />
              <div className="flex flex-col">
                <b>Nome</b>
                <p>
                  {driver?.firstName} {driver?.lastName}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <b>Telefone</b>
              <div className="flex">
                <p>{getMaskedCellphone(driver?.telephone.slice(2) || "")} </p>
                <WhatsAppButton driverNumber={driver?.telephone || ""} />
              </div>
            </div>
            <div className="flex flex-col">
              <b>Documento</b>
              <p>{getMaskedCPF(driver?.document || "")}</p>
            </div>
            <div className="flex flex-col">
              <b>Entregas realizadas</b>
              <p>{data?.length}</p>
            </div>
          </StyledDriverInfo>
        </div>
        <Table
          pagination={{
            pageSize: 5,
          }}
          rowKey={(e) => e._id}
          dataSource={data}
          loading={isLoading}
        >
          <Column
            title="Hora do pedido"
            dataIndex="orderTimestamp"
            key="orderTimestamp"
            render={(value) => unixToViewDateTime(value)}
          />
          <Column
            title="Pedido"
            dataIndex="displayId"
            key="displayId"
            render={(value) => <span className="orderId">#{value}</span>}
          />
          <Column
            title="Status"
            dataIndex="lastOrderStatus"
            key="lastOrderStatus"
            render={(value: OrderStatusName) => <OrderStatusTag status={value} bg />}
          />
          <Column
            title="Endereço"
            key="address"
            render={(_text, record: Order) => (
              <span>
                {record.deliveryAddress.streetName}, {record.deliveryAddress.streetNumber} -{" "}
                {record.deliveryAddress.neighborhood}
              </span>
            )}
          />
          <Column
            title="Horário de coleta"
            key="address"
            render={(_text, record: Order) =>
              record.collectedAt ? unixToViewDateTime(record.collectedAt) : "--"
            }
          />
          <Column
            title="Horário de entrega"
            key="address"
            render={(_text, record: Order) =>
              record.deliveredAt ? unixToViewDateTime(record.deliveredAt) : "--"
            }
          />
          <Column
            title="A receber por entrega"
            key="driverDeliveryFee"
            dataIndex="driverDeliveryFee"
            render={(_text, record: Order) => (
              <RealCurrency valueToConvert={record.driverDeliveryFee} sign />
            )}
          />
          <Column
            title="A receber por distancia de entrega"
            key="driverRadiusFee"
            dataIndex="driverRadiusFee"
            render={(_text, record: Order) => (
              <RealCurrency valueToConvert={record.driverRadiusFee} sign />
            )}
          />
        </Table>
      </div>
    </Modal>
  );
};

export default OrderDetailsModal;
