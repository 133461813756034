import styled from "styled-components";
import { FormInstance } from "antd/lib/form/Form";
import { HereAutoCompleteItem } from "interfaces/here.interface";
import { getStateLabel } from "utils/order.utils";

interface AddressListDropdownProps {
  isVisible: boolean;
  addressList: HereAutoCompleteItem[] | undefined;
  formInstance: FormInstance<any>;
  toggle: () => void;
}

const StyledDropdown = styled.ul`
  position: absolute;
  top: 5.9375rem;
  width: max-content;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e1e1e1;
  border-radius: 0.625rem;
  z-index: 1050;
  display: block;
`;

const StyledDropdownItem = styled.li`
  cursor: pointer;
  padding: 0.5625rem 1.125rem;
  border-radius: 0.625rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const AddressListDropdown: React.FC<AddressListDropdownProps> = (props) => {
  const { isVisible, addressList, formInstance, toggle } = props;
  return (
    <div
      style={{ width: "100%", boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)" }}
      className={isVisible ? "block" : "hidden"}
    >
      <StyledDropdown>
        {addressList &&
          addressList.map((address) => (
            <StyledDropdownItem
              key={address.locationId}
              onMouseDown={() => {
                formInstance.setFieldsValue({
                  postalCode: address.address.postalCode,
                  streetName: address.address.street,
                  streetNumber: "",
                  neighborhood: address.address.district,
                  city: address.address.city,
                  state: getStateLabel(address.address.state),
                });
                toggle();
              }}
            >
              <i>
                {address.address.street}, {address.address.district}, {address.address.city},{" "}
                {address.address.postalCode}
              </i>
            </StyledDropdownItem>
          ))}
      </StyledDropdown>
    </div>
  );
};

export default AddressListDropdown;
