import React from "react";
import Order from "interfaces/domain/order.interface";
import { Dropdown, Menu } from "antd";
import "./OrderStatusDropdown.scss";
import { useMap } from "react-map-gl";

interface OrderStatusDropdownProps {
  children?: React.ReactNode;
  order: Order;
}

const OrderStatusDropdown: React.FC<OrderStatusDropdownProps> = ({ order, children }) => {
  const { orderMap } = useMap();

  const StatusMenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={(e) => {
          if (!orderMap) return;
          e.domEvent.stopPropagation();
          orderMap.flyTo({
            center: [order.deliveryAddress.coordinates.lng, order.deliveryAddress.coordinates.lat],
            zoom: 15,
          });
        }}
      >
        Centralizar
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown className="cursor-pointer" overlay={StatusMenu} trigger={["click"]}>
      {children}
    </Dropdown>
  );
};

export default OrderStatusDropdown;
