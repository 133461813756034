import styled from "styled-components";

const StyledContainer = styled.div`
  position: absolute;
  top: 9.2rem;
  right: 0.625rem;
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
`;

const MapActionContainer: React.FC = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default MapActionContainer;
