import { HereGeocodeApiClient, HereAutoCompleteApiClient } from "utils/apiClient";
import { HereAutoCompleteResponse, HereGeocodeResponse } from "interfaces/here.interface";
import appEnv from "utils/appEnv";

const HereService = {
  async geocodeAddress(formattedAddress: string) {
    const urlEncodedAddress = encodeURIComponent(formattedAddress);
    const { data } = await HereGeocodeApiClient.get<HereGeocodeResponse>(
      `/geocode?q=${urlEncodedAddress}&apiKey=${appEnv.HERE_API_KEY}`
    );
    return data;
  },
  async autoCompleteAddress(query: string, storeLatLng: [number, number]) {
    const { data } = await HereAutoCompleteApiClient.get<HereAutoCompleteResponse>("", {
      params: {
        query: query,
        prox: `${storeLatLng[0]},${storeLatLng[1]},40000`,
        country: "BRA",
        maxresults: 10,
        language: "pt",
        apiKey: appEnv.HERE_API_KEY,
      },
    });
    return data.suggestions;
  },
};

export default HereService;
