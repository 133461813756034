import React, { useEffect, useState } from "react";
import { Form, Radio } from "antd";
import Button from "components/layout/Button";
import currency from "currency.js";
import Store, { DriverPaymentConfig } from "interfaces/domain/store.interface";
import NumberFormat from "react-number-format";
import toast from "react-hot-toast";
import { UseMutateAsyncFunction } from "react-query";

interface DriverDeliveryFeeCardProps {
  deliveryFee: {
    fee?: number;
    options?: {
      uniqueValue: boolean;
      altenatingValue: boolean;
    };
  };
  mutateConfig: UseMutateAsyncFunction<Store, unknown, DriverPaymentConfig, unknown>;
  setPaymentConfig: React.Dispatch<React.SetStateAction<DriverPaymentConfig | undefined>>;
}

interface FormValues {
  deliveryFeeOption: number;
  deliveryValue: number;
}

const DriverDeliveryFeeCard: React.FC<DriverDeliveryFeeCardProps> = (props) => {
  const { deliveryFee, mutateConfig, setPaymentConfig } = props;
  const [radioOpt, setRadioOpt] = useState(-1);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!deliveryFee.options?.uniqueValue && !deliveryFee.options?.altenatingValue) {
      setRadioOpt(3);
    } else if (deliveryFee.options.uniqueValue) {
      setRadioOpt(1);
    } else if (deliveryFee.options.altenatingValue) {
      setRadioOpt(2);
    }
    form.setFieldsValue({ deliveryFeeOption: radioOpt });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [deliveryFee, form]);

  useEffect(() => {
    if (radioOpt === 3) {
      form.setFieldsValue({ deliveryValue: 0 });
    }
  }, [form, radioOpt]);

  const onFinishForm = (values: FormValues) => {
    const { deliveryValue } = values;
    const fixedDeliveryValue = deliveryValue ? currency(deliveryValue, { decimal: "," }).value : 0;
    const newValues = {
      fee: fixedDeliveryValue,
      options: {
        uniqueValue: radioOpt === 1 ? true : false,
        altenatingValue: radioOpt === 2 ? true : false,
      },
    };
    const params = { deliveryFee: newValues };
    setPaymentConfig(params);
    if (JSON.stringify(deliveryFee) === JSON.stringify(newValues)) {
      toast.success("Dados inalterados.");
    } else {
      toast.promise(mutateConfig(params), {
        loading: "Carregando...",
        success: "Pagamento por entrega atualizado com sucesso!",
        error: "Erro ao atualizar pagamento por entrega.",
      });
    }
  };

  return (
    <div className="flex gap-4">
      <Form form={form} layout="vertical" onFinish={(values) => onFinishForm(values)}>
        <div className="flex flex-col items-start gap-4">
          <Form.Item name="deliveryFeeOption" valuePropName="checked">
            <Radio.Group value={radioOpt} onChange={(e) => setRadioOpt(e.target.value)}>
              <div className="flex flex-col gap-2">
                <Radio value={1}>Utilizar valor único por entrega.</Radio>
                <Radio value={2}>
                  Utilizar valor único por entrega, apenas quando não houver taxa de entrega no
                  pedido.
                </Radio>
                <Radio value={3}>Não utilizar pagamento por entrega.</Radio>
              </div>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Valor por entrega" name="deliveryValue" initialValue={deliveryFee.fee}>
            <NumberFormat
              disabled={radioOpt === 3}
              placeholder="Valor em R$"
              className="input"
              prefix="R$"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              allowNegative={false}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Button htmlType="submit" text="Salvar configuração" bgcolor="red" textcolor="white" />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DriverDeliveryFeeCard;
