import { DownOutlined, ClearOutlined } from "@ant-design/icons";
import { Dropdown, Button as AntdButton, Menu, Tooltip } from "antd";
import InnerNavLink from "components/InnerNavLink";
import Button from "components/layout/Button";
import React, { useState } from "react";
import OrderCard from "./components/OrderCard";
import OrderFeedSkeleton from "./components/OrderFeedSkeleton";
import { ReactComponent as EmptyOrdersIcon } from "assets/icons/orders/empty-orders-icon.svg";
import { SelectOrdersMetadata } from "interfaces/ordersPage.interface";
import Order, { OrderStatusName } from "interfaces/domain/order.interface";
import Store from "interfaces/domain/store.interface";
import OrderMarker from "./components/OrderIcons";
import OrderSearchInput from "./components/OrderSearchInput";
import FilterButton from "./components/FilterButton";
import MapPage from "./components/NewOrderMap/MapPage";
import { MapContainer, Map, OrderFeedContainer, ScrollContainer } from "./OrderMapTab.styles";

interface OrderMapTabProps {
  store: Store | undefined;
  orders: Order[];
  selectedOrders: SelectOrdersMetadata[];
  isErrorOrders: boolean;
  isLoadingOrders: boolean;
  setMapInstance: React.Dispatch<React.SetStateAction<mapboxgl.Map | undefined>>;
  handleBatchOrderAssign: () => void;
  toggleCreateOrder: () => void;
  setSelectedOrders: React.Dispatch<React.SetStateAction<SelectOrdersMetadata[]>>;
  toggleOrderDetails: (order: Order) => void;
  openAssignModalAndSetOrder: (orderId: string, driverId?: string | undefined) => void;
}

const OrderMapTab: React.FC<OrderMapTabProps> = (props) => {
  const {
    orders,
    selectedOrders,
    isErrorOrders,
    isLoadingOrders,
    handleBatchOrderAssign,
    toggleCreateOrder,
    setSelectedOrders,
    toggleOrderDetails,
    openAssignModalAndSetOrder,
  } = props;

  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [filtredOrders, setFiltredOrders] = useState<Order[]>();
  const clearOrderFilters = () => {
    setFiltredOrders([]);
    setIsFiltering(false);
  };

  const filterByDisplayId = (displayId: string) => {
    if (!orders) return;
    const filtred = orders.filter((order) => {
      return order.displayId.includes(displayId);
    });
    setFiltredOrders(filtred);
  };

  const filterOrderByStatus = (status: OrderStatusName) => {
    if (!orders) return;
    clearOrderFilters();
    setIsFiltering(true);
    setFiltredOrders(orders.filter((order) => order.lastOrderStatus === status));
  };

  const filterSelectedOrders = () => {
    if (!orders) return;
    clearOrderFilters();
    setIsFiltering(true);
    setFiltredOrders(
      orders.filter(
        (order) => !!selectedOrders.find((selectedOrder) => selectedOrder.orderId === order._id)
      )
    );
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const StatusSortMenu = () => (
    <Menu selectable={false}>
      <Menu.Item
        key="0"
        icon={<OrderMarker orderStatus="UNASSIGNED" />}
        onClick={() => filterOrderByStatus("UNASSIGNED")}
      >
        <span>Não atribuido</span>
      </Menu.Item>
      <Menu.Item
        key="1"
        icon={<OrderMarker orderStatus="ASSIGNED" />}
        onClick={() => filterOrderByStatus("ASSIGNED")}
      >
        Atribuído
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<OrderMarker orderStatus="IN_TRANSIT" />}
        onClick={() => filterOrderByStatus("IN_TRANSIT")}
      >
        Em transito
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="flex justify-between mb-4">
        <div className="flex justify-center">
          <InnerNavLink to="/" text="Mapa" />
          <InnerNavLink to="orders/list" text="Lista" />
          <InnerNavLink to="orders/historic" text="Histórico" />
        </div>
        <div className="flex gap-6">
          <Button
            disabled={selectedOrders.length < 2}
            loading={false}
            text="Atribuir pedidos em lote"
            bgcolor={"white"}
            textcolor={"red"}
            onClick={handleBatchOrderAssign}
          />
          <Button
            loading={false}
            text="Novo pedido"
            bgcolor={"red"}
            textcolor={"white"}
            onClick={() => toggleCreateOrder()}
          />
        </div>
      </div>
      <MapContainer>
        <Map>
          <MapPage
            orders={orders}
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
            toggleOrderDetails={toggleOrderDetails}
            openAssignModalAndSetOrder={openAssignModalAndSetOrder}
          />
        </Map>
        <OrderFeedContainer>
          <div className="flex flex-col gap-1">
            <p>
              <b>Próximas entregas</b>
            </p>
            <div className="flex gap-5">
              <OrderSearchInput
                className="flex-1"
                onSearch={filterByDisplayId}
                placeholder="Pesquisar por pedido"
              />
              <FilterButton onClick={() => setIsFilterVisible(!isFilterVisible)} />
            </div>
          </div>
          {isFilterVisible && (
            <div className="flex gap-3 mt-3">
              <Dropdown overlay={StatusSortMenu} trigger={["click"]}>
                <Tooltip title="Filtrar pedidos pelo status" className="cursor-pointer">
                  <AntdButton className="filter-button">
                    Status <DownOutlined />
                  </AntdButton>
                </Tooltip>
              </Dropdown>
              <Tooltip title="Filtrar por pedidos selecionados" className="cursor-pointer">
                <AntdButton
                  className="filter-button"
                  disabled={!selectedOrders.length}
                  onClick={filterSelectedOrders}
                >
                  Selecionados
                </AntdButton>
              </Tooltip>
              <Tooltip title="Limpar filtros" className="cursor-pointer">
                <AntdButton
                  disabled={!isFiltering}
                  className="filter-button"
                  icon={<ClearOutlined />}
                  onClick={clearOrderFilters}
                />
              </Tooltip>
            </div>
          )}
          <ScrollContainer>
            {isLoadingOrders && (
              <OrderFeedSkeleton isLoading={isLoadingOrders || isErrorOrders} orderQtt={6} />
            )}
            {filtredOrders &&
              filtredOrders.map((order) => (
                <OrderCard
                  key={order._id}
                  order={order}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  toggleOrderDetails={toggleOrderDetails}
                  openAssignModalAndSetOrder={openAssignModalAndSetOrder}
                />
              ))}
            {!isLoadingOrders &&
              orders &&
              orders.length > 0 &&
              !filtredOrders?.length &&
              !isFiltering &&
              orders?.map((order) => {
                return (
                  <OrderCard
                    key={order._id}
                    order={order}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    toggleOrderDetails={toggleOrderDetails}
                    openAssignModalAndSetOrder={openAssignModalAndSetOrder}
                  />
                );
              })}
            {(!orders?.length || (!filtredOrders?.length && isFiltering)) && (
              <div className="flex flex-col items-center mt-10">
                <EmptyOrdersIcon />
                <p className="mt-2">Nenhum pedido para mostrar.</p>
              </div>
            )}
          </ScrollContainer>
        </OrderFeedContainer>
      </MapContainer>
    </>
  );
};

export default OrderMapTab;
