import { MapboxDirectionsRes } from "interfaces/mapboxService.interface";
import { MapboxApiClient } from "utils/apiClient";

const accessToken =
  "sk.eyJ1IjoiZmxlZXRtYXAiLCJhIjoiY2tvdzBpbzc1MDFhajJxbGp6NTI5Y20xeiJ9.5bpcVI-rQPgIP0xNi0qwFA";

const MapboxService = {
  async getRoute(coordinates: number[][]) {
    try {
      const coordinatesString = coordinates.join(";");
      const { data } = await MapboxApiClient.get<MapboxDirectionsRes>(
        `/directions/v5/mapbox/driving/${coordinatesString}?access_token=${accessToken}&overview=false`
      );
      return data;
    } catch (error) {
      console.error("[MapboxService - getRoute]", error);
    }
  },
};

export default MapboxService;
