import { Switch, Tooltip } from "antd";
import { GrConfigure } from "react-icons/gr";
import styled from "styled-components";

const StyledIntegrationCard = styled.div`
  width: 18.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border: 1px solid #f8f8f8;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 2px rgba(108, 108, 108, 0.25);
`;

const SetupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  box-shadow: 0px 0px 2px rgba(108, 108, 108, 0.25);
  border-radius: 0.625rem;
  padding: 0.5rem 1.5rem;
`;

interface IntegrationCardProps {
  source: string;
  icon: React.ReactNode;
  description: string;
  isConfigured: boolean;
  isActive: boolean | undefined;
  onSwitch: (source: string, checked: boolean) => void;
  onConfigure: () => void;
}

const IntegrationCard: React.FC<IntegrationCardProps> = (props) => {
  const { source, icon, description, isConfigured, isActive, onSwitch, onConfigure } = props;

  return (
    <StyledIntegrationCard>
      <div className="flex justify-between items-center gap-6">
        {icon}
        <Tooltip title="Ativar integração">
          <Switch
            disabled={!isConfigured}
            checked={isActive}
            onChange={(checked) => onSwitch(source, checked)}
          />
        </Tooltip>
      </div>
      <p className="text-">{description}</p>
      <hr />
      <SetupButton onClick={onConfigure}>
        Configurar
        <GrConfigure />
      </SetupButton>
    </StyledIntegrationCard>
  );
};

export default IntegrationCard;
