import Driver from "interfaces/domain/driver.interface";
import DriverInvite from "interfaces/domain/driverInvite.interface";
import Store, {
  ActivateIntegrationPayload,
  IntegratorPayload,
  DriverPaymentConfig,
} from "interfaces/domain/store.interface";
import { ApiClient } from "utils/apiClient";

export interface UpdateStoreDataReq {
  storeId: string;
  displayName: string;
  cnpj: string;
  email: string;
  phone: string;
  address: {
    postalCode: string;
    streetName: string;
    streetNumber: string;
    neighborhood: string;
  };
}

const StoreService = {
  async getAssociatedDrivers() {
    const { data } = await ApiClient.get<Driver[]>("store/drivers");
    return data;
  },
  async getInvitedDrivers() {
    const { data } = await ApiClient.get<DriverInvite[]>("invite/bystore");
    return data;
  },
  async updateStoreData(storeData: UpdateStoreDataReq) {
    const { data } = await ApiClient.put(`/store/${storeData.storeId}`, storeData);
    return data;
  },
  async storeIntegratorData(integratorPayload: IntegratorPayload) {
    const { data } = await ApiClient.post(`/store/integrators`, integratorPayload);
    return data;
  },
  async activateIntegrator(payload: ActivateIntegrationPayload) {
    const { data } = await ApiClient.post(`/store/integrators/activate`, payload);
    return data;
  },
  async updateDriverPayment(payload: DriverPaymentConfig) {
    const { data } = await ApiClient.post<Store>("/store/preference/driverPayment", payload);
    return data;
  },
};

export default StoreService;
