import { StoreContext } from "contexts/store.context";
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Topbar from "./Topbar";

const TopbarLayout: React.FC = () => {
  const { user } = useContext(StoreContext);
  const storedUser = localStorage.getItem("user");

  return (
    <div>
      <Topbar />
      <div className="p-8">
        {user._id || storedUser ? <Outlet /> : <Navigate to="auth/login" />}
      </div>
    </div>
  );
};

export default TopbarLayout;
