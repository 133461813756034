import React, { useEffect, useState } from "react";
import Driver from "interfaces/domain/driver.interface";
import Order from "interfaces/domain/order.interface";
import Store from "interfaces/domain/store.interface";
import { StoreContextConfig } from "interfaces/storeContext.interface";
import User from "interfaces/domain/user.interface";
import { Map, Marker } from "mapbox-gl";

export const StoreContext = React.createContext<StoreContextConfig>({} as StoreContextConfig);

export interface OrderMarkerStore {
  orderId: string;
  orderStatus: string;
  htmlMarker: HTMLDivElement;
  mapboxMarker: Marker;
  isSelected: boolean;
}

const StoreContextProvider: React.FC = ({ children }) => {
  const [store, setStore] = useState<Store | undefined>();
  const [user, setUser] = useState<User>({} as User);
  const [orders, setOrders] = useState<Order[]>([]);
  const [drivers, setDrivers] = useState<Driver[] | undefined>(undefined);
  const [mapInstance, setMapInstance] = useState<Map | undefined>(undefined);
  const [markers, setMarkers] = useState<OrderMarkerStore[]>([]);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (!user._id && storedUser !== null) {
      const parsedStoredUser = JSON.parse(storedUser);
      if (!parsedStoredUser) return;
      setUser(parsedStoredUser);
      setStore(parsedStoredUser.stores[0]);
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem("store", JSON.stringify(store));
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedStoredUser = JSON.parse(storedUser);
      if (!parsedStoredUser) return;
      parsedStoredUser.stores[0] = store;
      localStorage.setItem("user", JSON.stringify(parsedStoredUser));
    }
  }, [store, user._id]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        user,
        setUser,
        orders,
        setOrders,
        drivers,
        setDrivers,
        mapInstance,
        setMapInstance,
        markers,
        setMarkers,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
