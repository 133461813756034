import React, { useContext, useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import DataCard from "components/DataCard";
import { useDebouncedCallback } from "use-debounce";
import CEPService from "services/cep.service";
import InputMask from "react-input-mask";
import Button from "components/layout/Button";
import { useMutation } from "react-query";
import StoreService, { UpdateStoreDataReq } from "services/store.service";
import { StoreContext } from "contexts/store.context";

interface FormValues {
  displayName: string;
  email: string;
  cnpj: string;
  phone: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
  neighborhood: string;
}

const StoreDataTab: React.FC = () => {
  const { store, setStore } = useContext(StoreContext);
  const [storeDataForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [editStoreData, setEditStoreData] = useState(false);
  const toggleStoreDataEdit = () => setEditStoreData(!editStoreData);

  const [cepLoading, setCepLoading] = useState(false);
  const [cepHasErrors, setCepHasErrors] = useState<boolean | undefined>();
  const debouncedCep = useDebouncedCallback(async (value) => {
    setCepLoading(true);
    const cepData = await CEPService.getStreetFromCEP(value);
    if (cepData) {
      storeDataForm.setFieldsValue({
        streetName: cepData.logradouro,
        neighborhood: cepData.bairro,
      });
      setCepHasErrors(false);
    } else {
      setCepHasErrors(true);
    }
    setCepLoading(false);
  }, 1000);

  useEffect(() => {
    if (!store) return;
    storeDataForm.setFieldsValue({
      displayName: store.displayName,
      email: store.email,
      cnpj: store.cnpj,
      phone: store.phone ? store.phone.slice(2) : "",
      postalCode: store.address.postalCode,
      streetName: store.address.streetName,
      streetNumber: store.address.streetNumber.toString(),
      neighborhood: store.address.neighborhood,
    });
  }, [store, storeDataForm]);

  const updateUserDataMutation = useMutation(
    "updateUserDataMutation",
    (storeData: UpdateStoreDataReq) => StoreService.updateStoreData(storeData),
    {
      onSuccess(data) {
        message.success("Dados alterados com sucesso!");
        toggleStoreDataEdit();
        setStore(data);
        localStorage.setItem("store", JSON.stringify(data));
      },
      onError() {
        message.error("Erro ao alterar dados, tente novamente.");
      },
    }
  );

  const submitForm = async (values: FormValues) => {
    if (!store) return;
    setIsLoading(true);
    updateUserDataMutation.mutate({
      storeId: store._id,
      displayName: values.displayName,
      email: values.email,
      cnpj: values.cnpj,
      phone: `55${values.phone}`,
      address: {
        postalCode: values.postalCode,
        streetName: values.streetName,
        streetNumber: values.streetNumber,
        neighborhood: values.neighborhood,
      },
    });
    setIsLoading(false);
  };

  return (
    <>
      <DataCard title="Dados empresariais" editable toggleEditing={toggleStoreDataEdit}>
        <Form
          form={storeDataForm}
          initialValues={{
            displayName: "",
            email: "",
            cnpj: "",
            phone: "",
            postalCode: "",
            streetName: "",
            streetNumber: "",
            neighborhood: "",
          }}
          layout="vertical"
          onFinish={(values) => submitForm(values)}
        >
          <div className="grid grid-cols-2 gap-4">
            <Form.Item
              name="displayName"
              label="Nome da empresa"
              hasFeedback={editStoreData}
              rules={[{ required: true, type: "string" }]}
            >
              <Input className="input" disabled={!editStoreData} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email da empresa"
              hasFeedback={editStoreData}
              rules={[{ required: true, type: "email" }]}
            >
              <Input className="input" disabled={!editStoreData} />
            </Form.Item>
            <Form.Item
              name="cnpj"
              label="CNPJ"
              hasFeedback={editStoreData}
              rules={[{ required: true, type: "string" }]}
            >
              <InputMask
                disabled={!editStoreData}
                className="input"
                value={store?.cnpj}
                mask="99.999.999/9999-99"
              />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Contato"
              hasFeedback={editStoreData}
              rules={[{ type: "string" }]}
            >
              <InputMask
                disabled={!editStoreData}
                className="input"
                value={store?.phone}
                mask="(99) 9999-9999"
              />
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="CEP"
              hasFeedback={editStoreData}
              validateStatus={(() => {
                if (cepLoading) {
                  return "validating";
                } else if (cepHasErrors) {
                  return "error";
                } else if (cepHasErrors === false) {
                  return "success";
                }
              })()}
              required
              rules={[{ required: true, type: "string" }]}
            >
              <InputMask
                value={store?.address.postalCode}
                disabled={!editStoreData}
                className="input"
                mask="99999-999"
                onChange={(e) => {
                  if (!e.target.value || e.target.value === "_____-___") return;
                  debouncedCep(e.target.value.replace(/[^\w][\s]*/gi, ""));
                }}
              />
            </Form.Item>
            <Form.Item
              name="streetName"
              label="Logradouro"
              hasFeedback={editStoreData}
              required
              rules={[{ required: true, type: "string" }]}
            >
              <Input className="input" disabled={cepLoading || !editStoreData} />
            </Form.Item>
            <Form.Item
              name="streetNumber"
              label="Número"
              hasFeedback={editStoreData}
              required
              rules={[{ required: true, type: "string" }]}
            >
              <Input className="input" disabled={!editStoreData} />
            </Form.Item>
            <Form.Item
              name="neighborhood"
              label="Bairro"
              hasFeedback={editStoreData}
              required
              rules={[{ required: true, type: "string" }]}
            >
              <Input className="input" disabled={cepLoading || !editStoreData} />
            </Form.Item>
          </div>
          {editStoreData && (
            <div className="flex justify-end">
              <Button
                loading={isLoading}
                text="Salvar alteração"
                textcolor="white"
                bgcolor="red"
                htmlType="submit"
              />
            </div>
          )}
        </Form>
      </DataCard>
    </>
  );
};

export default StoreDataTab;
