import { ApiClient } from "utils/apiClient";

export interface UpdateUserDataReq {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

const UserService = {
  async updateUserData(userData: UpdateUserDataReq) {
    const { data } = await ApiClient.put(`/user/${userData.userId}`, userData);
    return data;
  },
};

export default UserService;
