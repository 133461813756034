import React from "react";
import Button from "components/layout/Button";
import Modal from "components/layout/Modal";
import { useMutation, useQueryClient } from "react-query";
import DriverService from "services/driver.service";
import DisassociateDriverImg from "assets/images/disassociate-driver.png";
import Driver from "interfaces/domain/driver.interface";

interface DisassociateDriverModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  driverToDisassociate: string;
  setDriverToDisassociate: React.Dispatch<React.SetStateAction<string>>;
}

const DisassociateDriverModal: React.FC<DisassociateDriverModalProps> = (props) => {
  const { isVisible, toggleModal, driverToDisassociate, setDriverToDisassociate } = props;

  const queryClient = useQueryClient();
  const disassociateDriverMutation = useMutation(
    "driverId",
    (driverToDisassociate: string) => DriverService.disassociateDriver(driverToDisassociate),
    {
      onSuccess: () => {
        queryClient.setQueryData<Driver[]>("getAssociatedDrivers", (oldData) =>
          oldData ? oldData.filter((driver) => driver._id !== driverToDisassociate) : []
        );
      },
    }
  );

  const submitDisassociate = () => {
    disassociateDriverMutation.mutate(driverToDisassociate);
    setDriverToDisassociate("");
  };

  const resetMutationAfterClose = () => {
    disassociateDriverMutation.reset();
  };
  return (
    <Modal
      title="Desassociar entregador"
      width={630}
      visible={isVisible}
      onCancel={toggleModal}
      afterClose={resetMutationAfterClose}
    >
      <div className="flex flex-col items-center">
        {disassociateDriverMutation.isIdle || disassociateDriverMutation.isLoading ? (
          <>
            <figure className="mb-10">
              <img src={DisassociateDriverImg} alt="Desassociar entregador" />
            </figure>
            <p className="w-80 mb-10">
              Você tem certeza que deseja <strong className="text-red">desassociar</strong> esse
              entregador do seu restaurante?
            </p>
            <Button
              loading={false}
              text="Desassociar entregador"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={submitDisassociate}
            />
          </>
        ) : null}
        {disassociateDriverMutation.isSuccess && (
          <>
            <p className="w-80 mb-10">
              O entregador foi <span className="text-green">desassociado com sucesso</span> do seu
              restaurante!
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
        {disassociateDriverMutation.isError && (
          <>
            <p className="w-80 mb-10">
              <span className="text-red">Erro ao desassociar entregador.</span>
              <br />
              Tente novamente.
            </p>
            <Button
              loading={false}
              text="Fechar"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={toggleModal}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DisassociateDriverModal;
