import firebase from "credentials/firebase.credentials";
import DriverInvite from "interfaces/domain/driverInvite.interface";
import Store from "interfaces/domain/store.interface";
import { ApiClient } from "utils/apiClient";

export interface InviteDriverReq {
  storeDisplayName: string;
  driverTelephone: string;
  sendSMS: boolean;
}

const DriverService = {
  getDriversRef(storeId: string) {
    const driversRef = firebase.database().ref(`stores/${storeId}/`);
    return driversRef;
  },

  getDriverRef(storeId: string, driverId: string) {
    const driverRef = firebase.database().ref(`stores/${storeId}/${driverId}`);
    return driverRef;
  },

  async inviteDriver(inviteReqParams: InviteDriverReq): Promise<DriverInvite> {
    const { data } = await ApiClient.post<DriverInvite>("/invite", inviteReqParams);
    return data;
  },

  async revokeDriverInvite(inviteId: string) {
    const revokeReq = await ApiClient.post("/invite/revoke", { inviteId });
    return revokeReq;
  },

  async disassociateDriver(driverId: string) {
    const { data } = await ApiClient.delete<Store>(`/invite/${driverId}`);
    return data;
  },
};

export default DriverService;
