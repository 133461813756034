import { useState } from "react";
import { FaFilter } from "react-icons/fa";
import styled from "styled-components";

interface StyledButtonProps {
  isClicked: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  width: 1.875rem;
  height: 1.875rem;
  background: ${(props) => (props.isClicked ? "#e3dfdf" : "#fff")};
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface FilterButtonProps {
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = (props) => {
  const { onClick } = props;
  const [isClicked, setIsClicked] = useState(false);

  return (
    <StyledButton
      isClicked={isClicked}
      onClick={() => {
        onClick();
        setIsClicked(!isClicked);
      }}
    >
      <FaFilter />
    </StyledButton>
  );
};

export default FilterButton;
