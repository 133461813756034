import React from "react";
import Modal from "components/layout/Modal";
import { Form, Input } from "antd";
import Button from "components/layout/Button";
import { useMutation } from "react-query";
import StoreService from "services/store.service";
import Store, { IntegratorPayload } from "interfaces/domain/store.interface";
import toast from "react-hot-toast";

interface IFoodIntegrationModalProps {
  merchantId: string | undefined;
  isVisible: boolean;
  toggleModal: () => void;
  setStore: React.Dispatch<React.SetStateAction<Store | undefined>>;
}

const IFoodIntegrationModal: React.FC<IFoodIntegrationModalProps> = (props) => {
  const { merchantId, isVisible, toggleModal, setStore } = props;

  const updateIntegratorDataMutation = useMutation(
    "updateIntegratorDataMutation",
    (integrationKey: IntegratorPayload) => StoreService.storeIntegratorData(integrationKey),
    {
      onSuccess(data) {
        setStore(data);
        toggleModal();
      },
    }
  );

  const submitForm = async (values: { iFoodMerchantId: string }) => {
    if (JSON.stringify(merchantId) === JSON.stringify(values.iFoodMerchantId)) {
      toast.success("Dados inalterados.");
      toggleModal();
    } else {
      toast.promise(
        updateIntegratorDataMutation.mutateAsync({
          source: "iFood",
          data: {
            iFoodMerchantId: values.iFoodMerchantId,
          },
        }),
        {
          loading: "Carregando...",
          success: "Id da loja atualizado com sucesso!",
          error: "Erro ao atualizar id da loja.",
        }
      );
    }
  };

  return (
    <Modal
      title="Cadastrar Id da loja iFood"
      width={920}
      visible={isVisible}
      onCancel={toggleModal}
    >
      <p className="text-left text-xs mb-3">
        *No caso de mais de uma loja adicionar os ids separados por virgula
      </p>
      <Form
        layout="vertical"
        onFinish={(values) => submitForm(values)}
        initialValues={{ iFoodMerchantId: merchantId }}
      >
        <Form.Item name="iFoodMerchantId" label="Id da loja:" hasFeedback>
          <Input className="input" />
        </Form.Item>
        <Button
          loading={updateIntegratorDataMutation.isLoading}
          text="Salvar"
          bgcolor="red"
          textcolor="white"
          htmlType="submit"
        />
      </Form>
    </Modal>
  );
};

export default IFoodIntegrationModal;
