import IMask from "imask";

export const getMaskedCEP = (cep: string) => {
  return IMask.createMask({
    mask: "00000-000",
  }).resolve(cep);
};

export const getMaskedCellphone = (cellphone: string) => {
  return IMask.createMask({
    mask: "(00) 00000-0000",
  }).resolve(cellphone);
};

export const getMaskedCPF = (cpf: string) => {
  return IMask.createMask({ mask: "000.000.000-00" }).resolve(cpf);
};
