import { useQuery } from "react-query";
import HereService from "services/here.service";

export default function useGetAutoCompleteAddress(query: string, storeLatLng: [number, number]) {
  const { data, isIdle, isLoading, isSuccess, isError, refetch } = useQuery(
    ["getAddressFromCep", query, storeLatLng],
    () => HereService.autoCompleteAddress(query, storeLatLng),
    {
      enabled: query && storeLatLng ? true : false,
    }
  );

  return {
    data,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
}
