import React, { useEffect, useState } from "react";
import { Radio, RadioChangeEvent, Select } from "antd";
import Button from "components/layout/Button";
import Modal from "components/layout/Modal";
import ModalTitle from "components/layout/ModalTitle";
import { SelectOrdersMetadata } from "interfaces/ordersPage.interface";
import { useMutation, useQueryClient } from "react-query";
import OrderService from "services/order.service";
import Order from "interfaces/domain/order.interface";
import SuccessAssign from "components/AssignModals/SuccessAssign";
import DriverService from "services/driver.service";
import {
  AttributionType,
  FirebaseDriverData,
  FirebaseDriverSnapshot,
} from "./OrdersMap/OrdersMap.interface";
import DragOrderPortrait from "./DragOrderPortrait";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { ref, onValue } from "firebase/database";
import { rtdb } from "utils/firebasev9";

interface BatchAssignDriverModalProps {
  storeId: string;
  isVisible: boolean;
  toggleModal: () => void;
  setSelectedOrders: React.Dispatch<React.SetStateAction<SelectOrdersMetadata[]>>;
  batchSelectedOrders: SelectOrdersMetadata[];
  setBatchSelectedOrders: React.Dispatch<React.SetStateAction<SelectOrdersMetadata[]>>;
}

const { Option } = Select;

const BatchAssignDriverModal: React.FC<BatchAssignDriverModalProps> = (props) => {
  const {
    storeId,
    isVisible,
    toggleModal,
    setSelectedOrders,
    batchSelectedOrders,
    setBatchSelectedOrders,
  } = props;

  const [selectedDriver, setSelectedDriver] = useState("");
  const [radioOpt, setRadioOpt] = useState(0);
  const [drivers, setDrivers] = useState<FirebaseDriverData[]>();
  const [driversToSelect, setDriversToSelect] = useState<FirebaseDriverData[]>();
  const [isLoadingDrivers, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isVisible || !storeId) return;
    const driversRef = ref(rtdb, `stores/${storeId}`);
    return onValue(driversRef, (snapshot) => {
      setIsLoading(true);
      setSelectedDriver("");
      const snapshotObj = snapshot.val() as FirebaseDriverSnapshot;
      if (!snapshotObj) return;
      const onlineDrivers = Object.values(snapshotObj) as FirebaseDriverData[];
      setDrivers(onlineDrivers.filter((driver) => driver.status === "AVAILABLE"));
      setIsLoading(false);
    });
  }, [isVisible, storeId]);

  const queryClient = useQueryClient();
  const batchAssignDriverMutation = useMutation(
    "batchAssignDriverMutation",
    () =>
      OrderService.batchAssignDriver(
        batchSelectedOrders.map((order) => order.orderId),
        selectedDriver
      ),
    {
      onSuccess: (data) => {
        if (storeId === "") return;
        queryClient.setQueryData("activeOrders", (oldData: any) => {
          return oldData.map((order: Order) => {
            const orderFound = data.find((dataOrder) => dataOrder._id === order._id);
            if (orderFound) return orderFound;
            return order;
          });
        });
        const inTransitOrders =
          radioOpt === 2 ? batchSelectedOrders.map((order) => order.displayId) : [];
        const attributionType: AttributionType = radioOpt === 2 ? "INDEXED" : "DISTANCE";
        DriverService.getDriverRef(storeId, selectedDriver).update({
          ordersAction: "AVAILABLE",
          inTransitOrders,
          attributionType,
        });
      },
    }
  );

  const handleSubmit = () => {
    if (selectedDriver && selectedDriver !== "") {
      batchAssignDriverMutation.mutate();
      setSelectedDriver("");
    }
  };

  const resetMutationAfterClose = () => {
    batchAssignDriverMutation.reset();
    setRadioOpt(0);
    setSelectedDriver("");
    setBatchSelectedOrders([]);
    setSelectedOrders([]);
  };

  const handleSelectChange = (value: string) => setSelectedDriver(value);

  const reorder = (list: SelectOrdersMetadata[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult, _: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(batchSelectedOrders, result.source.index, result.destination.index);
    setBatchSelectedOrders(items);
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    let radioValue = e.target.value;
    if (drivers) {
      if (radioValue === 2) {
        setDriversToSelect(drivers.filter((driver) => !driver.inTransitOrders?.length));
      } else {
        setDriversToSelect(drivers);
      }
    }
    setSelectedDriver("");
    setRadioOpt(e.target.value);
  };

  return (
    <Modal
      title={ModalTitle({
        text: "Atribuir lote de pedidos",
      })}
      width={820}
      visible={isVisible}
      onCancel={toggleModal}
      afterClose={resetMutationAfterClose}
    >
      {(batchAssignDriverMutation.isIdle || batchAssignDriverMutation.isLoading) && (
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-center gap-4">
            <p>Em qual sequência os pedidos deverão ser entregues?</p>
            <Radio.Group value={radioOpt} onChange={onRadioChange}>
              <div className="flex flex-col gap-2">
                <Radio value={1}>
                  <p className="text-base">Roteirização automatica por distância.</p>
                </Radio>
                <Radio value={2}>
                  <p className="text-base">Escolher manualmente a sequência de entrega</p>
                </Radio>
              </div>
            </Radio.Group>
            {radioOpt === 2 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided: DroppableProvided, _: DroppableStateSnapshot) => (
                    <div
                      className="flex gap-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {batchSelectedOrders.map((order, index) => (
                        <Draggable
                          key={order.displayId}
                          draggableId={order.displayId}
                          index={index}
                        >
                          {(providedDraggable: DraggableProvided, _) => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <DragOrderPortrait
                                key={order.orderId}
                                orderId={order.displayId}
                                address={order.deliveryAddress}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          <div className="flex flex-col items-center gap-5">
            <p>Selecione um dos entregadores disponíveis para atribuir estes pedidos:</p>
            <Select
              showSearch
              bordered
              style={{ width: 465, textAlign: "left" }}
              optionFilterProp="children"
              optionLabelProp="children"
              filterOption={(input, option) => {
                if (!option || !option.children) return false;
                return option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              value={selectedDriver}
              loading={isLoadingDrivers}
              onChange={handleSelectChange}
            >
              {driversToSelect &&
                driversToSelect?.map((driver, index) => (
                  <Option key={driver.id} value={driver.id}>
                    {index + 1}&#176;- {`${driver.fullName}`}
                  </Option>
                ))}
            </Select>
            <Button
              loading={batchAssignDriverMutation.isLoading}
              disabled={
                isLoadingDrivers ||
                !drivers ||
                !drivers.length ||
                selectedDriver === "" ||
                radioOpt === 0
              }
              text="Atribuir pedidos ao entregador"
              bgcolor={"red"}
              textcolor={"white"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
      {batchAssignDriverMutation.isSuccess && <SuccessAssign toggleModal={toggleModal} />}
      {batchAssignDriverMutation.isError && (
        <>
          <p className="w-80 mb-10">
            <span className="text-red">
              <strong>Erro ao atribuir pedido.</strong>
            </span>
            <br />
            Tente novamente.
          </p>
          <Button
            loading={false}
            text="Fechar"
            bgcolor={"red"}
            textcolor={"white"}
            onClick={toggleModal}
          />
        </>
      )}
    </Modal>
  );
};

export default BatchAssignDriverModal;
